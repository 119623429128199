import { App } from "@pimo/pimo-app-builder";
import { generatePath } from "react-router-dom";

import { TracyAppState } from "../app";
import { APP_ROUTES } from "../constants";
import { addNewScenario } from "./fetch/fetch-scenario";

/**
 * Adds a new scenario and navigates to that scenario's flow. After creating the scenario, `onCreated` is called.
 */
export async function addScenario(
  app: App<TracyAppState>,
  onCreated?: () => void
) {
  const { year, currentOEReportPage } = app.getAppState();

  if (!year || currentOEReportPage?.oe?.id == null) {
    return;
  }

  const oeId = currentOEReportPage.oe.id;

  const scenario = await addNewScenario({
    year,
    oeId,
  });

  onCreated?.();

  app.navigate(
    generatePath(`${APP_ROUTES.oeScenarios}/*`, {
      oeId: String(oeId),
      scenarioId: String(scenario.id),
      "*": "step-0",
    })
  );
}

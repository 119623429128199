import type { OERatingResponse } from "tracy-types";

import { STRAPI_URL } from "../../env";

/**
 * Fetches the data for one OE report rating for one OE in a year.
 */
export async function fetchOERatings({
  id,
  year,
}: {
  id: number;
  year: number;
}): Promise<OERatingResponse | undefined> {
  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff/oe-ratings/${id}/${year}`,
      { credentials: "include" }
    );

    return (await response.json()) as OERatingResponse;
  } catch {
    return;
  }
}

import { EntityValidationResult } from "tracy-types";

export function hasError(
  path: string,
  validationResult: EntityValidationResult | undefined
): boolean {
  return !!validationResult?.error?.details?.errors?.some(
    (error) => error.path === path
  );
}

import { CheckCircle } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import React from "react";
import type { UpdateStatus } from "tracy-types";

export type TracyStatusIndicatorProps = { updateStatus?: UpdateStatus };

export const TracyStatusIndicator: React.FC<TracyStatusIndicatorProps> = ({
  updateStatus,
}) => {
  const theme = useTheme();

  switch (updateStatus) {
    case "up-to-date":
      return <CheckCircle sx={{ fill: theme.palette.success.main }} />;
    case "in-progress":
      return <CheckCircle sx={{ fill: "#FFC107" }} />;
    case "outdated":
      return <CheckCircle sx={{ fill: "#979797" }} />;
    default:
      return null;
  }
};

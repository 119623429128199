var ACTORS = [
    "Nation-state",
    "Organized Criminals",
    "Hacker",
    "Malicious Insider",
    "Employee",
];
var DEFAULT_ACTORS = ACTORS.map(function (item) {
    return { name: item };
});

var AFFECTED_ATTRIBUTES = [
    "Availability",
    "Integrity",
    "Confidentiality",
];
var DEFAULT_AFFECTED_ATTRIBUTES = AFFECTED_ATTRIBUTES.map(function (item) {
    return { name: item };
});

var CONTROL_ENV_SUBGROUPS = [
    "Process",
    "People",
    "Control",
    "Culture",
];
var CONTROL_ENV_RATING = [
    1, 2, 3, 4, 5,
];

var SCENARIO_FIELDS = [
    "motivesObjectives",
    "assetsAtRisk",
    "initialAction",
    "actor",
    "responseDuration",
    "vulnerability",
    "actionFurtherActionAttackTtp",
    "businessImpact",
    "affectedAttributes",
    "storyAndName",
    "story",
    "name",
    "shortDescription",
];

var FINANCIAL_SUBGROUPS = [
    "Short Assessment",
    "Business Outage",
    "Data loss and/or manipulation",
    "Financial fraud or ransom",
    "Software and/or Hardware recovery",
    "Forensic expenses",
    "Regulatory & Legal defense costs",
    "Communication and management",
    "Other financial impact",
];
var FINANCIAL_RATING = [
    1, 2, 3, 4, 5,
];

var FINANCIAL_LOOK_UP = [
    {
        rating: 1,
        ratingSchema: "ASTRA",
        description: "Less than 10%",
        lowerBound: 0,
        upperBound: 0.1,
    },
    {
        rating: 2,
        ratingSchema: "ASTRA",
        description: "Between 10% to 30%",
        lowerBound: 0.1,
        upperBound: 0.3,
    },
    {
        rating: 3,
        ratingSchema: "ASTRA",
        description: "Between 30% to 50%",
        lowerBound: 0.3,
        upperBound: 0.5,
    },
    {
        rating: 4,
        ratingSchema: "ASTRA",
        description: "Between 50% to 100%",
        lowerBound: 0.5,
        upperBound: 1.00000000000001,
    },
    {
        rating: 5,
        ratingSchema: "ASTRA",
        description: "More than 100%",
        lowerBound: 1.00000000000001,
        upperBound: 1000000,
    },
    {
        rating: 1,
        ratingSchema: "AFIRM",
        description: "Less Than 0.5%",
        lowerBound: 0,
        upperBound: 0.005,
    },
    {
        rating: 2,
        ratingSchema: "AFIRM",
        description: "Between 0.5% to 3%",
        lowerBound: 0.005,
        upperBound: 0.03,
    },
    {
        rating: 3,
        ratingSchema: "AFIRM",
        description: "Between 3% to 7%",
        lowerBound: 0.03,
        upperBound: 0.07,
    },
    {
        rating: 4,
        ratingSchema: "AFIRM",
        description: "Between 7% to 15%",
        lowerBound: 0.07,
        upperBound: 0.1500000000000001,
    },
    {
        rating: 5,
        ratingSchema: "AFIRM",
        description: "More than 15%",
        lowerBound: 0.1500000000000001,
        upperBound: 1000000,
    },
];

var OVERALL_RATING = [
    1, 2, 3, 4, 5,
];

var PROBABILITY_RATING = [
    1, 2, 3, 4, 5,
];

var REPUTATIONAL_SUBGROUPS = [
    "AZ Investors",
    "Customers",
    "NGOs",
    "Business partners",
    "Regulators",
    "AZ Staff",
];
var REPUTATIONAL_RATING = [
    0, 1, 2, 3, 4, 5,
];

var RESPONSE_DURATIONS = [
    "Short-term (days)",
    "Medium-term (weeks)",
    "Long-term (months)",
];
var DEFAULT_RESPONSE_DURATIONS = RESPONSE_DURATIONS.map(function (item) {
    return { name: item };
});

var RISK_TYPE = ["current", "residual"];

var STEP_NUMBERS_OF_NORMAL_ASSESSMENT = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
];
var TOTAL_STEPS_OF_NORMAL_ASSESSMENT = STEP_NUMBERS_OF_NORMAL_ASSESSMENT.length;
var STEP_NUMBERS_OF_SHORTCUT_ASSESSMENT = [
    1,
];
var TOTAL_STEPS_OF_SHORTCUT_ASSESSMENT = STEP_NUMBERS_OF_SHORTCUT_ASSESSMENT.length;

var FILTER_PARAMS = [
    "searchQueryFilter",
    "nameFilter",
    "contactFilter",
    "topRiskFilter",
    "reportStatusFilter",
    "clusterFilter",
];
var FILTER_PARAMS_DASHBOARD = ["clusterFilter"];

var UPDATE_STATUS_VALUES = [
    "up-to-date",
    "outdated",
    "in-progress",
];

var RATING_SCHEMAS = ["ASTRA", "AFIRM"];
var SUBVERSIONS_FOR_TECHNICAL_VULNERABILITY_EXPLOITATION = [
    "Financial Motive Variation",
    "Data Stealing Motivation",
];

/** this serves as a pseudo-entity for predefined scenarios
 *there are not going to be any real assessments made for this entity */
var DEFAULT_ENTITY = "default";

var EntityValidationResult = /** @class */ (function () {
    function EntityValidationResult() {
        this.error = { details: { errors: [] } };
    }
    EntityValidationResult.prototype.addValidationError = function (_a) {
        var path = _a.path, message = _a.message;
        this.error.details.errors.push({ path: path, message: message, name: "ValidationError" });
    };
    EntityValidationResult.prototype.hasErrors = function () {
        return this.error.details.errors.length > 0;
    };
    return EntityValidationResult;
}());

export { ACTORS, AFFECTED_ATTRIBUTES, CONTROL_ENV_RATING, CONTROL_ENV_SUBGROUPS, DEFAULT_ACTORS, DEFAULT_AFFECTED_ATTRIBUTES, DEFAULT_ENTITY, DEFAULT_RESPONSE_DURATIONS, EntityValidationResult, FILTER_PARAMS, FILTER_PARAMS_DASHBOARD, FINANCIAL_LOOK_UP, FINANCIAL_RATING, FINANCIAL_SUBGROUPS, OVERALL_RATING, PROBABILITY_RATING, RATING_SCHEMAS, REPUTATIONAL_RATING, REPUTATIONAL_SUBGROUPS, RESPONSE_DURATIONS, RISK_TYPE, SCENARIO_FIELDS, STEP_NUMBERS_OF_NORMAL_ASSESSMENT, STEP_NUMBERS_OF_SHORTCUT_ASSESSMENT, SUBVERSIONS_FOR_TECHNICAL_VULNERABILITY_EXPLOITATION, TOTAL_STEPS_OF_NORMAL_ASSESSMENT, TOTAL_STEPS_OF_SHORTCUT_ASSESSMENT, UPDATE_STATUS_VALUES };

export const ProbabilityDescription = `
### Background Information

In general, we consider two types of probabilities when evaluating cyber risks:
- Occurrence Probability: The likelihood of a given scenario taking place.
- Loss Probability: The chance that, should the scenario occur, the resultant loss falls within a certain range.

In step one of TRACY, you assessed the occurrence probability. Subsequently, in steps two and three, you evaluated the financial and non-financial impact ratings assuming the scenario materializes, without specifying the loss probability. We are now seeking to refine these impact ratings by establishing a fixed loss probability of 1 in 20 years. This adjustment aims to assess the impact rating of a loss arising from a severe loss scenario occurring once in 20 years.

### Example

Current assessment with Ransomware as representative scenario: in the assessment of the financial impact rating, we considered a maximum outage duration of 21 days resulting in a high business interruption which leads to a financial impact rating of 4.

New assessment with Ransomware as representative scenario (fixing the loss probability to 1 in 20 years): Under the new assessment parameters, we no longer use the maximum outage duration of 21 days. Instead, we consider a shorter outage duration of 14 days. This adjustment reflects a reduction of the business interruption, which in turn results in a lower estimated financial loss. Consequently, the financial impact rating is adjusted downwards to a value of 3.
`;

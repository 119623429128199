import { type PropsWithChildren, useEffect, useRef } from "react";

type UseFadeInProps = PropsWithChildren<{
  duration: number;
}>;

/** custom hook to let components fade in */
export function useFadeIn({ duration }: UseFadeInProps) {
  const ref = useRef<HTMLElement | null>(null);
  const styles = {
    transition: `opacity ${duration}ms, transform ${duration}ms`,
    transform: `translateY(20px)`,
    opacity: 0,
  };

  useEffect(() => {
    ref.current?.animate(
      {
        transform: ["translateY(20px)", "translateY(0)"],
        opacity: [0, 1],
      },
      {
        duration,
        easing: "ease-in",
        fill: "forwards",
      }
    );
  }, [duration]);

  return {
    ref,
    styles,
  };
}

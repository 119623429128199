import {
  Alert,
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FINANCIAL_SUBGROUPS, FinancialSubgroup } from "tracy-types";
import {
  getColorSchemeForRating,
  getFinancialImpactRatingFromImpactPercentageOfOperatingProfit,
} from "tracy-utils";

import { FINANCIAL_CONFIG } from "../../../../config/financial";
import { RATING_LABEL } from "../../../../config/rating-label";
import { DISABLED_INPUT_STYLES } from "../../../../constants";
import { getErrorMessage } from "../../../../helpers/validation/get-error-message";
import { hasError } from "../../../../helpers/validation/has-error";
import { ScenariosFormComponent, ScenariosFormValues } from "../../types";

export const FinancialImpactOfCyberRiskForm: ScenariosFormComponent<{
  riskType: "current" | "residual";
}> = ({ disabled, formValues, oeReportPage, riskType, validationResult }) => {
  const { watch, register, setValue, getValues } =
    useFormContext<ScenariosFormValues>();

  const versionForThisStep = watch(`financialImpact.${riskType}.version`);
  const versionForCurrent = watch(`financialImpact.current.version`);

  const valuesForThisStep = watch(`financialImpact.${riskType}.values`);
  const valuesForCurrent =
    riskType === "current"
      ? valuesForThisStep
      : watch(`financialImpact.current.values`);

  const questionsForThisStep: FinancialSubgroup[] = useMemo(() => {
    switch (versionForThisStep) {
      case "extended":
        return [
          ...FINANCIAL_SUBGROUPS.filter((sg) => sg !== "Short Assessment"),
        ];
      default:
        return ["Short Assessment"] as FinancialSubgroup[];
    }
  }, [versionForThisStep]);

  const questionsForCurrent: FinancialSubgroup[] = useMemo(() => {
    switch (versionForCurrent) {
      case "extended":
        return [
          ...FINANCIAL_SUBGROUPS.filter((sg) => sg !== "Short Assessment"),
        ];
      default:
        return ["Short Assessment"] as FinancialSubgroup[];
    }
  }, [versionForCurrent]);

  const hasValues =
    questionsForThisStep.filter(
      (question) =>
        valuesForThisStep?.[question]?.value != null &&
        (valuesForThisStep?.[question]?.value as unknown as string) !== ""
    ).length > 0;

  const sumOfAllImpactValues = hasValues
    ? questionsForThisStep.reduce((acc, question) => {
        if (typeof valuesForThisStep?.[question]?.value !== "number") {
          return acc + 0;
        }

        return acc + (valuesForThisStep[question]?.value ?? 0);
      }, 0)
    : undefined;

  const sumOfAllImpactValuesForCurrent = questionsForCurrent.reduce(
    (acc, question) => {
      if (typeof valuesForCurrent?.[question]?.value !== "number") {
        return acc + 0;
      }

      return acc + (valuesForCurrent[question]?.value ?? 0);
    },
    0
  );

  const impactValuePercentOfOperatingProfit =
    sumOfAllImpactValues != null
      ? parseInt(oeReportPage?.oeStatus?.operatingProfit ?? "0") > 0 &&
        sumOfAllImpactValues > 0
        ? (sumOfAllImpactValues /
            parseInt(oeReportPage?.oeStatus?.operatingProfit ?? "0")) *
          100
        : 0
      : undefined;

  const impactRating =
    impactValuePercentOfOperatingProfit != null
      ? getFinancialImpactRatingFromImpactPercentageOfOperatingProfit(
          impactValuePercentOfOperatingProfit / 100,
          oeReportPage?.oeStatus?.ratingSchema ?? "ASTRA"
        )
      : undefined;

  useEffect(() => {
    setValue(`financialImpact.${riskType}.rating`, impactRating);
  }, [impactRating, setValue, riskType]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            m: 4,
            mr: 6,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Select Version</Typography>
          <Controller
            name={`financialImpact.${riskType}.version`}
            render={({ field: { onChange, onBlur, value } }) => (
              <RadioGroup
                onChange={onChange}
                onBlur={onBlur}
                value={(value ?? "basic") as string}
              >
                <FormControlLabel
                  disabled={disabled}
                  value="basic"
                  control={<Radio />}
                  label={"Version 1: Short Assessment of Financial Impact"}
                />
                <FormControlLabel
                  disabled={disabled}
                  value="extended"
                  control={<Radio />}
                  label={"Version 2: Extend Assessment of Financial Impact"}
                />
              </RadioGroup>
            )}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  width="25%"
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                    borderRadius: "10px 0 0 10px",
                  }}
                >
                  Financial Impact (Loss) in EUR
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                  }}
                >
                  Guiding Questions
                </TableCell>
                <TableCell
                  width="30%"
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                    borderRadius: "0 10px 10px 0",
                  }}
                >
                  Comment
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {/**
               * in case we're on step 8 and the versions don't match
               * we need to compare the sum of all of the impact values
               * and only show a single warning
               */}
              {riskType === "residual" &&
                versionForCurrent !== versionForThisStep &&
                (sumOfAllImpactValuesForCurrent ?? 0) <
                  (sumOfAllImpactValues ?? 0) && (
                  <TableCell sx={{ border: "none" }} colSpan={4}>
                    <Alert sx={{ color: "gray" }} severity="warning">
                      The entered residual risk value is worse than the
                      corresponding current risk value. Please review your
                      assessment or provide an explanation in the comment field
                    </Alert>
                  </TableCell>
                )}

              {questionsForThisStep.map((question, questionIndex) => {
                const borderBottom =
                  questionIndex === questionsForThisStep.length - 1
                    ? "none"
                    : "##DFEFF2 1px solid";

                return (
                  <Fragment key={question}>
                    {/**
                     * in case we're on step 8 and the versions *do* match
                     * we need to compare the individual impact values
                     * and show a warning for each
                     */}
                    {riskType === "residual" &&
                      versionForCurrent === versionForThisStep &&
                      (getValues(
                        `financialImpact.current.values.${question}.value`
                      ) ?? 0) <
                        (getValues(
                          `financialImpact.residual.values.${question}.value`
                        ) ?? 0) && (
                        <TableCell sx={{ border: "none" }} colSpan={4}>
                          <Alert sx={{ color: "gray" }} severity="warning">
                            The entered residual risk value is worse than the
                            corresponding current risk value. Please review your
                            assessment or provide an explanation in the comment
                            field
                          </Alert>
                        </TableCell>
                      )}
                    <TableRow key={question}>
                      <TableCell
                        sx={{
                          borderBottom,
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                          {question}
                        </Typography>
                        <Box
                          sx={{
                            flex: "0 0 50%",
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <Controller
                            defaultValue={watch(
                              `financialImpact.${riskType}.values.${question}.value`
                            )}
                            name={`financialImpact.${riskType}.values.${question}.value`}
                            render={({
                              field: { onBlur, onChange, value },
                            }) => (
                              <TextField
                                defaultValue={value as number}
                                disabled={disabled}
                                error={hasError(
                                  `financialImpact.${riskType}.values.${question}.value`,
                                  validationResult
                                )}
                                helperText={getErrorMessage(
                                  `financialImpact.${riskType}.values.${question}.value`,
                                  validationResult
                                )}
                                label="€"
                                sx={disabled ? DISABLED_INPUT_STYLES : {}}
                                type="number"
                                onChange={(e) => {
                                  // call the regular onChange function, but pass a number instead of text
                                  onChange({
                                    ...e,
                                    target: {
                                      ...e.target,
                                      value: e.target.value
                                        ? Number(e.target.value)
                                        : e.target.value,
                                    },
                                  });
                                }}
                                onBlur={onBlur}
                              />
                            )}
                          />
                          {riskType === "residual" && (
                            <Typography sx={{ color: "#979797" }}>
                              Current Risk:{" "}
                              {formValues?.financialImpact?.current?.version ===
                                versionForThisStep &&
                                String(
                                  formValues?.financialImpact?.current
                                    ?.values?.[question]?.value ?? "n/a"
                                ) + " €"}
                              {formValues?.financialImpact?.current?.version ===
                                "extended" &&
                                versionForThisStep === "basic" &&
                                String(
                                  sumOfAllImpactValuesForCurrent ?? "n/a"
                                ) + " €"}{" "}
                              {formValues?.financialImpact?.current?.version ===
                                "basic" &&
                                versionForThisStep === "extended" &&
                                "n/a €"}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom,
                        }}
                      >
                        <ul style={{ paddingLeft: "20px" }}>
                          {FINANCIAL_CONFIG[question].map((guidingQuestion) => (
                            <li key={guidingQuestion}>
                              <Typography>{guidingQuestion}</Typography>
                            </li>
                          ))}
                        </ul>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom,
                        }}
                      >
                        <TextField
                          disabled={disabled}
                          fullWidth
                          inputProps={register(
                            `financialImpact.${riskType}.values.${question}.comment`
                          )}
                          error={hasError(
                            `financialImpact.${riskType}.values.${question}.comment`,
                            validationResult
                          )}
                          helperText={getErrorMessage(
                            `financialImpact.${riskType}.values.${question}.comment`,
                            validationResult
                          )}
                          multiline
                          placeholder="Comment"
                          rows="4"
                          sx={
                            disabled
                              ? { ...DISABLED_INPUT_STYLES, height: "100%" }
                              : { height: "100%" }
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pl: 6,
          mt: 2,
          pb: 4,
        }}
      >
        <Typography sx={{ fontWeight: "bold" }} component="span">
          Financial Impact Rating
          <Chip
            sx={{
              background:
                getColorSchemeForRating(impactRating).colorScheme.chipColor,
              height: 28,
              ml: 2,
              pl: 1,
              pr: 1,
              color:
                getColorSchemeForRating(impactRating).colorScheme.textColor,
            }}
            label={
              impactRating
                ? `${impactRating} (${RATING_LABEL.FinancialImpact[impactRating]})`
                : "N/A"
            }
          />
        </Typography>
        <Typography>
          Sum of All Amounts:{" "}
          {sumOfAllImpactValues != null
            ? sumOfAllImpactValues.toLocaleString()
            : "N/A"}{" "}
          EUR
        </Typography>
        <Typography>
          {impactValuePercentOfOperatingProfit != null
            ? Math.round(impactValuePercentOfOperatingProfit).toLocaleString()
            : "N/A"}{" "}
          %
        </Typography>
        {/* empty box to push content to the left not to touch the right screen edge */}
        <Box />
      </Box>
    </>
  );
};

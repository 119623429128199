import { FilterData, OEOverviewPage } from "tracy-types";
import { buildURLParameters } from "tracy-utils";

import { STRAPI_URL } from "../../env";

/**
 * Fetches the data for one OE report page for one OE in a year.
 */
export async function fetchOEOverviewPage(
  year: number,
  filterData?: FilterData
): Promise<OEOverviewPage | undefined> {
  const params = buildURLParameters(filterData);

  params.push(`pagination[limit]=100`);
  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff/oe-overview-page/${year}?${params.join("&")}`,
      { credentials: "include" }
    );

    return (await response.json()) as OEOverviewPage;
  } catch {
    return;
  }
}

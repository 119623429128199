import type { ShortcutAssessmentFormValues } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function postShortcutAssessment({
  data,
  oeId,
  scenarioId,
  year,
}: {
  data: ShortcutAssessmentFormValues;
  oeId: number;
  scenarioId: number;
  year: number;
}) {
  const response = await fetch(
    `${STRAPI_URL}/api/bff/scenarios-shortcut-assessment/${oeId}/${scenarioId}/${year}`,
    {
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );
  if (response.status === 400) {
    throw await response.json();
  }
}

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { IdentifierBadge } from "@pimo/pimo-components";
import { useState } from "react";
import {
  FieldValues,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import type {
  ControlEnvRatingValue,
  EntityValidationResult,
  FE_ControlEnvRating,
  ScenarioStepStatus,
} from "tracy-types";
import {
  getColorSchemeAndTextForControlEnvRatingBadge,
  getMaxRating,
  mappedRatingToText,
} from "tracy-utils";

import { CONTROL_ENV_CONFIG } from "../../../config/control-env";
import { getErrorMessage } from "../../../helpers/validation/get-error-message";
import { hasError } from "../../../helpers/validation/has-error";
import { InfoPopup } from "../../info-popup/info-popup";
import { ScenariosStatusIndicator } from "../../scenarios/scenarios-status-indicator";
import { ConfirmationDialogBox } from "./confirmation-dialog-box";

export interface EditEnvironmentEffectivenessDialogProps {
  oeName?: string;
  year?: number;
  controlEnvironmentEffectivenessEntries: FE_ControlEnvRating[];
  formValidationResult?: EntityValidationResult;
  updateStatus: ScenarioStepStatus;
}

/** helper type contained within this file to help with the mapping between rating and description */
type ControlEnvRatingAndDescription = FE_ControlEnvRating & {
  description: string;
};

export const EditEnvironmentEffectivenessDialog: PimoReactComponent<
  EditEnvironmentEffectivenessDialogProps,
  "submit" | "cancel",
  {
    controlEnvironmentEffectivenessEntries: FE_ControlEnvRating[];
  }
> = ({
  controlEnvironmentEffectivenessEntries,
  oeName,
  year,
  fireEvent,
  formValidationResult,
  updateStatus,
}) => {
  const theme = useTheme();
  const [overallRating, setOverallRating] = useState<ControlEnvRatingValue>(
    getMaxRating(
      controlEnvironmentEffectivenessEntries.map((cee) => cee.rating)
    ) as ControlEnvRatingValue
  );
  const colorAndTextForOverallRating =
    getColorSchemeAndTextForControlEnvRatingBadge(overallRating);

  const { control, handleSubmit, register, getValues, setValue } = useForm<{
    controlEnvironmentEffectivenessEntries: ControlEnvRatingAndDescription[];
  }>({ defaultValues: { controlEnvironmentEffectivenessEntries } });
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !controlEnvironmentEffectivenessEntries.some(
      (env) => env.comment || env.rating
    )
  );
  const { fields } = useFieldArray({
    control: control,
    name: "controlEnvironmentEffectivenessEntries",
  });
  const [isComfirmationDialogOpen, setIsComfirmationDialogOpen] =
    useState(false);

  const onSubmit: SubmitHandler<{
    controlEnvironmentEffectivenessEntries: ControlEnvRatingAndDescription[];
  }> = (data) => {
    fireEvent?.("submit", data);
  };

  const onCancel = () => {
    setIsComfirmationDialogOpen(true);
  };

  return (
    <>
      <ConfirmationDialogBox
        open={isComfirmationDialogOpen}
        onContinue={() => {
          setIsComfirmationDialogOpen(false);
        }}
        onClose={() => {
          setIsComfirmationDialogOpen(false);
          fireEvent?.("cancel");
        }}
      />
      <Dialog
        open
        maxWidth={"xl"}
        onClose={() => setIsComfirmationDialogOpen(true)}
        PaperProps={{ sx: { borderRadius: 5 } }}
      >
        <form
          style={{ borderRadius: "12px" }}
          onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "12px",
            }}
          >
            <DialogTitle sx={{ fontWeight: 500, borderRadius: "12px" }}>
              Edit Control Environment Effectiveness for {oeName} ({year})
            </DialogTitle>
            <Box
              sx={{
                mx: 2,
                gap: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>Update </Typography>
              <ScenariosStatusIndicator status={updateStatus} />
            </Box>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "1px",
              borderWidth: 0,
            }}
          />
          <InfoPopup
            shortText="Assess the Control Environment Effectiveness of your OE, please provide a short comment on your selection on the box on the right hand side as well."
            longText="The approach to assessing Control Environment Effectiveness for your entire OE with respect to CYBER on its highest level and based on your expert judgement as specified in the Allianz Standard for Top Risk Assessment (ASTRA) differs in level of detail compared to the Integrated Information Risk Management (IIRM) based on the Group Information Security Framework (GISF)."
          />

          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "1px",
              borderWidth: 0,
            }}
          />
          <Box
            key={oeName}
            sx={{ flex: "0 0 50%", overflow: "auto", maxHeight: 450 }}
          >
            {fields.map((field, index) => (
              <>
                <Box
                  key={field.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      mx: 2,
                      alignItems: "center",
                    }}
                    key={field.id}
                  >
                    <IdentifierBadge>{index + 1}</IdentifierBadge>
                    <Typography sx={{ fontWeight: 500 }}>
                      Dimension {field.subgroup}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      mx: 2,
                      flex: "0 0 50%",
                    }}
                  >
                    <FormControl
                      fullWidth
                      error={hasError(
                        `controlEnvironmentEffectivenessEntries.${index}.rating`,
                        formValidationResult
                      )}
                    >
                      <InputLabel>Rating</InputLabel>
                      <Select
                        variant="outlined"
                        label="Rating"
                        inputProps={register(
                          `controlEnvironmentEffectivenessEntries.${index}.rating`
                        )}
                        onChange={() => {
                          setIsButtonDisabled(false);
                          setValue(
                            `controlEnvironmentEffectivenessEntries.${index}.description`,
                            CONTROL_ENV_CONFIG[
                              getValues(
                                `controlEnvironmentEffectivenessEntries.${index}.rating`
                              )
                            ][field.subgroup]
                          );

                          setOverallRating(
                            Math.max(
                              ...fields.map((_, index) =>
                                getValues(
                                  `controlEnvironmentEffectivenessEntries.${index}.rating`
                                )
                              )
                            ) as ControlEnvRatingValue
                          );
                        }}
                        defaultValue={field.rating}
                        name={`controlEnvironmentEffectivenessEntries.${index}.rating`}
                        error={hasError(
                          `controlEnvironmentEffectivenessEntries.${index}.rating`,
                          formValidationResult
                        )}
                      >
                        {mappedRatingToText.map((entry) => (
                          <MenuItem
                            value={entry.rating}
                            key={`${entry.rating}menuitem`}
                          >
                            {`${entry.rating} - ${entry.value}`}
                          </MenuItem>
                        ))}
                      </Select>
                      {hasError(
                        `controlEnvironmentEffectivenessEntries.${index}.rating`,
                        formValidationResult
                      ) && (
                        <FormHelperText
                          sx={{ color: theme.palette.error.main }}
                        >
                          {getErrorMessage(
                            `controlEnvironmentEffectivenessEntries.${index}.rating`,
                            formValidationResult
                          )}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                        multiline={true}
                        inputProps={register(
                          `controlEnvironmentEffectivenessEntries.${index}.description`
                        )}
                        label="Description of the Rating"
                        sx={{
                          backgroundColor: grey[100],
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: grey[700],
                          },
                        }}
                        defaultValue={
                          CONTROL_ENV_CONFIG?.[field.rating]?.[
                            field.subgroup
                          ] ?? ""
                        }
                        rows={4}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        multiline={true}
                        label="Comment on the Rating"
                        rows={4}
                        inputProps={register(
                          `controlEnvironmentEffectivenessEntries.${index}.comment`
                        )}
                        onChange={() => {
                          setIsButtonDisabled(false);
                        }}
                        error={hasError(
                          `controlEnvironmentEffectivenessEntries.${index}.comment`,
                          formValidationResult
                        )}
                        helperText={getErrorMessage(
                          `controlEnvironmentEffectivenessEntries.${index}.comment`,
                          formValidationResult
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {index !== fields.length - 1 && (
                  <Divider
                    sx={{
                      bgcolor: theme.palette.secondary.main,
                      height: "1px",
                      borderWidth: 0,
                    }}
                  />
                )}
              </>
            ))}
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "1px",
              borderWidth: 0,
            }}
          />
          <Box
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Chip
              label={colorAndTextForOverallRating.text}
              sx={{
                background: colorAndTextForOverallRating.colorScheme.chipColor,
                height: 28,
                ml: 2,
                pl: 1,
                pr: 1,
                color: colorAndTextForOverallRating.colorScheme.textColor,
              }}
            />
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "1px",
              borderWidth: 0,
            }}
          />
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              mt: 2,
              ml: 3,
              mr: 3,
              mb: 2,
              gap: 2,
            }}
          >
            <Button type="button" variant="text" onClick={() => onCancel()}>
              Cancel
            </Button>
            {/*
             * the button should be disable if the user has not input anything yet, hence the fields are all undefined
             */}
            <Button
              disabled={isButtonDisabled}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

import { FE_OEStatus } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function createOEStatus({
  oeId,
  oeStatus,
}: {
  oeId: number;
  oeStatus: Partial<FE_OEStatus>;
}): Promise<void> {
  const response = await fetch(`${STRAPI_URL}/api/bff/oe-status/${oeId}`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: oeStatus }),
  });

  if (response.status === 400) {
    throw await response.json();
  }
}

export async function updateOEStatus({
  oeId,
  oeStatus,
}: {
  oeId: number;
  oeStatus: Partial<FE_OEStatus>;
}): Promise<void> {
  if (!oeStatus.id) {
    throw new Error("Trying to update OE Status without an ID");
  }

  const response = await fetch(
    `${STRAPI_URL}/api/bff/oe-status/${oeId}/${oeStatus.id}`,
    {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: oeStatus }),
    }
  );

  if (response.status === 400) {
    throw await response.json();
  }
}

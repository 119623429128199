import type { ControlEnvConfig } from "tracy-types";

export const CONTROL_ENV_CONFIG = {
  1: {
    Process:
      "The underlying process and controls are highly stable and well established in governing policies and procedures. Where possible, controls have been automated to reduce the likelihood of control failure.",
    People:
      "Risk Owners have a strong appreciation and understanding of their responsibility to mitigate operational risks and are able to demonstrate proactive instances of doing so. Sufficient staff exists to properly execute the process and controls and staff is well trained to carry out each other’s responsibilities in the event that someone leaves the team or is otherwise unavailable. Staff is highly qualified and turnover exceptionally low.",
    Control:
      "Risk mitigation activities / controls are effective, continuously applied and well placed with extremely limited instances of risk mitigation activity / control failures. All risk mitigation activities / controls are efficient and able to automatically adjust to changes in the environment. Entity Level Controls are well established, regularly tested and known to be effective. Controls are regularly subject to independent, highquality control testing covering both control design and operating effectiveness. Operational losses only occur on an exceptional basis or not at all. For IT controls, all required control activities are performed in a real time by fully automated IT systems (e.g. IPS) with evidence available.",
    Culture:
      "Risk culture and awareness is firmly embedded into the associated function.",
  },
  2: {
    Process:
      "The underlying process and controls are stable, clearly defined, effective and complete. They are fully governed by documented policies and procedures.",
    People:
      "Risk Owners clearly understand their responsibility to mitigate operational risks. They are able to consistently translate this awareness into decision making, process and control improvements. Sufficient staff exists to properly execute the process and controls and staff is, with a few exceptions, well trained to carry out each other’s responsibilities in the event that someone leaves the team or is otherwise unavailable. Staff is adequately qualified and turnover is low.",
    Control:
      "Risk mitigation activities / controls are effective, continuously applied and well placed with some relatively isolated instances of risk mitigation failures. Very few risk mitigation activities / controls are neither efficient nor able to automatically adjust to changes in the environment. Controls are regularly tested. Entity Level Controls are established, regularly tested and known to be effective. Recent audit results were indicative of a strong control environment, with very few findings, if any, related to the control environment. Operational losses rarely occur. For IT controls, all required control activities are performed regularly by an IT tool or manually by an independent SME (e.g. pentest, vulnerability scan) with evidence available.",
    Culture:
      "Risk culture and awareness is well embedded into the associated function, although minor improvements might be made.",
  },
  3: {
    Process:
      "The underlying process and controls are fairly stable, clearly defined and complete but must occasionally be updated.",
    People:
      "Risk Owners are aware of their responsibility to mitigate operational risks, but perhaps do not, or are unable to, translate this awareness into decision making, process and control improvements, etc. as consistently as they would prefer. Sufficient staff exists to properly execute the process and controls, but a number of staff are new, not ideally qualified or otherwise inexperienced. A number of process steps or key controls are dependent on single individuals and it would cause a minor disruption were one of these individuals to leave the team or otherwise be unavailable.  Moderate turnover exists, but is for the most part manageable.",
    Control:
      "Risk mitigation activities / controls are in place, and continuously applied, but there may be minor instances of risk mitigation activity / control failures. Some risk mitigation activities / controls may are well placed and efficient but are not able to automatically adjust to changes in the environment. Associated Entity Level Controls are established, but some minor deficiencies are known to exist. Control testing is performed, but not on a regular basis (e.g. the most recent control testing was more than 5 years ago). The quality of control testing varies between rigorous control testing by well qualified independent parties and self-assessment by individuals with a limited understanding of control testing. Audit findings are present, but limited in number or significance and established plans to remediate any findings are adhered to. Operational losses sometimes occur, but not with any regularity.  Implementation of effective IT controls is confirmed by IT and the implementation is documented in detail within a guideline (e.g. ASS); with IT Operations obliged to implement the control objectives as documented, but control activities have not been completely executed.",
    Culture:
      "Risk culture and risk awareness is embedded into the associated function, but some key aspects of the risk culture could be improved or gain broader acceptance.",
  },
  4: {
    Process:
      "The underlying process and controls exist but they are unstable, not clearly defined, incomplete and/or heavily dependent on manual execution or intervention.",
    People:
      "Risk Owners do not clearly understand their responsibility manage operational risks, although their actions demonstrate that they actually do so but in an inconsistent manner. Insufficient staff is in place to properly execute the process and controls, or sufficient staff is present, but a large portion of the staff is new, not ideally qualified or otherwise inexperienced. Moderate turnover exists and is a frequent obstacle towards consistently being able to execute the underlying process and controls at an acceptable level of quality.",
    Control:
      "There have been frequent risk mitigation activity / control failures, or the risk mitigation activities / controls are found to be unsatisfactory or incomplete. Some controls may be in place, but are not continuously applied, or efficient. Associated Entity Level Controls are incomplete or numerous deficiencies are known to exist. Control testing is either rarely performed or not performed at all. In general the quality of any control testing and/or independence of control testers is poor. Numerous and/or significant audit findings are present, or audit findings remain unresolved subsequent to the passing of any established remediation deadlines. Operational losses occur on a relatively frequent basis.  Implementation of effective IT controls is only confirmed by IT verbally or via e-mail.",
    Culture:
      "The basics of an adequate risk culture are not firmly and consistently embedded into the associated function.",
  },
  5: {
    Process:
      "The underlying process is new or highly unstable and not clearly defined. If some processes exist they are ad hoc and potentially incomplete.",
    People:
      "Risk Owners do not acknowledge or understand their responsibility to mitigate operational risks. Insufficient staff is in place to properly execute the process and controls, staff lacks the required level of skills and training, and/or high staff turnover exists.",
    Control:
      "No risk mitigation activities / controls are operating or existing. If some mitigation activities occur they are ad hoc and incomplete. Operational losses occur on a regular basis. IT controls are unknown or critical vulnerabilities are known and not mitigated in a timely manner.",
    Culture:
      "Evidence of a risk culture in the associated function is not present.",
  },
} as const satisfies ControlEnvConfig;

import type { RatingLabels } from "tracy-types";

export const RATING_LABEL = {
  ProbabilityRating: {
    1: "Rare",
    2: "Unlikely",
    3: "Moderate",
    4: "Likely",
    5: "Almost Certain",
  },
  FinancialImpact: {
    1: "Very Low",
    2: "Low",
    3: "Medium",
    4: "High",
    5: "Very High",
  },
  ReputationalImpact: {
    0: "Not Affected",
    1: "Very Low",
    2: "Low",
    3: "Medium",
    4: "High",
    5: "Very High",
  },
  ControlEnv: {
    5: "Non-existent / Initial",
    4: "Poor / Repeatable",
    3: "Fair / Defined",
    2: "Good / Managed",
    1: "Very Good / Optimised",
  },
} as const satisfies RatingLabels;

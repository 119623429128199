import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { SideMenuEntryInterface } from "../../types";

interface MenuTabProps {
  entries: SideMenuEntryInterface[];
}

function LayoutSideBar({ entries }: MenuTabProps) {
  return (
    <>
      {entries.map((entry) => (
        <FirstLevelTab key={entry.title} tab={entry} />
      ))}
    </>
  );
}

function FirstLevelTab({ tab }: { tab: SideMenuEntryInterface }) {
  const [areChildrenExpanded, setChildrenExpanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  useEffect(() => {
    return setChildrenExpanded(
      !!(
        currentPath === tab.link ||
        tab.items?.some((item) => item.link && currentPath === item.link)
      )
    );
  }, [currentPath]);

  const isActive = currentPath === tab.link;

  const handleTabClick = () => {
    if (tab.link) {
      navigate(tab.link);
    }
  };

  return (
    <>
      <Box
        onClick={handleTabClick}
        sx={{
          marginLeft: "80px",
          cursor: "pointer",
          height: "28px",
          fontSize: "16px",
          fontWeight: isActive ? 600 : 400,
          borderLeft: `${isActive ? "#003781" : "#B2CED4"} 2px solid`,
          color: `${isActive ? "#003781" : "#000000"}`,
          padding: "32px 4px 32px 11px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          transition: "border-left-color 0.3s",
        }}
      >
        {tab.title}
        {(tab.items ?? []).length > 0 && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setChildrenExpanded(!areChildrenExpanded);
            }}
          >
            {areChildrenExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Box>
      {tab.items && (
        <Box
          sx={{
            maxHeight: areChildrenExpanded ? "200px" : 0,
            transition: "max-height 1s",
            overflow: "hidden",
          }}
        >
          {tab.items?.map((childTab: SideMenuEntryInterface) => (
            <SecondLevelTab tab={childTab} key={childTab.title} />
          ))}
        </Box>
      )}
    </>
  );
}

function SecondLevelTab({ tab }: { tab: SideMenuEntryInterface }) {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const isActive = currentPath === tab.link;

  const handleTabClick = () => {
    if (tab.link) {
      navigate(tab.link);
    }
  };

  return (
    <Box
      onClick={handleTabClick}
      sx={{
        marginLeft: "80px",
        cursor: "pointer",
        height: "10px",
        fontSize: "14px",
        fontWeight: isActive ? 600 : 400,
        borderLeft: `${isActive ? "#003781" : "#B2CED4"} 2px solid`,
        color: `${isActive ? "#003781" : "#000000"}`,
        padding: "16px 4px 16px 24px",
        display: "flex",
        alignItems: "center",
        transition: "border-left-color 0.3s",
      }}
    >
      {tab.title}
    </Box>
  );
}
export { LayoutSideBar };
export default LayoutSideBar;

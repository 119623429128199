import type { PermissionResponse } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchPermissions(): Promise<PermissionResponse> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff/permissions`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as PermissionResponse;
  } catch {
    return [];
  }
}

import { Button, Card, Stack, Switch, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect, useState } from "react";

type GeneralSettingsEventNames = "group-dashboard-settings-changed";

type GeneralSettingsPayload = {
  lockGroupDashboardForOEs: boolean;
};

export type GeneralSettingsEventPayload = {
  lockGroupDashboardForOEs: boolean;
};

export const GeneralSettingsPage: PimoReactComponent<
  GeneralSettingsPayload,
  GeneralSettingsEventNames,
  GeneralSettingsEventPayload
> = ({ lockGroupDashboardForOEs, fireEvent }) => {
  const [formData, setFormData] = useState({
    lockGroupDashboardForOEs: lockGroupDashboardForOEs,
  });

  const [initialLockGroupDashboardForOEs, setInitialLockGroupDashboardForOEs] =
    useState(lockGroupDashboardForOEs);

  useEffect(() => {
    setFormData({
      lockGroupDashboardForOEs: lockGroupDashboardForOEs,
    });
    setInitialLockGroupDashboardForOEs(lockGroupDashboardForOEs);
  }, [lockGroupDashboardForOEs]);

  const hasChanges =
    formData.lockGroupDashboardForOEs !== initialLockGroupDashboardForOEs;

  const handleSubmit = () => {
    fireEvent?.("group-dashboard-settings-changed", { ...formData });

    setInitialLockGroupDashboardForOEs(formData.lockGroupDashboardForOEs);
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        boxShadow: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">General Settings</Typography>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center" mb={6}>
        <Switch
          checked={formData.lockGroupDashboardForOEs}
          onChange={(e) =>
            setFormData({
              ...formData,
              lockGroupDashboardForOEs: e.target.checked,
            })
          }
        />
        <Typography sx={{ ml: 2 }}>
          Disable Group Dashboard for all users
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" sx={{ mt: "auto" }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          sx={{ ml: 2 }}
          disabled={!hasChanges}
        >
          SAVE
        </Button>
      </Stack>
    </Card>
  );
};

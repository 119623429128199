import type {
  FE_OrganizationalEntity,
  FilterData,
  OECluster,
} from "tracy-types";
import { buildURLParameters } from "tracy-utils";

import { STRAPI_URL } from "../../env";

export async function fetchOEs(
  payload?: FilterData
): Promise<FE_OrganizationalEntity[]> {
  try {
    const params = buildURLParameters(payload);

    const response = await fetch(
      `${STRAPI_URL}/api/bff/oes?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as FE_OrganizationalEntity[];

    return json.sort((a, b) => a.name.localeCompare(b.name));
  } catch {
    return [];
  }
}

export async function fetchOEsForSettings(
  year: number,
  filters?: FilterData
): Promise<{
  oeClusters: OECluster[];
  oes: (FE_OrganizationalEntity & { hasAllDefaultScenarios: boolean })[];
}> {
  try {
    const params = buildURLParameters(filters);

    const response = await fetch(
      `${STRAPI_URL}/api/bff-settings/oes/year/${year}?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as {
      oeClusters: OECluster[];
      oes: (FE_OrganizationalEntity & { hasAllDefaultScenarios: boolean })[];
    };

    return json;
  } catch {
    return { oeClusters: [], oes: [] };
  }
}

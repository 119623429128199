import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useState } from "react";

type Comment = {
  id?: number;
  author: string;
  comment: string;
  date: string;
};

export type CommentListEvent = "add-comment" | "close";
export type CommentListPayload = { comment: string };

export type CommentListProps = {
  comments: Comment[];
  headline?: string;
  slot?: React.ReactNode;
};

export const CommentList: PimoReactComponent<
  CommentListProps,
  CommentListEvent,
  CommentListPayload
> = ({ headline, comments, fireEvent, slot }) => {
  const theme = useTheme();
  const [newComment, setNewComment] = useState("");

  const handleAddComment = () => {
    if (newComment.trim() !== "" && fireEvent) {
      fireEvent?.("add-comment", { comment: newComment.trim() });
      setNewComment("");
    }
  };

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      {(headline || slot) && (
        <>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {headline && <Typography variant="h6">{headline}</Typography>}
            {slot}
          </Box>

          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "2px",
              borderWidth: 0,
            }}
          />
        </>
      )}

      <Box sx={{ mt: 2 }}>
        {comments.map(({ author, comment, date, id }) => (
          <Box key={id} sx={{ mb: 2 }}>
            <Typography
              sx={{ color: theme.palette.grey[600], fontSize: "12px", mb: 0.5 }}
            >
              {author} on {date}
            </Typography>
            <Box
              sx={{
                bgcolor: theme.palette.secondary.light,
                p: 2,
                borderRadius: "8px",
              }}
            >
              <Typography>{comment}</Typography>
            </Box>
          </Box>
        ))}
      </Box>

      {comments.length ? (
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "2px",
            borderWidth: 0,
            my: 2,
          }}
        />
      ) : null}

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2">Write a new comment</Typography>
        <TextField
          fullWidth
          multiline
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Write a new comment"
          rows={3}
          value={newComment}
          variant="outlined"
        />
        <Box
          sx={{ display: "flex", gap: 1, justifyContent: "flex-end", mt: 2 }}
        >
          <Button onClick={() => fireEvent?.("close")}>Close</Button>
          <Button
            onClick={handleAddComment}
            startIcon={<SendIcon />}
            variant="contained"
          >
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CommentList;

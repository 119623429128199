import { FE_Scenario } from "tracy-types";

import { STRAPI_URL } from "../../env";

export function addNewScenario({
  oeId,
  year,
}: {
  oeId: number;
  year: number;
}): Promise<FE_Scenario> {
  return fetch(`${STRAPI_URL}/api/bff/scenarios/${oeId}/${year}`, {
    credentials: "include",
    method: "POST",
  }).then((response) => response.json()) as Promise<FE_Scenario>;
}

export async function deleteScenario({
  oeId,
  scenarioId,
}: {
  oeId: number;
  scenarioId: number;
}): Promise<void> {
  await fetch(`${STRAPI_URL}/api/bff/scenarios/${oeId}/${scenarioId}`, {
    credentials: "include",
    method: "DELETE",
  });
}

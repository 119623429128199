/* eslint-disable react-refresh/only-export-components */

import { CheckCircleRounded } from "@mui/icons-material";
import type { FC } from "react";
import type { ScenarioProgress, StepCount } from "tracy-types";

import { STATUS_COLORS } from "../../constants";
import { SCENARIO_STEPS_BY_CATEGORY, ScenarioFlowCategory } from "./types";

export type ScneariosStatusIndicatorProps = {
  status: keyof typeof STATUS_COLORS;
};

export function getStatus(options: {
  step?: StepCount;
  progress?: ScenarioProgress;
  /** if set, all steps from the given category will be taken into consideration */
  category?: ScenarioFlowCategory;
}): keyof typeof STATUS_COLORS {
  if (!options.progress) {
    return "open";
  }

  if (options.category) {
    const stepsInCategory = SCENARIO_STEPS_BY_CATEGORY[options.category];
    const progressForStepsInCategory = stepsInCategory.map(
      (step) => options.progress![step]
    );

    switch (true) {
      case progressForStepsInCategory.every((status) => status === "completed"):
        return "completed";
      case progressForStepsInCategory.includes("started"):
      case progressForStepsInCategory.includes("completed"):
        return "started";
      case progressForStepsInCategory.every((status) => status === "open"):
      default:
        return "open";
    }
  }

  if (options.step == null) {
    return options.progress?.total;
  }

  return options.progress?.[options.step] ?? "open";
}

export const ScenariosStatusIndicator: FC<ScneariosStatusIndicatorProps> = ({
  status,
}) => {
  return (
    <CheckCircleRounded
      sx={{
        color: STATUS_COLORS[status],
        backgroundColor: "white",
        borderRadius: 100,
      }}
    />
  );
};

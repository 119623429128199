import { ScenariosForm } from "../scenarios-form";
import type { ScenariosFormComponent } from "../types";
import { ReputationalImpactOfCyberRiskForm } from "./reusable-step-components/3-and-9-reputational-impact-of-cyber-risk";

export const ReputationImpactOfCurrentCyberRisk: ScenariosFormComponent = (
  props
) => {
  const { oeRatings, oeReportPage, scenario, step } = props;

  if (!oeRatings || !oeReportPage || !scenario || !step) {
    return <></>;
  }

  return (
    <ScenariosForm {...props}>
      <ReputationalImpactOfCyberRiskForm {...props} riskType="current" />
    </ScenariosForm>
  );
};

import { Box } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getColorSchemeForRating, getImpactSeverity } from "tracy-utils";

import { RATING_LABEL } from "../../../config/rating-label";
import { Arrow } from "../../arrow";
import { ImpactBox } from "../../impact-box";
import { ScenariosForm } from "../scenarios-form";
import { type ScenariosFormComponent, ScenariosFormValues } from "../types";

export const ImpactSeverityOfCurrentCyberRisk: ScenariosFormComponent = (
  props
) => {
  const { oeRatings, oeReportPage, scenario, step } = props;

  if (!oeRatings || !oeReportPage || !scenario || !step) {
    return <></>;
  }

  return (
    <ScenariosForm {...props}>
      <ImpactSeverityOfCurrentCyberRiskForm {...props} riskType="current" />
    </ScenariosForm>
  );
};

const ImpactSeverityOfCurrentCyberRiskForm: ScenariosFormComponent<{
  riskType: "current" | "residual";
}> = ({ riskType = "current", formValues }) => {
  const { setValue } = useFormContext<ScenariosFormValues>();
  const financialImpactRating = formValues?.financialImpact?.[riskType]?.rating;

  const reputationalImpactRating =
    formValues?.reputationalImpact?.[riskType]?.rating;

  const impactSeverityRating = getImpactSeverity({
    financialImpactRating,
    reputationalImpactRating,
  });

  useEffect(() => {
    if (impactSeverityRating) {
      setValue(`impactSeverity.${riskType}.rating`, impactSeverityRating);
    }
  }, [impactSeverityRating, setValue, riskType]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "740px",
        position: "relative",
        marginTop: "80px",
      }}
    >
      <ImpactBox
        name="Financial Impact Rating (Step 2)"
        impact={financialImpactRating}
        impactName={
          financialImpactRating != null
            ? RATING_LABEL.FinancialImpact[financialImpactRating]
            : undefined
        }
        sx={{ position: "absolute", left: "calc(50% - 350px)", top: 0 }}
        background={
          getColorSchemeForRating(financialImpactRating).colorScheme.chipColor
        }
        color={
          getColorSchemeForRating(financialImpactRating).colorScheme.textColor
        }
      />
      <ImpactBox
        name="Reputational Impact Rating (Step 3)"
        impact={reputationalImpactRating}
        impactName={
          reputationalImpactRating != null
            ? RATING_LABEL.ReputationalImpact[reputationalImpactRating]
            : undefined
        }
        sx={{ position: "absolute", right: "calc(50% - 350px)", top: 0 }}
        background={
          getColorSchemeForRating(reputationalImpactRating).colorScheme
            .chipColor
        }
        color={
          getColorSchemeForRating(reputationalImpactRating).colorScheme
            .textColor
        }
      />
      <ImpactBox
        name="Impact Severity"
        impact={impactSeverityRating}
        impactName={
          impactSeverityRating != null
            ? RATING_LABEL.FinancialImpact[impactSeverityRating]
            : undefined
        }
        sx={{ position: "absolute", right: "calc(50% - 140px)", top: "198px" }}
        background={
          getColorSchemeForRating(impactSeverityRating).colorScheme.chipColor
        }
        color={
          getColorSchemeForRating(impactSeverityRating).colorScheme.textColor
        }
      />
      <Arrow
        direction="top-left-bottom-right"
        length={40}
        style={{
          position: "absolute",
          top: "148px",
          left: "calc(50% - 150px)",
        }}
      />
      <Arrow
        direction="top-right-bottom-left"
        length={40}
        style={{
          position: "absolute",
          top: "148px",
          right: "calc(50% - 150px)",
        }}
      />
    </Box>
  );
};

import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { RiskType } from "tracy-types";

type ChipTableCellProp = {
  text: string;
  chipColor: string;
  textColor: string;
};

export type AssessmentTableEntries = {
  name: string;
  financialRating: ChipTableCellProp;
  impactRating: ChipTableCellProp;
  impactSeverity: ChipTableCellProp;
  probabilityRating: ChipTableCellProp;
  overallRating: ChipTableCellProp;
  topRisk: ChipTableCellProp;
  delta?: ChipTableCellProp;
}[];

type AssessmentTableProps = {
  entries: AssessmentTableEntries;
  riskType?: RiskType;
};

const ChipTableCell = (details: {
  text: string;
  chipColor: string;
  textColor: string;
}) => (
  <TableCell align="center" sx={{ borderColor: "#DFEFF2" }}>
    <Chip
      label={details.text}
      sx={{
        background: details.chipColor,
        height: 28,
        color: details.textColor,
        minWidth: 150,
      }}
    />
  </TableCell>
);

export const AssessmentTable: FC<AssessmentTableProps> = ({
  entries,
  riskType,
}) => {
  const theme = useTheme();
  const style = {
    sx: {
      textAlign: "center",
      borderColor: theme.palette.secondary.main,
    },
  };
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Scenario Name</TableCell>
          <TableCell {...style}>Fin. Impact Rating</TableCell>
          <TableCell {...style}>Rep. Impact Rating</TableCell>
          <TableCell {...style}>Impact Severity</TableCell>
          <TableCell {...style}>Prob. Rating</TableCell>
          <TableCell {...style}>Overall Rating</TableCell>
          <TableCell {...style}>Top Risk</TableCell>
          {riskType === "residual" && (
            <TableCell {...style}>Difference</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {entries?.map((row) => (
          <TableRow
            key={row.name}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{ borderColor: theme.palette.secondary.main }}
            >
              {row.name}
            </TableCell>
            <ChipTableCell {...row.financialRating} />
            <ChipTableCell {...row.impactRating} />
            <ChipTableCell {...row.impactSeverity} />
            <ChipTableCell {...row.probabilityRating} />
            <ChipTableCell {...row.overallRating} />
            <ChipTableCell {...row.topRisk} />
            {row.delta && <ChipTableCell {...row.delta} />}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

import { Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Markdown, TitleCard, TitleCardProps } from "@pimo/pimo-components";
import React, { useState } from "react";
import { UpdateStatus } from "tracy-types";

import { theme } from "../../theme";
import { TracyStatusIndicator } from "../tracy-status-indicator";

type EndSlotProps = {
  updateStatus?: UpdateStatus;
  processDescription?: string;
};

export type OEReportTitleCardProps = TitleCardProps &
  EndSlotProps & {
    completionDate?: string;
    isLocked?: boolean;
    isSubmitReportButtonEnabled: boolean;
  };

const UpdateStatusAndProcessDescription: React.FC<EndSlotProps> = ({
  updateStatus,
  processDescription,
}) => {
  const [isProcessDescriptionModelOpen, setIsProcessDescriptionModelOpen] =
    useState(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <Typography>Overall Update </Typography>
        <TracyStatusIndicator updateStatus={updateStatus} />
        <Box sx={{ ml: 2 }} />
        <Typography>Process Description</Typography>
        <IconButton onClick={() => setIsProcessDescriptionModelOpen(true)}>
          <Info />
        </IconButton>
      </Box>
      <Dialog
        open={isProcessDescriptionModelOpen}
        onClose={() => setIsProcessDescriptionModelOpen(false)}
        fullScreen
        PaperProps={{ sx: { borderRadius: 5 } }}
        sx={{ m: 4 }}
      >
        <Box
          sx={{
            background: theme.palette.background.paper,
            overflowY: "auto",
            p: 3,
          }}
        >
          <Typography component="h2" variant="h5">
            Process Description
          </Typography>
          <Divider sx={{ mt: 2, mb: 3 }} />
          <Typography component="span">
            <Markdown>{processDescription ?? ""}</Markdown>
          </Typography>
        </Box>
        <DialogActions sx={{ p: 3 }}>
          <Button
            variant="contained"
            onClick={() => setIsProcessDescriptionModelOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const OEReportTitleCard: PimoReactComponent<
  OEReportTitleCardProps,
  "submit-report"
> = ({
  updateStatus,
  processDescription,
  completionDate,
  isSubmitReportButtonEnabled,
  isLocked,
  fireEvent,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TitleCard
        {...props}
        endSlot={
          <UpdateStatusAndProcessDescription
            updateStatus={updateStatus}
            processDescription={processDescription}
          />
        }
      />
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
        PaperProps={{ sx: { borderRadius: 5 } }}
      >
        <DialogTitle id="alert-dialog-title">Submit Report?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit the report? After the submission no
            changes can be made.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Close</Button>
          <Button
            onClick={() => {
              setIsOpen(false);
              fireEvent?.("submit-report");
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Report can be submitted only once finalized.">
        <span>
          <Button
            type="submit"
            disabled={!isSubmitReportButtonEnabled}
            sx={{
              position: "absolute",
              right: 20,
              bottom: 10,
              height: 40,
              fontSize: "12px",
              opacity: 1,
              background: isLocked
                ? "#00C853 !important"
                : isSubmitReportButtonEnabled
                  ? "#003781 !important"
                  : "#C4C4C4 !important",
              color: "#ffffff !important",
              boxShadow: `3px 4px 10px grey`,
              borderRadius: 20,
              zIndex: 1000,
            }}
            onClick={() => setIsOpen(true)}
          >
            {isLocked
              ? completionDate
                ? `Submitted on ${completionDate}`
                : `Submitted`
              : `Submit Report`}
          </Button>
        </span>
      </Tooltip>
    </>
  );
};

export default OEReportTitleCard;

import { Box, CircularProgress } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";

export type ScenariosLoader = {
  isLoading?: boolean;
};

export const ScenariosLoader: PimoReactComponent<ScenariosLoader> = ({
  isLoading,
}) => {
  return isLoading ? (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        justifyContent: "center",
        display: "flex",
        height: "100%",
        inset: 0,
        position: "absolute",
        width: "100%",
        zIndex: 1400,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  ) : null;
};

import { App, Plugin, Route } from "@pimo/pimo-app-builder";
import { GridLayout } from "@pimo/pimo-components";
import type {
  AvailableGroupDashboardPage,
  LockedGroupDashboardPage,
} from "tracy-types";
import { has1In20YearsProbability } from "tracy-utils";

import { TracyAppState } from "../../app";
import { DashboardTitleCard } from "../../components/dashboard-title-card";
import { ResultsDisclaimer } from "../../components/group-dashboard/results-disclaimer";
import { TopRisksAndScenarioRanking } from "../../components/group-dashboard/top-risks-and-scenario-ranking";
import { RiskMatrix } from "../../components/risk-matrix/risk-matrix";
import { TopRiskMatrix } from "../../components/top-risk-matrix/top-risk-matrix";
import { APP_ROUTES } from "../../constants";
import { fetchGroupDashboardPage } from "../../helpers/fetch/fetch-group-dashboard-page";

export class GroupDashboardPlugin implements Plugin<TracyAppState> {
  route?: Route;
  onRegister(app: App<TracyAppState>): void {
    const view = app.createView({
      name: "Group Dashboard",
      layout: new GridLayout(),
    });

    const headline = view.addComponent({
      component: DashboardTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    headline.mapState(({ year, filterState, oeClusters }) => {
      return {
        title: `Group Dashboard - TRACY (Current Risk for ${year})`,
        filterData: filterState ?? {
          clusterFilter: [],
        },
        filterValues: {
          clusters: oeClusters.map((cluster) => cluster.name),
        },
      };
    });

    headline.on("filter:apply", async ({ payload }) => {
      const state = app.getAppState();
      const dashboardPage = await fetchGroupDashboardPage(state.year, payload);

      if (!state || !dashboardPage) {
        return;
      }

      app.patchAppState({
        dashboardPage,
        filterState: payload,
      });
    });

    headline.on("filter:clear", async () => {
      const state = app.getAppState();
      const dashboardPage = await fetchGroupDashboardPage(
        app.getAppState().year
      );

      if (!state || !dashboardPage) {
        return;
      }
      app.patchAppState({
        dashboardPage,
        filterState: {
          clusterFilter: [],
        },
      });
    });

    const topRisksAndScenarioRanking = view.addComponent({
      component: TopRisksAndScenarioRanking,
      layoutProps: {
        xs: 12,
      },
    });

    topRisksAndScenarioRanking.mapVisibility((state) => {
      const { dashboardPage } = state;

      if (!dashboardPage) {
        return false;
      }

      return !(dashboardPage as LockedGroupDashboardPage)?.isLocked;
    });

    topRisksAndScenarioRanking.mapState((state) => {
      const dashboardPage = state.dashboardPage as AvailableGroupDashboardPage;

      const donutChartProps = {
        backgroundColor: ["#F86200", "#013176"],
        cardProps: {
          sx: { flex: 1 },
        },
        labels: ["OEs with Top Risk", "OEs without Top Risk"],
        toolTipEnabled: true,
        toolTipLabels: ["OEs with Top Risk", "OEs without Top Risk"],
      };

      return {
        scenariosRankedByTopRisks: {
          progressBars: dashboardPage?.scenariosRankedByTopRisks?.map(
            ({ name, amount, percentage }) => ({
              title: name,
              actualValue: amount,
              progress: percentage,
            })
          ),
          title: [
            "Ranking of Cyber Top Risks Scenarios",
            dashboardPage?.total && `out of ${dashboardPage.total} OEs`,
          ]
            .filter(Boolean)
            .join(" "),
        },
        topRisksWithoutOverruling: {
          ...donutChartProps,
          series: [
            dashboardPage?.withTopRiskBeforeOverruling ?? 0,
            dashboardPage?.withoutTopRiskBeforeOverruling ?? 0,
          ],
          title: [
            "OEs with Cyber Risk as a Top Risk before overruling",
            dashboardPage?.total && `(total ${dashboardPage.total})`,
          ]
            .filter(Boolean)
            .join(" "),
        },
        topRisksWithOverruling: {
          ...donutChartProps,
          series: [
            dashboardPage?.withTopRiskAfterOverruling ?? 0,
            dashboardPage?.withoutTopRiskAfterOverruling ?? 0,
          ],
          title: [
            "OEs with Cyber Risk as a Top Risk after overruling",
            dashboardPage?.total && `(total ${dashboardPage.total})`,
          ]
            .filter(Boolean)
            .join(" "),
        },
      };
    });

    const riskMatrix = view.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 12 },
    });

    riskMatrix.mapVisibility((state) => {
      const { dashboardPage, year } = state;

      if (!dashboardPage || has1In20YearsProbability(year)) {
        return false;
      }

      return !(dashboardPage as LockedGroupDashboardPage)?.isLocked;
    });

    riskMatrix.mapState((state) => {
      const dashboardPage = state.dashboardPage as AvailableGroupDashboardPage;

      return {
        entries:
          dashboardPage?.oesGroupedByFinancialAndReputationalRatings ?? [],
        legendLabel: "#OEs",
        showAmount: true,
        title: [
          `Financial vs Non Financial Impact`,
          typeof dashboardPage?.total === "number" &&
            `(total ${dashboardPage?.total})`,
        ]
          .filter(Boolean)
          .join(" "),
        xAxisLabel: "Financial Impact",
        yAxisLabel: "Reputational Impact",
      };
    });

    const heatMapTopRiskAssessment = view.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 6 },
    });

    heatMapTopRiskAssessment.mapState((state) => {
      const dashboardPage = state.dashboardPage as AvailableGroupDashboardPage;

      return {
        entries:
          dashboardPage?.oesGroupedByFinancialAndReputationalRatings ?? [],
        legendLabel: "#OEs",
        showAmount: true,
        title: "Heat Map (Top Risk Assessment)",
        xAxisLabel: "Financial Impact",
        yAxisLabel: "Reputational Impact",
      };
    });

    const heatMap1In20YearsProbability = view.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 6 },
    });

    heatMap1In20YearsProbability.mapState((state) => {
      const dashboardPage = state.dashboardPage as AvailableGroupDashboardPage;

      return {
        entries:
          dashboardPage?.oesGroupedByFinancialAndReputationalProbabilityRatings ??
          [],
        legendLabel: "#OEs",
        showAmount: true,
        title: "Heat Map (1 in 20 years probability)",
        xAxisLabel: "Financial Impact",
        yAxisLabel: "Reputational Impact",
      };
    });

    [heatMapTopRiskAssessment, heatMap1In20YearsProbability].forEach(
      (component) => {
        component.mapVisibility((state) => {
          const { dashboardPage, year } = state;

          if (!dashboardPage || !has1In20YearsProbability(year)) {
            return false;
          }

          return !(dashboardPage as LockedGroupDashboardPage)?.isLocked;
        });
      }
    );

    const topRiskMatrix = view.addComponent({
      component: TopRiskMatrix,
      layoutProps: { xs: 12 },
    });

    topRiskMatrix.mapVisibility((state) => {
      const { dashboardPage } = state;

      if (!dashboardPage) {
        return false;
      }

      return !(dashboardPage as LockedGroupDashboardPage)?.isLocked;
    });

    topRiskMatrix.mapState((state) => {
      const dashboardPage = state.dashboardPage as AvailableGroupDashboardPage;

      return {
        title: [
          `Overall Probability Rating vs. Overall Impact Severity`,
          typeof dashboardPage?.total === "number" &&
            `(total ${dashboardPage?.total})`,
        ]
          .filter(Boolean)
          .join(" "),
        entries: dashboardPage?.oesGroupedByImpactAndProbabilityRatings ?? [],
      };
    });

    const resultsDisclaimer = view.addComponent({
      component: ResultsDisclaimer,
      layoutProps: {
        xs: 12,
      },
    });

    resultsDisclaimer.mapVisibility((state) => {
      const { dashboardPage } = state;

      if (!dashboardPage) {
        return false;
      }

      return (dashboardPage as LockedGroupDashboardPage)?.isLocked;
    });

    this.route = app.createRoute({
      path: APP_ROUTES.groupDashboard,
      view,
    });

    this.route.on("load", async () => {
      const { year } = app.getAppState();

      const dashboardPage = await fetchGroupDashboardPage(year);

      app.patchAppState({
        filterState: undefined,
        dashboardPage,
      });
    });
  }
}

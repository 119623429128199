import { SubversionForTechnicalVulnerabilityExploitation } from "tracy-types";

export function getSubversionTitle(
  subversion: SubversionForTechnicalVulnerabilityExploitation | string
): string {
  switch (subversion) {
    case "Data Stealing Motivation":
      return "Data Stealing Variation";
    case "Financial Motive Variation":
      return "Financial Motive Variation";
  }

  return subversion;
}

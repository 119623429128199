import { ArrowForward } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { PimoComponent } from "@pimo/pimo-app-builder";
import React from "react";
import { ScenarioStepStatus } from "tracy-types";

import { ScenariosStatusIndicator } from "../scenarios/scenarios-status-indicator";

export interface StatusOfReportingCardProps {
  title: string;
  entries: {
    label: string;
    updateStatus: ScenarioStepStatus;
    linkTo: string;
  }[];
  button?: {
    label: string;
    onClick: () => void;
  };
}

export const StatusOfReportingCard: PimoComponent<
  StatusOfReportingCardProps
> = ({ title, entries, button }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        borderRadius: "12px",
        height: "100%",
        pb: 2,
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
          width: "100%",
          mb: 1,
        }}
      />
      <Box
        sx={{
          px: 2,
          minHeight: 130,
        }}
      >
        {entries.map(({ label, updateStatus, linkTo }, index) => (
          <React.Fragment key={label}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                px: 3,
                py: 1,
                cursor: "pointer",
              }}
              onClick={() => {
                const section = document.getElementById(linkTo);
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <Typography>{index + 1}.</Typography>
              <Typography sx={{ flex: `0 0 70%` }}>{label}</Typography>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <ScenariosStatusIndicator status={updateStatus} />
                <ArrowForward />
              </Box>
            </Box>
            <Divider sx={{ mx: 2 }} />
          </React.Fragment>
        ))}
      </Box>
      {button && (
        <Box sx={{ display: "flex", p: 6, justifyContent: "center" }}>
          <Button onClick={button.onClick} variant="outlined">
            {button.label}
          </Button>
        </Box>
      )}
    </Card>
  );
};

import { FE_Mitigation } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchUpdateMitigationAction(
  mitigationAction: FE_Mitigation,
  oeId: number
): Promise<void> {
  if (!mitigationAction?.id || !oeId) {
    return;
  }

  await fetch(
    `${STRAPI_URL}/api/bff/mitigation-actions/${oeId}/${mitigationAction.id}`,
    {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mitigationAction),
    }
  );
}

export async function fetchDeleteMitigationAction(
  mitigationActionId: number,
  oeId: number
): Promise<void> {
  if (!mitigationActionId || !oeId) {
    return;
  }

  await fetch(
    `${STRAPI_URL}/api/bff/mitigation-actions/${oeId}/${mitigationActionId}`,
    {
      credentials: "include",
      method: "DELETE",
    }
  );
}

import { ArrowBack } from "@mui/icons-material";
import { Box, Button, type ButtonProps } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";

export type ScenariosActionsProps = {
  cancel?: ButtonProps;
  close?: ButtonProps;
  next?: ButtonProps;
  prev?: ButtonProps;
};

export type ScenarioActionEvent =
  | "actions:cancel"
  | "actions:close"
  | "actions:next"
  | "actions:prev";

export const ScenariosActions: PimoReactComponent<
  ScenariosActionsProps,
  ScenarioActionEvent
> = ({ cancel, close, next, prev, fireEvent }) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        p: 2,
        width: "100%",
      }}
    >
      <Box>
        {prev && (
          <Button
            startIcon={<ArrowBack />}
            {...prev}
            onClick={() => fireEvent?.("actions:prev")}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        {cancel && (
          <Button {...cancel} onClick={() => fireEvent?.("actions:cancel")} />
        )}
        {close && (
          <Button {...close} onClick={() => fireEvent?.("actions:close")} />
        )}
        {next && (
          <Button {...next} onClick={() => fireEvent?.("actions:next")} />
        )}
      </Box>
    </Box>
  );
};

import type { SxProps } from "@mui/material";
import { grey } from "@mui/material/colors";

export const APP_ROUTES = {
  groupDashboard: "/group-dashboard",
  login: "/login",
  oeOverview: "/oe-overview",
  oeReport: "/oe-reports/:oeId",
  oeReportComments: "/oe-reports/:oeId/:year/comments",
  oeReportEditSettings: "/oe-reports/:oeId/edit/settings",
  oeReportEditEnvEffectiveness: "/oe-reports/:oeId/edit/env-effectiveness",
  oeReportEditProbability: "/oe-reports/:oeId/edit/probability",
  oeReportShortCutAssessment:
    "/oe-reports/:oeId/shortcut-assessment/:scenarioId",
  oeReportSectionLocked: "/oe-reports/:oeId/section-locked",
  oeScenarios: "/oe-reports/:oeId/scenarios/:scenarioId",
  oeReportScenarioOverview: "/oe-reports/:oeId/edit/scenario-overview",
  settingsNotifications: "/settings/notifications",
  settingsManageOEs: "/settings/manage-OEs",
} as const;

export const DISABLED_INPUT_STYLES: SxProps = {
  backgroundColor: grey[100],
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: grey[700],
  },
};

export const STATUS_COLORS = {
  open: "#C4C4C4",
  started: "#FFC107",
  completed: "#00C853",
} as const;

export type ScenarioStatus = keyof typeof STATUS_COLORS;

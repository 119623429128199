import { Info } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  CommentList,
  CommentListEvent,
  CommentListPayload,
  type CommentListProps,
} from "@pimo/pimo-components";
import { useEffect, useRef, useState } from "react";

const DEFAULT_INTERVAL = 30 * 1000; // 30 seconds

export type CommentDialogProps = CommentListProps;
export type CommentDialogEvent = CommentListEvent | "fetch";
export type CommentDialogPayload = CommentListPayload;

export const CommentsDialog: PimoReactComponent<
  CommentDialogProps,
  CommentDialogEvent,
  CommentDialogPayload
> = (props) => {
  const { fireEvent } = props;
  const theme = useTheme();
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (fireEvent) {
      timer.current = setTimeout(() => {
        fireEvent?.("fetch");
      }, DEFAULT_INTERVAL);
    }

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [fireEvent]);

  return (
    <>
      <Dialog
        onClose={() => fireEvent?.("close")}
        open
        PaperProps={{
          sx: { borderRadius: "12px", minWidth: "60vw", maxWidth: "80vw" },
        }}
        sx={{ margin: 8 }}
      >
        <CommentList
          {...props}
          slot={
            <IconButton onClick={() => setIsOpen(true)}>
              <Info />
            </IconButton>
          }
        />
      </Dialog>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{ sx: { borderRadius: 5 } }}
        sx={{ m: 4 }}
      >
        <Box
          sx={{
            background: theme.palette.background.paper,
            p: 3,
          }}
        >
          <Typography>
            Use this section to provide feedback on your assessment or changes.
            Your insights will help keep your team informed and ensure alignment
            on any changes made.
          </Typography>
        </Box>
        <DialogActions sx={{ p: 3 }}>
          <Button variant="contained" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Chip } from "@mui/material";
import React from "react";

export const TopRiskChip: React.FC<{ label: string }> = ({ label }) => (
  <Chip
    sx={{
      background: label === "N/A" ? "#C4C4C4" : "#2196F3",
      height: 28,
      ml: 2,
      pl: 1,
      pr: 1,
      color: "#fff",
      fontWeight: "bold",
    }}
    label={label}
  />
);

import { ScenariosForm } from "../scenarios-form";
import type { ScenariosFormComponent } from "../types";
import { FinancialImpactOfCyberRiskForm } from "./reusable-step-components/2-and-8-financial-impact-of-cyber-risk-form";

export const FinancialImpactOfResidualCyberRisk: ScenariosFormComponent = (
  props
) => {
  const { oeRatings, oeReportPage, scenario, step } = props;

  if (!oeRatings || !oeReportPage || !scenario || !step) {
    return <></>;
  }

  return (
    <ScenariosForm {...props}>
      <FinancialImpactOfCyberRiskForm {...props} riskType="residual" />
    </ScenariosForm>
  );
};

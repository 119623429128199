import { Box, BoxProps, Typography } from "@mui/material";

import { IMPACT_BOX_HEIGHT, IMPACT_BOX_WIDTH } from "./impact-box";
import { TopRiskChip } from "./top-risk-chip";

export const TopRiskBox: React.FC<{
  topRisk?: boolean;
  sx?: BoxProps;
}> = ({ topRisk, sx }) => {
  const label = topRisk == null ? "N/A" : topRisk ? "YES" : "NO";

  return (
    <Box
      sx={{
        ...sx,
        border: "#C4C4C4 1px solid",
        borderRadius: "5px",
        width: `${IMPACT_BOX_WIDTH}px`,
        height: `${IMPACT_BOX_HEIGHT}px`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <Typography
        component="span"
        sx={{ fontWeight: "bold", display: "inline" }}
      >
        Top Risk
      </Typography>
      <TopRiskChip label={label} />
    </Box>
  );
};

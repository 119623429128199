import { CheckCircle } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import React from "react";
import type { UpdateStatus } from "tracy-types";

export type CheckBoxCellProps = { updateStatus?: UpdateStatus };

export const CheckBoxCell: React.FC<CheckBoxCellProps> = ({ updateStatus }) => {
  const theme = useTheme();

  switch (updateStatus) {
    case "up-to-date":
      return (
        <Box sx={{ px: 3 }}>
          <CheckCircle sx={{ fill: theme.palette.success.main }} />
        </Box>
      );
    case "in-progress":
      return (
        <Box sx={{ px: 3 }}>
          <CheckCircle sx={{ fill: "#FFC107" }} />
        </Box>
      );
    case "outdated":
      return (
        <Box sx={{ px: 3 }}>
          <CheckCircle sx={{ fill: "#979797" }} />
        </Box>
      );
    default:
      return null;
  }
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CommonRatingValue,
  type FE_Scenario,
  FINANCIAL_RATING,
  PROBABILITY_RATING,
  REPUTATIONAL_RATING,
  RISK_TYPE,
  ShortcutAssessmentFormValues,
} from "tracy-types";

import { RATING_LABEL } from "../../config/rating-label";

export type ShortcutAssessmentDialogProps = {
  isLocked?: boolean;
  scenario?: FE_Scenario;
};
export type ShortcutAssessmentDialogEvent = "cancel" | "submit";
export type ShortcutAssessmentDialogData = ShortcutAssessmentFormValues;

const RISK_TYPE_LABEL = {
  current: "Current Cyber Risk Assessment",
  residual: "Residual Cyber Risk Assessment",
};

export const ShortcutAssessmentDialog: PimoReactComponent<
  ShortcutAssessmentDialogProps,
  ShortcutAssessmentDialogEvent,
  ShortcutAssessmentDialogData
> = ({ fireEvent, isLocked, scenario }) => {
  const { handleSubmit, register, reset, setValue, watch } =
    useForm<ShortcutAssessmentDialogData>({
      defaultValues: {
        currentFinancialImpactRatingOverride:
          scenario?.currentFinancialImpactRatingOverride,
        currentReputationalImpactRatingOverride:
          scenario?.currentReputationalImpactRatingOverride,
        currentProbabilityRatingOverride:
          scenario?.currentProbabilityRatingOverride,
        residualFinancialImpactRatingOverride:
          scenario?.residualFinancialImpactRatingOverride,
        residualProbabilityRatingOverride:
          scenario?.currentProbabilityRatingOverride,
        residualReputationalImpactRatingOverride:
          scenario?.currentReputationalImpactRatingOverride,
      },
    });
  const theme = useTheme();
  const disabled = Boolean(isLocked);

  useEffect(() => {
    if (scenario) {
      reset();
      setValue(
        "currentFinancialImpactRatingOverride",
        scenario.currentFinancialImpactRatingOverride
      );
      setValue(
        "currentReputationalImpactRatingOverride",
        scenario.currentReputationalImpactRatingOverride
      );
      setValue(
        "currentProbabilityRatingOverride",
        scenario.currentProbabilityRatingOverride
      );
      setValue(
        "residualFinancialImpactRatingOverride",
        scenario.residualFinancialImpactRatingOverride
      );
      setValue(
        "residualProbabilityRatingOverride",
        scenario.residualProbabilityRatingOverride
      );
      setValue(
        "residualReputationalImpactRatingOverride",
        scenario.residualReputationalImpactRatingOverride
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario]);

  if (!scenario) return null;

  return (
    <Dialog
      open
      maxWidth="lg"
      PaperProps={{ sx: { borderRadius: 5 } }}
      onClose={() => fireEvent?.("cancel")}
    >
      <form onSubmit={handleSubmit((data) => fireEvent?.("submit", data))}>
        <DialogTitle sx={{ fontWeight: 500, width: 1200 }}>
          Short Cut Assessment for {scenario?.name}
        </DialogTitle>
        <Divider />
        <Box
          sx={{
            background: "#F5F5F5",
            borderRadius: 5,
            m: 3,
            p: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontStyle: "italic" }}>
            Please set the ratings for the following criteria:
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 4,
            ml: 3,
            mr: 5,
            gap: 2,
          }}
        >
          {RISK_TYPE.map((riskType) => {
            const financialImpactRatingKey =
              `${riskType}FinancialImpactRatingOverride` as const;
            const reputationalImpactRatingKey =
              `${riskType}ReputationalImpactRatingOverride` as const;
            const probabilityRatingKey =
              `${riskType}ProbabilityRatingOverride` as const;

            return (
              <Box
                key={riskType}
                sx={{
                  flex: "0 0 50%",
                  flexDirection: "column",
                  display: "flex",
                  gap: 2,
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h5">
                    {RISK_TYPE_LABEL[riskType]}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <InputLabel>Financial Impact Rating</InputLabel>
                  <Select
                    defaultValue={scenario[financialImpactRatingKey]}
                    disabled={disabled}
                    inputProps={register(financialImpactRatingKey, {
                      valueAsNumber: true,
                    })}
                    onChange={(e) =>
                      setValue(
                        financialImpactRatingKey,
                        e.target.value as CommonRatingValue
                      )
                    }
                    label="Financial Impact Rating"
                    variant="outlined"
                    value={watch(financialImpactRatingKey) ?? ""}
                  >
                    {FINANCIAL_RATING.map((rating) => (
                      <MenuItem value={rating} key={rating}>
                        {RATING_LABEL.FinancialImpact[rating]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Reputational Impact Rating</InputLabel>
                  <Select
                    defaultValue={scenario[reputationalImpactRatingKey]}
                    disabled={disabled}
                    inputProps={register(reputationalImpactRatingKey, {
                      valueAsNumber: true,
                    })}
                    onChange={(e) =>
                      setValue(
                        reputationalImpactRatingKey,
                        e.target.value as CommonRatingValue
                      )
                    }
                    label="Reputational Impact Rating"
                    variant="outlined"
                    value={watch(reputationalImpactRatingKey) ?? ""}
                  >
                    {REPUTATIONAL_RATING.map((rating) => (
                      <MenuItem value={rating} key={rating}>
                        {RATING_LABEL.ReputationalImpact[rating]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Probability Impact Rating</InputLabel>
                  <Select
                    defaultValue={scenario[probabilityRatingKey]}
                    disabled={disabled}
                    inputProps={register(probabilityRatingKey, {
                      valueAsNumber: true,
                    })}
                    onChange={(e) =>
                      setValue(
                        probabilityRatingKey,
                        e.target.value as CommonRatingValue
                      )
                    }
                    label="Probability Impact Rating"
                    variant="outlined"
                    value={watch(probabilityRatingKey) ?? ""}
                  >
                    {PROBABILITY_RATING.map((rating) => (
                      <MenuItem value={rating} key={rating}>
                        {RATING_LABEL.ProbabilityRating[rating]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            );
          })}
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
            mt: 10,
          }}
        />
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            mt: 2,
            ml: 3,
            mr: 3,
            mb: 2,
            gap: 2,
          }}
        >
          <Button
            type="button"
            variant="text"
            onClick={() => fireEvent?.("cancel")}
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

/* eslint-disable react-refresh/only-export-components */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect } from "react";

type LockedSectionProps = { text: string };
type LockedSectionEvent = "close";

export const LOCKED_SECTION_DIALOG_TEXTS = {
  generalSettings: "Please finalize the general parameters before proceeding.",
  controlEnvironmentEffectiveness:
    "Please finalize the environmental effectiveness parameters before proceeding.",
  scenariosAndMitigations:
    "Please finalize the scenarios and mitigations before proceeding.",
  result: "Please finalize the expert judgement before proceeding.",
  all: "Please finalize the whole scenario assessment including the expert judgement before proceeding.",
} as const;

export const LockedSectionDialog: PimoReactComponent<
  LockedSectionProps,
  LockedSectionEvent
> = ({ fireEvent, text }) => {
  useEffect(() => {
    if (!text) {
      fireEvent?.("close");
    }
  }, [fireEvent, text]);

  return (
    <Dialog
      open
      PaperProps={{ sx: { borderRadius: 5 } }}
      onClose={() => fireEvent?.("close")}
    >
      <DialogTitle>Before proceeding</DialogTitle>
      <Divider />
      <Box
        sx={{
          m: 3,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>{text}</Typography>
      </Box>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          mt: 2,
          ml: 3,
          mr: 3,
          mb: 2,
          gap: 2,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          onClick={() => fireEvent?.("close")}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

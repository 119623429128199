import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  FINANCIAL_RATING,
  FinancialImpactType,
  FinancialRatingValue,
  OneInTwentyYearsProbabilityRating,
  OneInTwentyYearsProbabilityRatingOptions,
  REPUTATIONAL_RATING,
  ReputationalImpactType,
  ReputationalRatingValue,
} from "tracy-types";

import { ConfirmationDialogBox } from "./confirmation-dialog-box";

export type EditProbabilityDialogProps = {
  currentOEProbabilityRatingOptions?: OneInTwentyYearsProbabilityRatingOptions;
};
export type EditProbabiltyDialogEventName = "cancel" | "submit";
export type EditProbabilityDialogEventPayload =
  OneInTwentyYearsProbabilityRating;

const PROBABILITY_LABELS_MAP = {
  crq: "CRQ Value",
  representative: "Representative Value",
  custom: "Own Value",
} as const satisfies Record<
  FinancialImpactType | ReputationalImpactType,
  string
>;

export const EditProbabilityDialog: PimoReactComponent<
  EditProbabilityDialogProps,
  EditProbabiltyDialogEventName,
  EditProbabilityDialogEventPayload
> = ({ currentOEProbabilityRatingOptions, fireEvent }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<EditProbabilityDialogEventPayload>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();
  const {
    financialImpactType,
    financialImpactRating,
    reputationalImpactType,
    reputationalImpactRating,
  } = watch();

  const onCancel = () => {
    setIsDialogOpen(true);
  };
  const onSubmit: SubmitHandler<EditProbabilityDialogEventPayload> = (data) => {
    fireEvent?.("submit", data);
  };

  useEffect(() => {
    if (currentOEProbabilityRatingOptions == null) {
      return;
    }

    const { comment, financialImpact, reputationalImpact } =
      currentOEProbabilityRatingOptions;
    const selectedFinancialImpact = financialImpact.find(
      ({ selected }) => selected
    );
    const selectedReputationalImpact = reputationalImpact.find(
      ({ selected }) => selected
    );

    reset({
      comment,
      financialImpactType: selectedFinancialImpact?.type,
      financialImpactRating: selectedFinancialImpact?.value,
      reputationalImpactType: selectedReputationalImpact?.type,
      reputationalImpactRating: selectedReputationalImpact?.value,
    });
  }, [currentOEProbabilityRatingOptions, reset]);

  if (currentOEProbabilityRatingOptions == null) {
    return null;
  }

  const { financialImpact, reputationalImpact } =
    currentOEProbabilityRatingOptions;

  return (
    <>
      <ConfirmationDialogBox
        onClose={() => {
          setIsDialogOpen(false);
          fireEvent?.("cancel");
        }}
        onContinue={() => {
          setIsDialogOpen(false);
        }}
        open={isDialogOpen}
      />
      <Dialog
        open
        maxWidth="lg"
        PaperProps={{ sx: { borderRadius: 5 } }}
        onClose={() => setIsDialogOpen(true)}
      >
        <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
          <DialogTitle sx={{ fontWeight: 500, width: 1200 }}>
            1 in 20 years loss probability
          </DialogTitle>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              mt: 4,
              ml: 3,
              mr: 5,
              gap: 2,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h5">Financial Impact Rating</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>Financial Impact Rating Type</InputLabel>
                  <Select
                    {...register("financialImpactType")}
                    label="Financial Impact Rating Type"
                    onChange={(event) => {
                      if (!event.target.value) {
                        return;
                      }

                      const financialImpactValue = financialImpact.find(
                        ({ type }) => type === event.target.value
                      );

                      setValue(
                        "financialImpactType",
                        financialImpactValue?.type
                      );
                      setValue(
                        "financialImpactRating",
                        financialImpactValue?.value
                      );
                    }}
                    value={financialImpactType ?? ""}
                  >
                    {financialImpact.map(({ type }) => (
                      <MenuItem key={type} value={type}>
                        {PROBABILITY_LABELS_MAP[type]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Financial Impact Rating Value</InputLabel>
                  <Select
                    {...register("financialImpactRating")}
                    disabled={
                      financialImpactType === "crq" ||
                      financialImpactType === "representative"
                    }
                    label="Financial Impact Rating Value"
                    onChange={(event) => {
                      if (
                        event.target.value === "" ||
                        event.target.value == null
                      ) {
                        return;
                      }

                      setValue(
                        "financialImpactRating",
                        +event.target.value as FinancialRatingValue
                      );
                    }}
                    value={financialImpactRating ?? ""}
                  >
                    {FINANCIAL_RATING.map((rating) => (
                      <MenuItem key={rating} value={rating}>
                        {rating}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h5">Reputational Impact Rating</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>Reputational Impact Rating Type</InputLabel>
                  <Select
                    {...register("reputationalImpactType")}
                    label="Reputational Impact Rating Type"
                    onChange={(event) => {
                      if (!event.target.value) {
                        return;
                      }

                      const reputationalImpactValue = reputationalImpact.find(
                        ({ type }) => type === event.target.value
                      );

                      setValue(
                        "reputationalImpactType",
                        reputationalImpactValue?.type
                      );
                      setValue(
                        "reputationalImpactRating",
                        reputationalImpactValue?.value
                      );
                    }}
                    value={reputationalImpactType ?? ""}
                  >
                    {reputationalImpact.map(({ type }) => (
                      <MenuItem key={type} value={type}>
                        {PROBABILITY_LABELS_MAP[type]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Reputational Impact Rating Value</InputLabel>
                  <Select
                    {...register("reputationalImpactRating")}
                    disabled={reputationalImpactType === "representative"}
                    label="Reputational Impact Rating Value"
                    onChange={(event) => {
                      if (
                        event.target.value === "" ||
                        event.target.value == null
                      ) {
                        return;
                      }

                      setValue(
                        "reputationalImpactRating",
                        +event.target.value as ReputationalRatingValue
                      );
                    }}
                    value={reputationalImpactRating ?? ""}
                  >
                    {REPUTATIONAL_RATING.map((rating) => (
                      <MenuItem key={rating} value={rating}>
                        {rating}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 4, ml: 3, mr: 5 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5">Comment on Rating</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                {...register("comment", { required: true })}
                error={!!errors["comment"]}
                helperText={errors["comment"] ? "This field is required." : ""}
                fullWidth
                multiline
                placeholder="Comment on Rating"
                rows={4}
                variant="outlined"
              />
            </FormControl>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "1px",
              borderWidth: 0,
              mt: 10,
            }}
          />
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              mx: 2,
              my: 3,
              gap: 2,
            }}
          >
            <Button type="button" variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

import { PlayArrow } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardProps,
  Divider,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Markdown } from "@pimo/pimo-components";

export interface TextCardProps<EventName extends string> {
  /** card title */
  cardOneTitle: string;
  /** (optional) buttons */
  cardOneButtons?: { text: string; event: EventName; isLocked?: boolean }[];
  /** text to be shown in card */
  textOneContent: string;
  /** card title */
  cardTwoTitle: string;
  /** (optional) buttons */
  cardTwoButtons?: { text: string; event: EventName; isLocked?: boolean }[];
  /** text to be shown in card */
  textTwoContent: string;
  /** optional boolean to show the icon */
  showIcon?: boolean;
  fireEvent?: (eventName: EventName) => void;
  cardProps?: CardProps;
  maxHeight?: number;
  titleProps?: TypographyProps;
}

export function TextCard<EventName extends string>({
  cardOneTitle,
  cardOneButtons,
  textOneContent,
  cardTwoTitle,
  cardTwoButtons,
  textTwoContent,
  fireEvent,
  showIcon = false,
  cardProps = {},
  maxHeight = 260,
  titleProps = {},
}: TextCardProps<EventName>): ReturnType<
  PimoReactComponent<TextCardProps<EventName>, EventName>
> {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        borderRadius: "12px",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          height: "100%",
          width: "50%",
          borderRadius: "12px",
          "@media print": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
          },
          ...cardProps.sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "space-between",
            padding: 2,
            width: "100%",
          }}
        >
          <Typography
            sx={{ fontWeight: 500, fontSize: "20px", ...titleProps.sx }}
          >
            {cardOneTitle}
          </Typography>
          {showIcon && <PlayArrow sx={{ color: theme.palette.primary.main }} />}
          {cardOneButtons?.length ? (
            <Box>
              {cardOneButtons.map(({ text, event, isLocked }) => (
                <Button
                  key={text}
                  onClick={() => fireEvent?.(event)}
                  variant="outlined"
                  disabled={isLocked}
                  sx={{
                    height: 30,
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.primary.main,
                    ml: 1,
                    borderRadius: 2,
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {text} {isLocked && "🔒"}
                </Button>
              ))}
            </Box>
          ) : null}
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
            width: "100%",
          }}
        />
        <Box
          sx={{
            px: 2,
            overflow: "auto",
            scrollBehavior: "auto",
            minHeight: 130,
            maxHeight,
          }}
        >
          <Markdown>{textOneContent}</Markdown>
        </Box>
      </Card>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          height: "100%",
          borderRadius: "12px",
          width: "50%",
          "@media print": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
          },
          ...cardProps.sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "space-between",
            padding: 2,
            width: "100%",
          }}
        >
          <Typography
            sx={{ fontWeight: 500, fontSize: "20px", ...titleProps.sx }}
          >
            {cardTwoTitle}
          </Typography>
          {showIcon && <PlayArrow sx={{ color: theme.palette.primary.main }} />}
          {cardTwoButtons?.length ? (
            <Box>
              {cardTwoButtons.map(({ text, event, isLocked }) => (
                <Button
                  key={text}
                  onClick={() => fireEvent?.(event)}
                  variant="outlined"
                  disabled={isLocked}
                  sx={{
                    height: 30,
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.primary.main,
                    ml: 1,
                    borderRadius: 2,
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {text} {isLocked && "🔒"}
                </Button>
              ))}
            </Box>
          ) : null}
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
            width: "100%",
          }}
        />
        <Box
          sx={{
            px: 2,
            overflow: "auto",
            scrollBehavior: "auto",
            minHeight: 130,
            maxHeight,
          }}
        >
          <Markdown>{textTwoContent}</Markdown>
        </Box>
      </Card>
    </Box>
  );
}

export default TextCard;

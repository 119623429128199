import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  ClickAwayListener,
  Popper,
  Typography,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Markdown } from "@pimo/pimo-components";
import React from "react";

type InfoPopupProps = {
  shortText?: string;
  longText?: string;
  images?: string[];
};

export const InfoPopup: PimoReactComponent<InfoPopupProps> = ({
  shortText,
  longText,
  images,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const clickAwayHandler = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        background: "#F5F5F5",
        borderRadius: 5,
        m: 1,
        px: 2,
        display: "flex",
        justifyContent: "space-between",
        gap: 20,
      }}
    >
      <Typography component="span" sx={{ fontStyle: "italic" }}>
        <Markdown>{shortText ?? ""}</Markdown>
      </Typography>
      {longText && (
        <Button onClick={handleClick}>
          <InfoOutlined sx={{ color: "#616161" }} />
        </Button>
      )}
      {open && (
        <ClickAwayListener
          onClickAway={clickAwayHandler}
          mouseEvent={"onMouseUp"}
        >
          <Popper
            placement="bottom-start"
            disablePortal={true}
            open={open}
            anchorEl={anchorEl}
            sx={{
              border: "1px solid",
              borderColor: "gray",
              borderRadius: "12px",
              backgroundColor: "#F5F5F5",
              boxShadow: "1px 1px 5px",
              p: 1,
              zIndex: 10000,
            }}
          >
            <Typography
              component="span"
              sx={{ px: 1, maxWidth: 900, fontStyle: "italic" }}
            >
              <Markdown>{longText ?? ""}</Markdown>
            </Typography>
            <Box
              sx={{ maxWidth: 900, display: "flex", flexDirection: "column" }}
            >
              {images &&
                images?.map((image) => (
                  <Box
                    sx={{ maxWidth: images.length > 1 ? 400 : 600 }}
                    component={"img"}
                    src={image}
                  />
                ))}
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

import { Box } from "@mui/material";
import { getColorSchemeForRating } from "tracy-utils";

import { RATING_LABEL } from "../../../config/rating-label";
import { Arrow } from "../../arrow";
import {
  IMPACT_BOX_HEIGHT,
  IMPACT_BOX_WIDTH,
  ImpactBox,
} from "../../impact-box";
import { TopRiskBox } from "../../top-risk-box";
import { ScenariosForm } from "../scenarios-form";
import type { ScenariosFormComponent } from "../types";

export const TopRiskYesNo: ScenariosFormComponent = (props) => {
  const { oeRatings, oeReportPage, scenario, step } = props;

  if (!oeRatings || !oeReportPage || !scenario || !step) {
    return <></>;
  }

  return (
    <ScenariosForm {...props}>
      <TopRiskYesNoForm {...props} />
    </ScenariosForm>
  );
};

const TopRiskYesNoForm: ScenariosFormComponent = ({ oeRatings, scenario }) => {
  const arrowLength = 40;
  const impactBoxHorizontalOffset = 70;
  const impactBoxVerticalOffset = 60;

  const scenarioRatings = oeRatings?.ratings?.find(
    ({ id }) => id === scenario.id
  );
  const currentRatings = scenarioRatings?.current;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "740px",
        position: "relative",
        marginTop: "80px",
      }}
    >
      <ImpactBox
        name="Probability Rating (Step 1)"
        impact={currentRatings?.probabilityRating}
        impactName={
          currentRatings?.probabilityRating != null
            ? RATING_LABEL.ProbabilityRating[currentRatings.probabilityRating]
            : undefined
        }
        sx={{
          position: "absolute",
          left: `calc(50% - ${IMPACT_BOX_WIDTH}px - ${impactBoxHorizontalOffset}px)`,
          top: 0,
        }}
        background={
          getColorSchemeForRating(currentRatings?.probabilityRating).colorScheme
            .chipColor
        }
        color={
          getColorSchemeForRating(currentRatings?.probabilityRating).colorScheme
            .textColor
        }
      />
      <ImpactBox
        name="Impact Severity (Step 4)"
        impact={currentRatings?.impactSeverityRating}
        impactName={
          currentRatings?.impactSeverityRating != null
            ? RATING_LABEL.ReputationalImpact[
                currentRatings?.impactSeverityRating
              ]
            : undefined
        }
        sx={{
          position: "absolute",
          right: `calc(50% - ${IMPACT_BOX_WIDTH}px - ${impactBoxHorizontalOffset}px)`,
          top: 0,
        }}
        background={
          getColorSchemeForRating(currentRatings?.impactSeverityRating)
            .colorScheme.chipColor
        }
        color={
          getColorSchemeForRating(currentRatings?.impactSeverityRating)
            .colorScheme.textColor
        }
      />
      <ImpactBox
        name="Overall Rating"
        impact={currentRatings?.overallRating}
        impactName={
          currentRatings?.overallRating != null
            ? RATING_LABEL.FinancialImpact[currentRatings?.overallRating]
            : undefined
        }
        sx={{
          position: "absolute",
          left: `calc(50% - ${IMPACT_BOX_WIDTH}px - ${impactBoxHorizontalOffset}px)`,
          top: `${IMPACT_BOX_HEIGHT + impactBoxVerticalOffset}px`,
        }}
        background={
          getColorSchemeForRating(currentRatings?.overallRating).colorScheme
            .chipColor
        }
        color={
          getColorSchemeForRating(currentRatings?.overallRating).colorScheme
            .textColor
        }
      />
      <TopRiskBox
        topRisk={currentRatings?.isTopRisk}
        sx={{
          position: "absolute",
          right: `calc(50% - ${IMPACT_BOX_WIDTH}px - ${impactBoxHorizontalOffset}px)`,
          top: `${IMPACT_BOX_HEIGHT + impactBoxVerticalOffset}px`,
        }}
      />
      <Arrow
        direction="top-bottom"
        length={arrowLength}
        style={{
          position: "absolute",
          top: `${
            IMPACT_BOX_HEIGHT + (impactBoxVerticalOffset - arrowLength) / 2
          }px`,
          left: `calc(50% - ${
            IMPACT_BOX_WIDTH / 2 + impactBoxVerticalOffset + arrowLength / 2
          }px)`,
        }}
      />
      <Arrow
        direction="top-right-bottom-left"
        length={80}
        style={{
          position: "absolute",
          top: `${IMPACT_BOX_HEIGHT + (impactBoxVerticalOffset - 80) / 2}px`,
          left: `calc(50% - ${impactBoxHorizontalOffset / 2}px - 10px)`,
        }}
      />
      <Arrow
        direction="left-right"
        length={80}
        style={{
          position: "absolute",
          top: `${
            IMPACT_BOX_HEIGHT * 1.5 + impactBoxVerticalOffset - arrowLength / 2
          }px`,
          left: `calc(50% - ${impactBoxHorizontalOffset / 2}px)`,
        }}
      />
    </Box>
  );
};

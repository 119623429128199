import type {
  RiskType,
  ScenariosFormValuesRequest,
  StepCount,
} from "tracy-types";
import { slugify } from "tracy-utils";

import { SCENARIO_STEPS_CATEGORY_NAMES, type ScenarioFlowStep } from "../types";
import { ScenarioParameters } from "./0-scenario-parameters";
import { ProbabilityRatingOfCurrentCyberRisk } from "./1-probability-rating-of-current-cyber-risk";
import { FinancialImpactOfCurrentCyberRisk } from "./2-financial-impact-of-current-cyber-risk";
import { ReputationImpactOfCurrentCyberRisk } from "./3-reputational-impact-of-current-cyber-risk";
import { ImpactSeverityOfCurrentCyberRisk } from "./4-impact-severity-of-current-cyber-risk";
import { TopRiskYesNo } from "./5-top-risk-yes-no";
import { MitigationActions } from "./6-mitigation-actions";
import { ProbabilityRatingOfResidualCyberRisk } from "./7-probability-rating-of-residual-cyber-risk";
import { FinancialImpactOfResidualCyberRisk } from "./8-financial-impact-of-residual-cyber-risk";
import { ReputationImpactOfResidualCyberRisk } from "./9-reputational-impact-of-residual-cyber-risk";
import { CompareCurrentVsResidualCyberRisk } from "./10-compare-current-vs-residual-cyber-risk";

function createScenarioFlowStep(
  number: StepCount,
  {
    category,
    component,
    shortDescription,
    longDescription,
    images,
    title,
    step,
    riskType,
  }: {
    category: ScenarioFlowStep["category"];
    component: ScenarioFlowStep["component"];
    shortDescription?: ScenarioFlowStep["shortDescription"];
    longDescription?: ScenarioFlowStep["longDescription"];
    images?: ScenarioFlowStep["images"];
    title: ScenarioFlowStep["title"];
    step?: ScenariosFormValuesRequest["step"];
    riskType?: RiskType;
  }
): ScenarioFlowStep {
  return {
    category,
    component,
    images,
    shortDescription,
    longDescription,
    name: `Step ${number}`,
    number,
    slug: `/${slugify(`Step ${number}`)}`,
    title,
    viewname: slugify(`Step ${number}`),
    step,
    riskType,
  };
}

export const SCENARIO_FLOW_STEPS = [
  createScenarioFlowStep(0, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[0],
    component: ScenarioParameters,
    shortDescription:
      "For predefined cyber risk scenarios, adjust or extend the scenario parameters to meet individual OE needs. For OE-specific scenarios, provide all required information. Please confirm below when you have finished.",
    longDescription: `**For predefined cyber risk scenarios:**\n\nScenario description and relevant scenario parameter are designed to give guidance and provide a common understanding of each scenario whilst they can individually be adjusted / extended to individual OE needs. Please use the comment fields to describe your adjustments.\n\n**For additional OE-specific scenarios:**\n\nPlease provide the "Scenario Name", the "Short Description" and the "Scenario Story", as well as any parameters for the scenario.`,
    title: "Scenario Parameters",
    step: "scenarioParameters",
  }),
  createScenarioFlowStep(1, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[1],
    component: ProbabilityRatingOfCurrentCyberRisk,
    shortDescription: "Assess the Probability Ratings of Current Cyber Risk",
    longDescription:
      "Please select the probability rating of the given cyber risk scenario. Also give a short description why you have chosen this probability rating. For filling out the table, please consider the below criteria from Allianz Standard for Top Risk Assessment (ASTRA):",
    title: "Probability Rating of Current Cyber Risk",
    step: "probabilityRating",
    riskType: "current",
    images: ["label-table.png"],
  }),
  createScenarioFlowStep(2, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[1],
    component: FinancialImpactOfCurrentCyberRisk,
    shortDescription:
      "Assess the Financial Impact of Current Cyber Risk using Scenario Analysis",
    // longDescription: `First, decide if you want to go with "Version 1: Short Assessment of Financial Impact" or "Version 2: Extended Assessment of Financial Impact".\n\nIf "Version 1: Short Assessment of Financial Impact" is selected, it is mandatory to enter the financial loss in € and a comment how the loss was quantified. Guiding questions for the financial impact are provided and might support for the quantification.\n\n**Please note that the financial impact should be assessed without any reductions or risk transfer considerations by cyber insurance.**\n\nIf "Version 2: Extended Assessment of Financial Impact" is selected, it is mandatory to provide the financial loss in € for the given "Impact Categories". Guiding questions for the financial impact categories are provided and might support for the quantification. If an impact category is not affected, please enter "0". If an additional impact category is required, enter its loss in the impact category "Other financial impact" and provide also a comment how it is named and quantified. The financial impact is calculated as the sum of financial losses of the impact categories.\n\n**Please note that the financial impact should be assessed without any reductions or risk transfer considerations by cyber insurance.**\n\nThe Operating Profit is taken from the section "1. General Parameters and Introduction" and **the Financial Impact Rating will be calculated automatically based on the selected Rating Scheme (ASTRA or AFIRM)** within the section “1. General Parameters and Introduction”. It is determined in the following way:`,
    // images: ["formula.png", "ratings-table.png"],
    longDescription: `First, decide if you want to go with "Version 1: Short Assessment of Financial Impact" or "Version 2: Extended Assessment of Financial Impact".\n\nIf "Version 1: Short Assessment of Financial Impact" is selected, it is mandatory to enter the financial loss in € and a comment how the loss was quantified. Guiding questions for the financial impact are provided and might support for the quantification.\n\n**Please note that the financial impact should be assessed without any reductions or risk transfer considerations by cyber insurance.**\n\nIf "Version 2: Extended Assessment of Financial Impact" is selected, it is mandatory to provide the financial loss in € for the given "Impact Categories". Guiding questions for the financial impact categories are provided and might support for the quantification. If an impact category is not affected, please enter "0". If an additional impact category is required, enter its loss in the impact category "Other financial impact" and provide also a comment how it is named and quantified. The financial impact is calculated as the sum of financial losses of the impact categories.\n\n**Please note that the financial impact should be assessed without any reductions or risk transfer considerations by cyber insurance.**\n\nThe Operating Profit is taken from the section "1. General Parameters and Introduction" and **the Financial Impact Rating will be calculated automatically based on ASTRA.** It is determined in the following way:`,
    images: ["formula.png", "ratings-table-astra.png"],
    title: "Financial Impact of Current Cyber Risk",
    step: "financialImpact",
    riskType: "current",
  }),
  createScenarioFlowStep(3, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[1],
    component: ReputationImpactOfCurrentCyberRisk,
    shortDescription:
      "Assess the Reputational Impact of Current Cyber Risk Using Scenario Analysis",
    longDescription: `Please assess the reputational impact for all stakeholder groups by using the dropdown (if a party is not affected, please select "not affected"). Also provide a short explanation why you selected the respective rating in the "Comment on Rating" field. Please consider the relevant criteria from the Allianz Standard for Top Risk Assessment (ASTRA), which are displayed in the "Impact on Stakeholder" field once a rating has been selected.`,
    title: "Reputational Impact of Current Cyber Risk",
    step: "reputationalImpact",
    riskType: "current",
  }),
  createScenarioFlowStep(4, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[1],
    component: ImpactSeverityOfCurrentCyberRisk,
    shortDescription:
      "Assess Impact Severity of Current Cyber Risk (this step is performed automatically)",
    longDescription: `The results are taken from **Step 2** and **Step 3**.\n\nThe "**Impact Severity**" is calculated as follows:\n\n **Impact severity = max{Financial Impact Rating, Reputational Impact Rating}**`,
    title: "Impact Severity of Current Cyber Risk",
    step: "impactSeverity",
  }),
  createScenarioFlowStep(5, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[1],
    component: TopRiskYesNo,
    shortDescription: "Top Risk Yes/No? (this step is performed automatically)",
    longDescription: `To determine if cyber risk is a top risk, the **overall rating** is used.\n\nThe overall rating is based on the impact severity from **Step 4** and the probability rating from **Step 1**. \n\n Cyber risk is considered as **top risk**, if the overall rating is **equal to 4 or 5**.`,
    title: "Top Risk Yes/No",
    images: ["matrix.png"],
  }),
  createScenarioFlowStep(6, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[2],
    component: MitigationActions,
    shortDescription:
      "Provide the **planned** mitigation actions and confirm below when you are done.",
    longDescription: `Provide the **planned** mitigation actions by filling out the following fields:\n\n- Project title\n- Estimated completion date\n- Owner\n- Source (Source of the mitigation action, e.g. kind of group or OE specific initiative)\n- Project scope\n\nPlease also select the cyber risk scenarios which are addressed by the mitigation action. Note that multiple scenarios can be selected.`,
    title: "Mitigation Actions",
    step: "mitigationActions",
  }),
  createScenarioFlowStep(7, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[3],
    component: ProbabilityRatingOfResidualCyberRisk,
    shortDescription:
      "Assess the Probability Ratings of Residual Cyber Risk based on mitigation actions",
    longDescription: `Please select the probability rating of the given cyber risk scenario **BASED ON THE DEFINED PLANNED MITIGATION ACTIONS**. Also give a short description why you have chosen this probability rating. For filling out the table, please consider the below criteria from Allianz Standard for Top Risk Assessment (ASTRA):`,
    title: "Probability Rating of Residual Cyber Risk",
    images: ["label-table.png"],
    step: "probabilityRating",
    riskType: "residual",
  }),
  createScenarioFlowStep(8, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[3],
    component: FinancialImpactOfResidualCyberRisk,
    shortDescription:
      "Assess the Financial Impact of Residual Cyber Risk using Scenario Analysis based on planned mitigation actions",
    // longDescription: `First, decide if you want to go with "Version 1: Short Assessment of Financial Impact" or "Version 2: Extended Assessment of Financial Impact".\n\nIf "Version 1: Short Assessment of Financial Impact" is selected, enter the financial loss in € **BASED ON THE DEFINED PLANNED MITIGATION ACTIONS** and a short description how the loss was quantified.\n\nIf “Version 2: Extended Assessment of Financial Impact” is selected, enter the financial loss in € **BASED ON THE DEFINED PLANNED MITIGATION ACTIONS** for the given “Impact Categories”. Guiding questions for the financial impact categories are provided and might support for the quantification. If an impact category is not affected, please enter “0”. If an additional impact category is required, enter its loss in the impact category “Other financial impact” and provide also a comment how it is named and quantified. The financial impact is calculated as the sum of financial losses of the impact categories.\n\nThe Operating Profit is taken from the section “1. General Parameters and Introduction” and **the Financial Impact Rating will be calculated automatically based on the selected Rating Scheme (ASTRA or AFIRM)** within the section “1. General Parameters and Introduction”. It is determined in the following way:`,
    // images: ["formula.png", "ratings-table.png"],
    longDescription: `First, decide if you want to go with "Version 1: Short Assessment of Financial Impact" or "Version 2: Extended Assessment of Financial Impact".\n\nIf "Version 1: Short Assessment of Financial Impact" is selected, enter the financial loss in € **BASED ON THE DEFINED PLANNED MITIGATION ACTIONS** and a short description how the loss was quantified.\n\nIf “Version 2: Extended Assessment of Financial Impact” is selected, enter the financial loss in € **BASED ON THE DEFINED PLANNED MITIGATION ACTIONS** for the given “Impact Categories”. Guiding questions for the financial impact categories are provided and might support for the quantification. If an impact category is not affected, please enter “0”. If an additional impact category is required, enter its loss in the impact category “Other financial impact” and provide also a comment how it is named and quantified. The financial impact is calculated as the sum of financial losses of the impact categories.\n\nThe Operating Profit is taken from the section "1. General Parameters and Introduction" and **the Financial Impact Rating will be calculated automatically based on ASTRA**. It is determined in the following way:`,
    images: ["formula.png", "ratings-table-astra.png"],
    title: "Financial Impact of Residual Cyber Risk",
    step: "financialImpact",
    riskType: "residual",
  }),
  createScenarioFlowStep(9, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[3],
    component: ReputationImpactOfResidualCyberRisk,
    shortDescription:
      "Assess the Reputational Impact of Residual Cyber Risk using Scenario Analysis based on planned mitigation actions",
    longDescription: `Please assess the reputational impact for all stakeholder groups **BASED ON THE DEFINED PLANNED MITIGATION ACTIONS** by using the dropdown (if a party is not affected, please select "not affected"). Also provide a short explanation why you selected the respective rating in the "Comment on Rating" field. Please consider the relevant criteria from the Allianz Standard for Top Risk Assessment (ASTRA), which are displayed in the "Impact on Stakeholder" field once a rating has been selected.`,
    title: "Reputational Impact of Residual Cyber Risk",
    step: "reputationalImpact",
    riskType: "residual",
  }),
  createScenarioFlowStep(10, {
    category: SCENARIO_STEPS_CATEGORY_NAMES[3],
    component: CompareCurrentVsResidualCyberRisk,
    shortDescription:
      "Compare Residual and Current Cyber Risk (this step is performed automatically)",
    longDescription: `To determine if cyber risk is a top risk, the **overall rating** is used.\n\nThe overall rating is based on the impact severity (i.e. maximum of financial and reputational impact rating) and the probability rating.\n\nCyber risk is considered as **top risk**, if the overall rating is equal to **4** or **5**.`,
    title: "Compare Current vs. Residual Cyber Risk",
    images: ["matrix.png"],
  }),
] as const satisfies readonly ScenarioFlowStep[];

import { Close, FilterAlt } from "@mui/icons-material";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { DropdownFilter } from "@pimo/pimo-components";
import { FC } from "react";
import { FilterData } from "tracy-types";

export interface FilterDialogPropsDashboard {
  onClick?: () => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;
  filterData: FilterData;
  filterValues?: {
    clusters?: string[];
  };
}

export const FilterDialogDashboard: FC<FilterDialogPropsDashboard> = ({
  onClick,
  handleChange,
  filterData,
  filterValues,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        gap: 0,
        width: 300,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            pr: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              pr: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <FilterAlt
                sx={{ color: theme.palette.primary.main, height: "35px" }}
              />
              <Typography sx={{ fontWeight: 500 }}>Filter</Typography>
            </Box>
            <Close onClick={onClick} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, py: 2 }}>
            <DropdownFilter
              options={filterValues?.clusters ?? []}
              value={filterData.clusterFilter ?? []}
              label="OE Cluster"
              handleChange={(e) => handleChange(e, "clusterFilter")}
              triggerIdentifier={"clusterFilter"}
            />
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
    </Box>
  );
};

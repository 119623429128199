import { Add, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import type { FE_Scenario, ScenarioProgress } from "tracy-types";

import { TracyTooltip } from "../tracy-tooltip";
import {
  getStatus,
  ScenariosStatusIndicator,
} from "./scenarios-status-indicator";
import {
  SCENARIO_STEPS_CATEGORY_NAMES,
  type ScenarioFlowCategory,
} from "./types";

type ScenarioWithProgress = FE_Scenario & { progress: ScenarioProgress };

export type ScenariosSidebarProps = {
  currentScenario?: FE_Scenario;
  currentScenarioCategory?: ScenarioFlowCategory;
  disabled?: boolean;
  scenarios: ScenarioWithProgress[];
};
export type ScenarioSidebarAccordionProps = {
  active: boolean;
  activeCategory?: ScenarioFlowCategory;
  currentScenario?: FE_Scenario;
  scenario: ScenarioWithProgress;
  onChange: () => void;
};
export type ScenariosSidebarEvent = "change" | "add-scenario";
export type ScenariosSidebarEventPayload = {
  scenario: ScenarioWithProgress;
  category: ScenarioFlowCategory;
};

const ScenarioSidebarAccordion: PimoReactComponent<
  ScenarioSidebarAccordionProps,
  ScenariosSidebarEvent,
  ScenariosSidebarEventPayload
> = ({
  active,
  activeCategory,
  currentScenario,
  scenario,
  onChange,
  fireEvent,
}) => {
  const theme = useTheme();

  return (
    <Accordion
      expanded={active}
      onChange={onChange}
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        color: theme.palette.primary.main,
        pb: 2,
        "&:before": { display: "none " },
      }}
    >
      <TracyTooltip title={scenario.name}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            border: "1px solid",
            borderColor: active ? theme.palette.primary.main : "transparent",
            borderRadius: 5,
            "& .MuiAccordionSummary-content": {
              maxWidth: "100%",
              overflow: "hidden",
            },
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {scenario.name}
            </Typography>
            <ScenariosStatusIndicator
              status={getStatus({
                progress: scenario.progress,
              })}
            />
          </Box>
        </AccordionSummary>
      </TracyTooltip>
      <AccordionDetails sx={{ backgroundColor: "transparent" }}>
        <MenuList>
          {SCENARIO_STEPS_CATEGORY_NAMES.map((category) => {
            return (
              <TracyTooltip key={category} title={category}>
                <MenuItem
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  onClick={() => fireEvent?.("change", { scenario, category })}
                >
                  <ListItemText
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight:
                          active &&
                          category === activeCategory &&
                          scenario.id === currentScenario?.id
                            ? "bold"
                            : "unset",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {category}
                    </Typography>
                  </ListItemText>
                  <ScenariosStatusIndicator
                    status={getStatus({
                      progress: scenario.progress,
                      category,
                    })}
                  />
                </MenuItem>
              </TracyTooltip>
            );
          })}
        </MenuList>
      </AccordionDetails>
    </Accordion>
  );
};

export const ScenariosSidebar: PimoReactComponent<
  ScenariosSidebarProps,
  ScenariosSidebarEvent,
  ScenariosSidebarEventPayload
> = ({
  currentScenario,
  currentScenarioCategory,
  disabled,
  scenarios,
  fireEvent,
}) => {
  const { scenarioId } = useParams<"scenarioId">();
  const theme = useTheme();
  const [active, setActive] = useState(
    scenarioId ? +scenarioId : currentScenario?.id
  );

  useEffect(() => {
    if (scenarioId) {
      setActive(+scenarioId);
    }
  }, [scenarioId]);

  useEffect(() => {
    if (active === undefined && currentScenario?.id) {
      setActive(currentScenario?.id);
    }
  }, [active, currentScenario]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.light,
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: 2,
        height: "100%",
        width: "100%",
        p: 2,
      }}
    >
      <Button
        disabled={disabled}
        onClick={() => fireEvent?.("add-scenario")}
        startIcon={<Add />}
        sx={{ borderRadius: "5px" }}
        variant="outlined"
      >
        Add OE-Specific Scenario
      </Button>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        {scenarios.map((scenario) => {
          return (
            <ScenarioSidebarAccordion
              active={active === scenario.id}
              activeCategory={currentScenarioCategory}
              currentScenario={currentScenario}
              onChange={() => setActive(scenario.id)}
              key={scenario.id}
              scenario={scenario}
              fireEvent={fireEvent}
            />
          );
        })}
      </Box>
    </Box>
  );
};

import { Chip } from "@mui/material";
import React from "react";

export const RatingChip: React.FC<{
  label: string;
  background?: string;
  border?: string;
  color?: string;
}> = ({
  label,
  background = "#FAB600",
  border = "none",
  color = "#ffffff",
}) => (
  <Chip
    sx={{
      background,
      border: `${border} 1px solid`,
      color,
      height: 28,
      ml: 2,
      pl: 1,
      pr: 1,
      fontWeight: "bold",
    }}
    label={label}
  />
);

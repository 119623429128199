import {
  Add,
  Delete,
  Edit,
  ExpandMore,
  Remove,
  Save,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { type MouseEventHandler, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import type { FE_Mitigation } from "tracy-types";
import { formatDate } from "tracy-utils";

import { getErrorMessage } from "../../../helpers/validation/get-error-message";
import { hasError } from "../../../helpers/validation/has-error";
import { useFadeIn } from "../../../hooks/use-fade-in";
import { useScenariosForm } from "../../../hooks/use-scenarios-form";
import { TracyTooltip } from "../../tracy-tooltip";
import { ScenariosErrorsDialog } from "../scenarios-errors-dialog";
import { ScenariosFormHeader } from "../scenarios-form-header";
import type { ScenariosFormComponent } from "../types";

const DISABLED_INPUT_STYLES: SxProps = {
  backgroundColor: grey[100],
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: grey[700],
  },
};

export const MitigationActions: ScenariosFormComponent = (props) => {
  const { oeRatings, oeReportPage, scenario, step } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const action = searchParams.get("action");
  const id = searchParams.get("mitigation");

  const onAddClick = () => setSearchParams({ action: "add" });
  const onCancel = () => setSearchParams({});
  const onCreateClick = () => setSearchParams({ action: "create" });

  if (!oeRatings || !oeReportPage || !scenario || !step) {
    return <></>;
  }

  if (action === "add") {
    return (
      <MitgationActionsWrapper {...props}>
        <MitigationActionsAddForm {...props} onCancel={onCancel} />
      </MitgationActionsWrapper>
    );
  }

  if (action === "create") {
    return (
      <MitgationActionsWrapper {...props}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography fontWeight="bold">New Mitigation</Typography>
          <MitigationActionsForm
            {...props}
            disabled={searchParams.get("action") !== "create"}
            onCancel={onCancel}
            type="create"
          />
        </Box>
      </MitgationActionsWrapper>
    );
  }

  if (action === "delete" && id) {
    return (
      <MitgationActionsWrapper {...props}>
        <MitigationActionsDeleteAndRemoveForm
          {...props}
          id={+id}
          onCancel={onCancel}
          type="delete"
        />
      </MitgationActionsWrapper>
    );
  }

  if (action === "remove" && id) {
    return (
      <MitgationActionsWrapper {...props}>
        <MitigationActionsDeleteAndRemoveForm
          {...props}
          id={+id}
          onCancel={onCancel}
          type="remove"
        />
      </MitgationActionsWrapper>
    );
  }

  if (!scenario.mitigations?.length) {
    return (
      <MitgationActionsWrapper {...props}>
        <MitigationActionsEmptyList
          {...props}
          onAddClick={oeReportPage.mitigations?.length ? onAddClick : undefined}
          onCreateClick={onCreateClick}
        />
        <MitigationActionsConfirmationForm {...props} />
      </MitgationActionsWrapper>
    );
  }

  return (
    <MitgationActionsWrapper {...props}>
      <MitigationActionsList
        {...props}
        onAddClick={onAddClick}
        onCreateClick={onCreateClick}
      />
      <MitigationActionsConfirmationForm {...props} />
    </MitgationActionsWrapper>
  );
};

const MitgationActionsWrapper: ScenariosFormComponent = ({
  children,
  ...props
}) => {
  const { ref, styles } = useFadeIn({ duration: 200 });

  return (
    <>
      <Box
        ref={ref}
        sx={{
          ...styles,
          display: "flex",
          flexDirection: "column",
          flexShrink: 0,
          height: "100%",
        }}
      >
        <ScenariosFormHeader {...props} />
        <Box sx={{ m: 4, mr: 6 }}>{children}</Box>
      </Box>
      <ScenariosErrorsDialog
        showValidationErrorsDialog={!!props.showValidationErrorsDialog}
        onContinue={() => props.fireEvent?.("form:ignore-errors")}
        onResolve={() => props.fireEvent?.("form:show-errors")}
      />
    </>
  );
};

const MitigationActionsAddForm: ScenariosFormComponent<{
  onCancel: MouseEventHandler<HTMLButtonElement>;
}> = ({
  fireEvent,
  formValues,
  oeReportPage,
  onCancel,
  scenario,
  validationResult,
}) => {
  const {
    formMethods: { setValue, watch },
    handleSubmit,
  } = useScenariosForm({
    fireEvent,
    formValues,
    reset: false,
  });

  useEffect(() => {
    setValue(`mitigationActions`, {});
    setValue(`mitigationActions.add`, {
      mitigations: (scenario?.mitigations?.map(({ id }) => id) ??
        []) as number[],
    });
    /** this is fine, we want to reset and initialize the specific form values on mount */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const scenarioMitigationIDs =
    scenario?.mitigations?.map((mitigation) => mitigation.id!) ?? [];
  const mitigations = watch(`mitigationActions.add.mitigations`) ?? [];

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        mt: 4,
      }}
    >
      <Box
        sx={{
          border: "1px solid lightgrey",
          borderRadius: 5,
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
          display: "inline-flex",
          justifySelf: "center",
          px: 4,
          py: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <FormControl
            component="fieldset"
            error={hasError(
              "mitigationActions.add.mitigations",
              validationResult
            )}
            fullWidth
          >
            <Typography fontWeight="bold">List of Mitigations</Typography>
            <FormGroup>
              {oeReportPage?.mitigations?.map((mitigation) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mitigations.includes(mitigation.id!)}
                      disabled={scenarioMitigationIDs.includes(mitigation.id!)}
                    />
                  }
                  label={mitigation.projectTitle}
                  name={`mitigationActions.add.mitigations`}
                  key={mitigation.id}
                  onChange={() => {
                    if (mitigations.includes(mitigation.id!)) {
                      setValue(
                        `mitigationActions.add.mitigations`,
                        mitigations.filter((id) => id !== mitigation.id)
                      );
                    } else {
                      setValue(`mitigationActions.add.mitigations`, [
                        ...mitigations,
                        mitigation.id!,
                      ]);
                    }
                  }}
                  value={mitigation.id}
                />
              ))}
            </FormGroup>
            {hasError(
              "mitigationActions.add.mitigations",
              validationResult
            ) && (
              <FormHelperText>
                {getErrorMessage(
                  "mitigationActions.add.mitigations",
                  validationResult
                )}
              </FormHelperText>
            )}
          </FormControl>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "flex-end", mt: 2 }}
          >
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="submit" variant="contained">
              Add
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

const MitigationActionsDeleteAndRemoveForm: ScenariosFormComponent<{
  id: number;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  type: "delete" | "remove";
}> = ({ fireEvent, formValues, id, onCancel, type }) => {
  const {
    formMethods: { setValue },
    handleSubmit,
  } = useScenariosForm({
    fireEvent,
    formValues,
    reset: false,
  });

  useEffect(() => {
    setValue(`mitigationActions`, {});
    setValue(`mitigationActions.${type}`, { id });
    /** this is fine, we want to reset and initialize the specific form values on mount */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        mt: 4,
      }}
    >
      <Box
        sx={{
          border: "1px solid lightgrey",
          borderRadius: 5,
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
          display: "inline-flex",
          justifySelf: "center",
          px: 4,
          py: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth component="fieldset">
            {type === "delete" && (
              <Typography fontWeight="bold">Delete Mitigation</Typography>
            )}
            {type === "remove" && (
              <Typography fontWeight="bold">
                Remove Mitigation from Scenario
              </Typography>
            )}
            <Typography>
              Are you sure you want to {type} this Mitigation?
            </Typography>
          </FormControl>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "flex-end", mt: 2 }}
          >
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="submit" variant="contained">
              {type === "delete" && "Delete"}
              {type === "remove" && "Remove"}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export const MitigationActionsForm: ScenariosFormComponent<{
  disabled?: boolean;
  hideUpdateButton?: boolean;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  mitigation?: FE_Mitigation;
  type: "create" | "update";
}> = ({
  disabled = false,
  hideUpdateButton = false,
  fireEvent,
  formValues,
  mitigation,
  onCancel,
  oeReportPage,
  scenario,
  type,
  validationResult,
}) => {
  const {
    formMethods: { control, register, setValue, watch },
    handleSubmit,
  } = useScenariosForm({
    fireEvent,
    formValues,
    reset: false,
  });

  useEffect(() => {
    setValue(`mitigationActions`, {});
    setValue(`mitigationActions.${type}`, {
      ...(mitigation ?? ({} as FE_Mitigation)),
      scenarios:
        type === "create"
          ? ([scenario.id!] as number[])
          : ((mitigation?.scenarios ?? []).map(
              (scenario) => scenario.id
            ) as number[]),
    });
    setValue(
      `mitigationActions.${type}.completionDate`,
      mitigation?.completionDate ?? ""
    );
    /** this is fine, we want to reset and initialize the specific form values on mount */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const scenarios = watch(`mitigationActions.${type}.scenarios`) ?? [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        height: "100%",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          {type === "update" && (
            <input
              {...register(`mitigationActions.${type}.id`)}
              type="hidden"
            />
          )}
          <FormControl fullWidth>
            <TextField
              disabled={disabled}
              error={hasError(
                `mitigationActions.${type}.projectTitle`,
                validationResult
              )}
              inputProps={register(`mitigationActions.${type}.projectTitle`)}
              helperText={getErrorMessage(
                `mitigationActions.${type}.projectTitle`,
                validationResult
              )}
              label="Project Title"
              sx={disabled ? DISABLED_INPUT_STYLES : {}}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`mitigationActions.${type}.completionDate`}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    {...field}
                    disabled={disabled}
                    disablePast
                    label="Estimated Completion Date"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={disabled}
                        error={hasError(
                          `mitigationActions.${type}.completionDate`,
                          validationResult
                        )}
                        helperText={getErrorMessage(
                          `mitigationActions.${type}.completionDate`,
                          validationResult
                        )}
                        sx={disabled ? DISABLED_INPUT_STYLES : {}}
                      />
                    )}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date: Dayjs | null) => {
                      if (!date) return;
                      setValue(
                        `mitigationActions.${type}.completionDate`,
                        formatDate(date)
                      );
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <FormControl fullWidth>
            <TextField
              disabled={disabled}
              error={hasError(
                `mitigationActions.${type}.projectOwner`,
                validationResult
              )}
              inputProps={register(`mitigationActions.${type}.projectOwner`)}
              helperText={getErrorMessage(
                `mitigationActions.${type}.projectOwner`,
                validationResult
              )}
              label="Owner"
              sx={disabled ? DISABLED_INPUT_STYLES : {}}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              disabled={disabled}
              error={hasError(
                `mitigationActions.${type}.source`,
                validationResult
              )}
              inputProps={register(`mitigationActions.${type}.source`)}
              helperText={getErrorMessage(
                `mitigationActions.${type}.source`,
                validationResult
              )}
              label="Source"
              sx={disabled ? DISABLED_INPUT_STYLES : {}}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <FormControl fullWidth>
            <TextField
              disabled={disabled}
              error={hasError(
                `mitigationActions.${type}.projectScope`,
                validationResult
              )}
              inputProps={register(`mitigationActions.${type}.projectScope`)}
              helperText={getErrorMessage(
                `mitigationActions.${type}.projectScope`,
                validationResult
              )}
              label="Project Scope"
              multiline
              rows={4}
              sx={disabled ? DISABLED_INPUT_STYLES : {}}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              disabled={disabled}
              error={hasError(
                `mitigationActions.${type}.description`,
                validationResult
              )}
              inputProps={register(`mitigationActions.${type}.description`)}
              helperText={getErrorMessage(
                `mitigationActions.${type}.description`,
                validationResult
              )}
              label="Description"
              multiline
              rows={4}
              sx={disabled ? DISABLED_INPUT_STYLES : {}}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControl
            fullWidth
            component="fieldset"
            error={hasError(
              `mitigationActions.${type}.scenarios`,
              validationResult
            )}
          >
            <Typography>Scenarios Addressed</Typography>
            <FormGroup>
              {oeReportPage?.scenarios?.map((scenario) => (
                <FormControlLabel
                  control={
                    <Checkbox checked={scenarios.includes(scenario.id!)} />
                  }
                  disabled={disabled}
                  label={scenario.name}
                  name={`mitigationActions.${type}.scenarios`}
                  key={scenario.id}
                  onChange={() => {
                    if (scenarios.includes(scenario.id!)) {
                      setValue(
                        `mitigationActions.${type}.scenarios`,
                        scenarios.filter((id) => id !== scenario.id)
                      );
                    } else {
                      setValue(`mitigationActions.${type}.scenarios`, [
                        ...scenarios,
                        scenario.id!,
                      ]);
                    }
                  }}
                  value={scenario.id}
                />
              ))}
            </FormGroup>
            {hasError(
              `mitigationActions.${type}.scenarios`,
              validationResult
            ) && (
              <FormHelperText>
                {getErrorMessage(
                  `mitigationActions.${type}.scenarios`,
                  validationResult
                )}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            gap: 2,
            justifyContent: "center",
            m: 2,
          }}
        >
          {onCancel && (
            <Button onClick={onCancel} variant="text">
              Cancel
            </Button>
          )}
          {!disabled && type === "create" && (
            <Button type="submit" variant="contained">
              Create
            </Button>
          )}
          {!disabled && hideUpdateButton !== true && type === "update" && (
            <Button startIcon={<Save />} type="submit" variant="contained">
              Update
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

const MitigationActionsList: ScenariosFormComponent<{
  onAddClick: MouseEventHandler<HTMLButtonElement>;
  onCreateClick: MouseEventHandler<HTMLButtonElement>;
}> = ({ disabled: disabledViaProps, onAddClick, onCreateClick, ...props }) => {
  const { fireEvent, formValues, scenario } = props;
  const [active, setActive] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Box>
      {scenario.mitigations?.map((mitigation) => {
        const enabled =
          mitigation?.id === active && searchParams.get("action") === "update";
        const disabled = disabledViaProps || !enabled;

        return (
          <Accordion
            expanded={mitigation?.id === active}
            key={mitigation?.id}
            onChange={() => {
              setActive(mitigation?.id !== active ? mitigation?.id : undefined);
            }}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              borderBottom: "1px solid lightgrey",
              pb: 2,
              "&:before": { display: "none " },
            }}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  pr: 2,
                }}
              >
                <Typography fontWeight="bold">
                  {mitigation.projectTitle}
                </Typography>
                <Box sx={{ display: "flex", gap: 2, height: "40px" }}>
                  {!disabledViaProps && (
                    <>
                      <TracyTooltip title="Remove Mitigation Action">
                        <Fab
                          color="primary"
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (mitigation.id) {
                              setSearchParams({
                                action: "remove",
                                mitigation: String(mitigation.id),
                              });
                            }
                          }}
                        >
                          <Remove />
                        </Fab>
                      </TracyTooltip>
                      {enabled ? (
                        <TracyTooltip title="Save Mitigation Action">
                          <Fab
                            color="primary"
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              if (formValues) {
                                fireEvent?.("form:submit", { formValues });
                              }
                            }}
                          >
                            <Save />
                          </Fab>
                        </TracyTooltip>
                      ) : (
                        <TracyTooltip title="Edit Mitigation Action">
                          <Fab
                            color="primary"
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              setActive(mitigation?.id);
                              setSearchParams({ action: "update" });
                            }}
                          >
                            <Edit />
                          </Fab>
                        </TracyTooltip>
                      )}
                      <TracyTooltip title="Delete Mitigation Action">
                        <Fab
                          color="primary"
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (mitigation.id) {
                              setSearchParams({
                                action: "delete",
                                mitigation: String(mitigation.id),
                              });
                            }
                          }}
                        >
                          <Delete />
                        </Fab>
                      </TracyTooltip>
                    </>
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <MitigationActionsForm
                {...props}
                disabled={disabled}
                mitigation={mitigation as unknown as FE_Mitigation}
                onCancel={
                  enabled
                    ? () => {
                        setSearchParams({});
                      }
                    : undefined
                }
                type="update"
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
      {!disabledViaProps && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            gap: 2,
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Button onClick={onAddClick} variant="outlined" startIcon={<Add />}>
            Add existing Mitigation
          </Button>
          <Button
            onClick={onCreateClick}
            variant="outlined"
            startIcon={<Add />}
          >
            New Mitigation
          </Button>
        </Box>
      )}
    </Box>
  );
};

const MitigationActionsEmptyList: ScenariosFormComponent<{
  onAddClick?: MouseEventHandler<HTMLButtonElement>;
  onCreateClick: MouseEventHandler<HTMLButtonElement>;
}> = ({ disabled, onAddClick, onCreateClick }) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: 2,
        justifyContent: "center",
      }}
    >
      <Typography fontStyle="italic">
        {disabled
          ? "No Mitigation defined yet."
          : "No Mitigation defined yet. Please click here to start."}
      </Typography>
      {!disabled && (
        <Box sx={{ display: "flex", gap: 2 }}>
          {onAddClick && (
            <Button onClick={onAddClick} variant="outlined" startIcon={<Add />}>
              Add existing Mitigation
            </Button>
          )}
          <Button
            onClick={onCreateClick}
            variant="outlined"
            startIcon={<Add />}
          >
            New Mitigation
          </Button>
        </Box>
      )}
    </Box>
  );
};

const MitigationActionsConfirmationForm: ScenariosFormComponent = ({
  disabled,
  fireEvent,
  formValues,
  scenario,
  validationResult,
}) => {
  const {
    formMethods: { control },
    handleSubmit,
  } = useScenariosForm({
    fireEvent,
    formValues: {
      ...formValues,
      mitigationActions: {
        ...formValues?.mitigationActions,
        confirm: {
          mitigationsConfirmed: scenario?.mitigationsConfirmed,
        },
      },
    },
    reset: false,
  });

  if (disabled) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          justifyContent: "center",
          my: 4,
          mx: 6,
        }}
      >
        <form onSubmit={handleSubmit}>
          <FormControl
            fullWidth
            error={hasError(
              "mitigationActions.confirm.mitigationsConfirmed",
              validationResult
            )}
          >
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="mitigationActions.confirm.mitigationsConfirmed"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              }
              label="I confirm that I've added all relevant mitigation actions for the scenario or that no planned mitigation actions exist."
            />
            {hasError(
              "mitigationActions.confirm.mitigationsConfirmed",
              validationResult
            ) && (
              <FormHelperText>
                {getErrorMessage(
                  "mitigationActions.confirm.mitigationsConfirmed",
                  validationResult
                )}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </Box>
    </>
  );
};

import { App, Plugin } from "@pimo/pimo-app-builder";

import { TracyAppState } from "../app";
import { APP_ROUTES } from "../constants";

/**
 * @TODO plugin that creates a virtual route that forwards the user to the group dashboard for now.
 * later, we will use this to check which role the user has and forward
 * them to different pages accordingly.
 */
export class HomeRouteHandlerPlugin implements Plugin<TracyAppState> {
  onRegister(app: App<TracyAppState>): void {
    const homeRoute = app.createRoute({
      path: "/",
      view: app.createView({ name: "home" }),
    });

    homeRoute.on("load", () => app.navigate(APP_ROUTES.groupDashboard));
  }
}

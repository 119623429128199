import type { LatestSteps } from "./types";

export function getLatestSteps() {
  const localStorageString = localStorage.getItem("latestSteps");

  if (!localStorageString) {
    return null;
  }

  return JSON.parse(localStorageString) as LatestSteps;
}

import { Box, Typography } from "@mui/material";
import { type FC, useEffect, useRef } from "react";

import { InfoPopup } from "../info-popup/info-popup";
import type { ScenariosFormProps } from "./types";

type ScenariosFormHeaderProps = ScenariosFormProps;

export const ScenariosFormHeader: FC<ScenariosFormHeaderProps> = ({
  scenario,
  step,
}) => {
  const headerRef = useRef<HTMLElement>();
  /** this effect scrolls back to the top of the page */
  useEffect(() => {
    headerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [step]);

  return (
    <Box
      id="form-header"
      ref={headerRef}
      sx={{
        borderBottom: "1px solid lightgrey",
        display: "flex",
        gap: 3,
        flexDirection: "column",
        p: 4,
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>
        {[scenario.name, [step.name, step.title].filter(Boolean).join(" - ")]
          .filter(Boolean)
          .join(": ")}
      </Typography>
      <InfoPopup
        shortText={step.shortDescription}
        longText={step.longDescription}
        images={step.images}
      />
    </Box>
  );
};

import {
  DeleteOutlined,
  EditOutlined,
  RestorePageOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { type FC, type PropsWithChildren, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  ACTORS,
  AFFECTED_ATTRIBUTES,
  EntityValidationResult,
  type FE_Scenario,
  type OverridableScenarioParametersKey,
  RESPONSE_DURATIONS,
} from "tracy-types";
import { doesFieldDifferFromDefault } from "tracy-utils";

import { FIELD_CONFIG } from "../../../config/field-config";
import { DISABLED_INPUT_STYLES } from "../../../constants";
import { getErrorMessage } from "../../../helpers/validation/get-error-message";
import { hasError } from "../../../helpers/validation/has-error";
import { TracyTooltip } from "../../tracy-tooltip";
import { ScenariosForm } from "../scenarios-form";
import type { ScenariosFormComponent, ScenariosFormValues } from "../types";

const OUTER_BOX_STYLES: SxProps = {
  display: "flex",
  flexDirection: "row",
  gap: 2,
  m: 4,
  mr: 6,
};

const INNER_BOX_STYLES: SxProps = {
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: "2px",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: 2,
  p: 2,
};

export type ScenarioParametersEvents = "parameters:delete" | "parameters:reset";

export const ScenarioParameters: ScenariosFormComponent = (props) => {
  const { oeRatings, oeReportPage, scenario, step } = props;

  if (!oeRatings || !oeReportPage || !scenario || !step) {
    return <></>;
  }

  return (
    <ScenariosForm {...props}>
      <ScenarioParametersForm
        disabled={props.disabled}
        fireEvent={props.fireEvent}
        scenario={props.scenario}
        validationResult={props?.validationResult}
      />
    </ScenariosForm>
  );
};

export const ScenarioParametersForm: PimoReactComponent<
  {
    disabled?: boolean;
    validationResult?: EntityValidationResult;
    scenario: FE_Scenario;
  },
  ScenarioParametersEvents,
  undefined
> = ({ validationResult, scenario, fireEvent, disabled: disabledViaProps }) => {
  const { register, setValue, watch } = useFormContext<ScenariosFormValues>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteWarningDialogOpen, setDeleteWarningDialogOpen] = useState(false);
  const [resetWarningDialogOpen, setResetWarningDialogOpen] = useState(false);
  const mode = searchParams.get("action") || "read";
  const disabled = disabledViaProps || mode === "read";

  const actor = watch("scenarioParameters.actor.content");
  const responseDuration = watch("scenarioParameters.responseDuration.content");
  const scenarioParameters = watch("scenarioParameters");

  const hasDefaultScenario = !!scenario.defaultScenario;
  const hasDefaultScenarioAndFieldDiffers = (
    field: OverridableScenarioParametersKey
  ) => {
    return (
      hasDefaultScenario &&
      doesFieldDifferFromDefault({
        field,
        scenarioParameters,
        defaultScenarioParameters: scenario.defaultScenario,
      })
    );
  };

  return (
    <>
      {!disabledViaProps && (
        <Box sx={{ ...OUTER_BOX_STYLES, justifyContent: "flex-end" }}>
          <FormControl
            fullWidth
            error={hasError(
              "scenarioParameters.parametersConfirmed",
              validationResult
            )}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={watch("scenarioParameters.parametersConfirmed")}
                />
              }
              name="scenarioParameters.parametersConfirmed"
              label={
                hasDefaultScenario
                  ? "I confirm that I've read the entire scenario description and have made individual adjustments or specifications where necessary."
                  : "I confirm that all the necessary information to define the OE-specific scenario has been provided."
              }
              onChange={(e) => {
                setValue(
                  `scenarioParameters.parametersConfirmed`,
                  (e.target as HTMLInputElement).checked
                );
              }}
            />
            {hasError(
              "scenarioParameters.parametersConfirmed",
              validationResult
            ) && (
              <FormHelperText>
                {getErrorMessage(
                  "scenarioParameters.parametersConfirmed",
                  validationResult
                )}
              </FormHelperText>
            )}
          </FormControl>
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            {mode === "read" && (
              <TracyTooltip title="Edit">
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => setSearchParams({ action: "edit" })}
                >
                  <EditOutlined />
                </Fab>
              </TracyTooltip>
            )}
            {mode === "edit" && (
              <TracyTooltip title="Save">
                <Fab size="small" color="primary" type="submit">
                  <SaveOutlined />
                </Fab>
              </TracyTooltip>
            )}
            {hasDefaultScenario ? (
              <TracyTooltip title="Reset">
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => setResetWarningDialogOpen(true)}
                >
                  <RestorePageOutlined />
                </Fab>
              </TracyTooltip>
            ) : (
              <TracyTooltip title="Delete">
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => setDeleteWarningDialogOpen(true)}
                >
                  <DeleteOutlined />
                </Fab>
              </TracyTooltip>
            )}
          </Box>
        </Box>
      )}
      <Box sx={OUTER_BOX_STYLES}>
        <ScenarioParametersFormBlock
          icon="scenario-story"
          title={FIELD_CONFIG.storyAndName.displayName}
        >
          <Box>
            <FormControl
              fullWidth
              error={hasError("scenarioParameters.name", validationResult)}
            >
              <TextField
                disabled={disabled || hasDefaultScenario}
                inputProps={register("scenarioParameters.name")}
                error={hasError("scenarioParameters.name", validationResult)}
                helperText={getErrorMessage(
                  "scenarioParameters.name",
                  validationResult
                )}
                label={FIELD_CONFIG["name"].displayName}
                sx={disabled || hasDefaultScenario ? DISABLED_INPUT_STYLES : {}}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.shortDescription.content",
                validationResult
              )}
            >
              <TextField
                disabled={disabled || hasDefaultScenario}
                inputProps={register(
                  "scenarioParameters.shortDescription.content"
                )}
                error={hasError(
                  "scenarioParameters.shortDescription.content",
                  validationResult
                )}
                helperText={getErrorMessage(
                  "scenarioParameters.shortDescription.content",
                  validationResult
                )}
                label={"Short Description"}
                multiline
                rows={3}
                sx={disabled || hasDefaultScenario ? DISABLED_INPUT_STYLES : {}}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.story.content",
                validationResult
              )}
            >
              <TextField
                disabled={disabled || hasDefaultScenario}
                inputProps={register("scenarioParameters.story.content")}
                error={hasError(
                  "scenarioParameters.story.content",
                  validationResult
                )}
                helperText={getErrorMessage(
                  "scenarioParameters.story.content",
                  validationResult
                )}
                label={FIELD_CONFIG["story"].displayName}
                multiline
                rows={10}
                sx={disabled || hasDefaultScenario ? DISABLED_INPUT_STYLES : {}}
              />
            </FormControl>
          </Box>
        </ScenarioParametersFormBlock>
        <ScenarioParametersFormBlock icon="threat-actor" title="Threat Actor">
          <Box sx={INNER_BOX_STYLES}>
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.actor.content",
                validationResult
              )}
            >
              <InputLabel>{FIELD_CONFIG["actor"].displayName}</InputLabel>
              <Select
                disabled={disabled}
                inputProps={register("scenarioParameters.actor.content")}
                onChange={(e) =>
                  setValue(
                    "scenarioParameters.actor.content",
                    e.target.value as (typeof ACTORS)[number]
                  )
                }
                label={FIELD_CONFIG["actor"].displayName}
                sx={disabled ? DISABLED_INPUT_STYLES : {}}
                value={actor ?? ""}
              >
                {ACTORS.map((actor) => (
                  <MenuItem value={actor} key={actor}>
                    {actor}
                  </MenuItem>
                ))}
              </Select>
              {hasError(
                "scenarioParameters.actor.content",
                validationResult
              ) && (
                <FormHelperText>
                  {getErrorMessage(
                    "scenarioParameters.actor.content",
                    validationResult
                  )}
                </FormHelperText>
              )}
            </FormControl>
            {hasDefaultScenarioAndFieldDiffers("actor") && (
              <ScenarioParametersCommentField
                disabled={disabled}
                field="actor"
                validationResult={validationResult}
              />
            )}
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.motivesObjectives.content",
                validationResult
              )}
            >
              <TextField
                disabled={disabled}
                inputProps={register(
                  "scenarioParameters.motivesObjectives.content"
                )}
                error={hasError(
                  "scenarioParameters.motivesObjectives.content",
                  validationResult
                )}
                helperText={getErrorMessage(
                  "scenarioParameters.motivesObjectives.content",
                  validationResult
                )}
                label={FIELD_CONFIG["motivesObjectives"].displayName}
                multiline
                rows={2}
                sx={disabled ? DISABLED_INPUT_STYLES : {}}
              />
            </FormControl>
            {hasDefaultScenarioAndFieldDiffers("motivesObjectives") && (
              <ScenarioParametersCommentField
                disabled={disabled}
                field="motivesObjectives"
                validationResult={validationResult}
              />
            )}
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.assetsAtRisk.content",
                validationResult
              )}
            >
              <TextField
                disabled={disabled}
                inputProps={register("scenarioParameters.assetsAtRisk.content")}
                error={hasError(
                  "scenarioParameters.assetsAtRisk.content",
                  validationResult
                )}
                helperText={getErrorMessage(
                  "scenarioParameters.assetsAtRisk.content",
                  validationResult
                )}
                label={FIELD_CONFIG["assetsAtRisk"].displayName}
                multiline
                rows={2}
                sx={disabled ? DISABLED_INPUT_STYLES : {}}
              />
            </FormControl>
            {hasDefaultScenarioAndFieldDiffers("assetsAtRisk") && (
              <ScenarioParametersCommentField
                disabled={disabled}
                field="assetsAtRisk"
                validationResult={validationResult}
              />
            )}
          </Box>
        </ScenarioParametersFormBlock>
      </Box>
      <Box sx={OUTER_BOX_STYLES}>
        <ScenarioParametersFormBlock
          icon="threat-event"
          title="Threat Event / Vulnerability"
        >
          <Box sx={INNER_BOX_STYLES}>
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.initialAction.content",
                validationResult
              )}
            >
              <TextField
                disabled={disabled}
                inputProps={register(
                  "scenarioParameters.initialAction.content"
                )}
                error={hasError(
                  "scenarioParameters.initialAction.content",
                  validationResult
                )}
                helperText={getErrorMessage(
                  "scenarioParameters.initialAction.content",
                  validationResult
                )}
                label={FIELD_CONFIG["initialAction"].displayName}
                multiline
                rows={3}
                sx={disabled ? DISABLED_INPUT_STYLES : {}}
              />
            </FormControl>
            {hasDefaultScenarioAndFieldDiffers("initialAction") && (
              <ScenarioParametersCommentField
                disabled={disabled}
                field="initialAction"
                validationResult={validationResult}
              />
            )}
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.actionFurtherActionAttackTtp.content",
                validationResult
              )}
            >
              <TextField
                disabled={disabled}
                inputProps={register(
                  "scenarioParameters.actionFurtherActionAttackTtp.content"
                )}
                error={hasError(
                  "scenarioParameters.actionFurtherActionAttackTtp.content",
                  validationResult
                )}
                helperText={getErrorMessage(
                  "scenarioParameters.actionFurtherActionAttackTtp.content",
                  validationResult
                )}
                label={FIELD_CONFIG["actionFurtherActionAttackTtp"].displayName}
                multiline
                rows={3}
                sx={disabled ? DISABLED_INPUT_STYLES : {}}
              />
            </FormControl>
            {hasDefaultScenarioAndFieldDiffers(
              "actionFurtherActionAttackTtp"
            ) && (
              <ScenarioParametersCommentField
                disabled={disabled}
                field="actionFurtherActionAttackTtp"
                validationResult={validationResult}
              />
            )}
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.vulnerability.content",
                validationResult
              )}
            >
              <TextField
                disabled={disabled}
                inputProps={register(
                  "scenarioParameters.vulnerability.content"
                )}
                error={hasError(
                  "scenarioParameters.vulnerability.content",
                  validationResult
                )}
                helperText={getErrorMessage(
                  "scenarioParameters.vulnerability.content",
                  validationResult
                )}
                label={FIELD_CONFIG["vulnerability"].displayName}
                multiline
                rows={3}
                sx={disabled ? DISABLED_INPUT_STYLES : {}}
              />
            </FormControl>
            {hasDefaultScenarioAndFieldDiffers("vulnerability") && (
              <ScenarioParametersCommentField
                disabled={disabled}
                field="vulnerability"
                validationResult={validationResult}
              />
            )}
          </Box>
        </ScenarioParametersFormBlock>
        <ScenarioParametersFormBlock icon="impact" title="Business Impact">
          <Box sx={INNER_BOX_STYLES}>
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.businessImpact.content",
                validationResult
              )}
            >
              <TextField
                disabled={disabled}
                inputProps={register(
                  "scenarioParameters.businessImpact.content"
                )}
                error={hasError(
                  "scenarioParameters.businessImpact.content",
                  validationResult
                )}
                helperText={getErrorMessage(
                  "scenarioParameters.businessImpact.content",
                  validationResult
                )}
                label={FIELD_CONFIG["businessImpact"].displayName}
                multiline
                rows={3}
                sx={disabled ? DISABLED_INPUT_STYLES : {}}
              />
            </FormControl>
            {hasDefaultScenarioAndFieldDiffers("businessImpact") && (
              <ScenarioParametersCommentField
                disabled={disabled}
                field="businessImpact"
                validationResult={validationResult}
              />
            )}
            <FormControl fullWidth component="fieldset">
              <Typography>
                {FIELD_CONFIG["affectedAttributes"].displayName}
              </Typography>
              <FormGroup>
                {AFFECTED_ATTRIBUTES.map((attribute) => {
                  const key = attribute.toLowerCase() as
                    | "availability"
                    | "integrity"
                    | "confidentiality";

                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={watch(
                            `scenarioParameters.affectedAttributes.${key}`
                          )}
                          disabled={disabled}
                        />
                      }
                      label={attribute}
                      name={`scenarioParameters.affectedAttributes.${key}`}
                      key={key}
                      onChange={(e) => {
                        setValue(
                          `scenarioParameters.affectedAttributes.${key}`,
                          (e.target as HTMLInputElement).checked
                        );
                      }}
                    />
                  );
                })}
              </FormGroup>
              {hasDefaultScenarioAndFieldDiffers("affectedAttributes") && (
                <ScenarioParametersCommentField
                  disabled={disabled}
                  field="affectedAttributes"
                  validationResult={validationResult}
                />
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={hasError(
                "scenarioParameters.responseDuration.content",
                validationResult
              )}
            >
              <InputLabel>
                {FIELD_CONFIG["responseDuration"].displayName}
              </InputLabel>
              <Select
                disabled={disabled}
                inputProps={register(
                  "scenarioParameters.responseDuration.content"
                )}
                label={FIELD_CONFIG["responseDuration"].displayName}
                onChange={(e) =>
                  setValue(
                    "scenarioParameters.responseDuration.content",
                    e.target.value as (typeof RESPONSE_DURATIONS)[number]
                  )
                }
                sx={disabled ? DISABLED_INPUT_STYLES : {}}
                value={responseDuration ?? ""}
              >
                {RESPONSE_DURATIONS.map((duration) => (
                  <MenuItem value={duration} key={duration}>
                    {duration}
                  </MenuItem>
                ))}
              </Select>

              {hasError(
                "scenarioParameters.responseDuration.content",
                validationResult
              ) && (
                <FormHelperText>
                  {getErrorMessage(
                    "scenarioParameters.responseDuration.content",
                    validationResult
                  )}
                </FormHelperText>
              )}
            </FormControl>
            {hasDefaultScenarioAndFieldDiffers("responseDuration") && (
              <ScenarioParametersCommentField
                disabled={disabled}
                field="responseDuration"
                validationResult={validationResult}
              />
            )}
          </Box>
        </ScenarioParametersFormBlock>
      </Box>
      {deleteWarningDialogOpen && (
        <Dialog open={deleteWarningDialogOpen}>
          <DialogTitle>
            Are you sure you want to delete the scenario?
          </DialogTitle>
          <Divider />
          <DialogContent>
            This scenario will be deleted. Any changes you made to this scenario
            will be lost.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteWarningDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                fireEvent?.("parameters:delete");
                setDeleteWarningDialogOpen(false);
              }}
            >
              Delete scenario
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {resetWarningDialogOpen && (
        <Dialog open={resetWarningDialogOpen}>
          <DialogTitle>
            Are you sure you want to reset the scenario parameters?
          </DialogTitle>
          <Divider />
          <DialogContent>
            All scenario parameters will be reset to the default values. Any
            changes you made to the parameters will be lost.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setResetWarningDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                fireEvent?.("parameters:reset");
                setResetWarningDialogOpen(false);
              }}
            >
              Reset scenario parameters
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

type ScenarioParametersFormBlockProps = PropsWithChildren<{
  icon: string;
  title: string;
}>;

const ScenarioParametersFormBlock: FC<
  PropsWithChildren<ScenarioParametersFormBlockProps>
> = ({ children, icon, title }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flex: "0 0 50%",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ alignItems: "center", display: "flex", gap: 2 }}>
        <Box
          sx={{
            background: theme.palette.primary.main,
            backgroundImage: `url('/${icon}.svg')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: 5,
            width: "2rem",
            height: "2rem",
          }}
        />
        <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

const ScenarioParametersCommentField: FC<{
  disabled: boolean;
  field: OverridableScenarioParametersKey;
  validationResult?: EntityValidationResult;
}> = ({ disabled, field, validationResult }) => {
  const { register } = useFormContext<ScenariosFormValues>();

  return (
    <FormControl
      fullWidth
      error={hasError(`scenarioParameters.${field}.comment`, validationResult)}
    >
      <TextField
        disabled={disabled}
        inputProps={register(`scenarioParameters.${field}.comment`)}
        error={hasError(
          `scenarioParameters.${field}.comment`,
          validationResult
        )}
        helperText={getErrorMessage(
          `scenarioParameters.${field}.comment`,
          validationResult
        )}
        label={[FIELD_CONFIG[field].displayName, "Comment"].join(": ")}
        placeholder="Please enter a comment on the changes applied"
        sx={disabled ? DISABLED_INPUT_STYLES : {}}
      />
    </FormControl>
  );
};

import type { FilterData, GroupDashboardPage } from "tracy-types";
import { buildURLParameters } from "tracy-utils";

import { STRAPI_URL } from "../../env";
/**
 * Fetches the data for the Group Dashboard Page.
 */
export async function fetchGroupDashboardPage(
  year: number,
  filterData?: FilterData
): Promise<GroupDashboardPage | undefined> {
  try {
    const params = buildURLParameters(filterData);

    const response = await fetch(
      `${STRAPI_URL}/api/bff/group-dashboard-page/${year}?${params.join("&")}`,
      { credentials: "include" }
    );

    return (await response.json()) as GroupDashboardPage;
  } catch {
    return;
  }
}

import type {
  OneInTwentyYearsProbabilityRating,
  OneInTwentyYearsProbabilityRatingOptions,
} from "tracy-types";
import { has1In20YearsProbability } from "tracy-utils";

import { STRAPI_URL } from "../../env";

export async function fetchOEProbabilityRating({
  id,
  year,
}: {
  id: number;
  year: number;
}): Promise<OneInTwentyYearsProbabilityRatingOptions | undefined> {
  if (!has1In20YearsProbability(year)) {
    return;
  }

  const response = await fetch(
    `${STRAPI_URL}/api/bff/oe-probability-rating/${id}/${year}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    return;
  }

  return (await response.json()) as OneInTwentyYearsProbabilityRatingOptions;
}

export async function updateOEProbabilityRating({
  data,
  id,
  year,
}: {
  data: OneInTwentyYearsProbabilityRating;
  id: number;
  year: number;
}) {
  await fetch(`${STRAPI_URL}/api/bff/oe-probability-rating/${id}/${year}`, {
    body: JSON.stringify({
      data,
    }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
  });
}

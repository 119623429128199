import type { FinancialConfig } from "tracy-types";

export const FINANCIAL_CONFIG = {
  "Short Assessment": [
    "What is the amount of revenue lost as a result of a business interruption?",
    "What are the expenses for data recovery, forensics and further incident response?",
    "What are the regulatory fines (e.g. in case of a data breach according to GDPR) as well as the costs for legal actions and notifications?",
    "For more detailed information and guidance, please refer to version 2.",
  ],
  "Business Outage": [
    "How long is the duration of the outage?",
    "Which business processes are affected, also indirectly?",
    "Loss of income during downtime / degraded operation? (Example: Sales Process are disrupted. Average sales per day multiply by duration)",
    "Increased cost of working  including third party services (e.g. how many FTEs for how long)? (Additional costs due to the interrupted process, e.g. manual workaround)",
    "Financial damage according to your contractual liabilities?",
    "...",
  ],
  "Data loss and/or manipulation": [
    "Cost of restoring data (including expenses to identify lost or damaged data and to determine whether the data can be restored etc.)? (E.g. how many FTE? Expenses for special software? External specialists?)",
    "Contractual liabilities (e.g. amount all data subjects can claim with class-action lawsuits)?",
    "Notification costs due to personal data loss (voluntarily and due to regulatory / legal requirements)? (E.g. how many FTE? How long does it take?)",
    "Equivalent monetary loss / value of stolen intellectual property as well as costs and expenses for their recovery?",
    "...",
  ],
  "Financial fraud or ransom": [
    "Do you have a strategy or an obligation (legal or regulatory) for not paying the ransom or it is possible to pay?",
    "What was the amount of money that was targeted by the financial fraud (e.g. in case of an employee is tricked into transferring money to a bank account owned by an attacker)?",
    "Cost (if applicable) for the accounting/statutory post-incident adjustments – actions with tax authorities?",
    "What is the financial impact of the loss on company’s financial results, financial planning (budget, expenses, reserves, etc.) and stock price?",
    "...",
  ],
  "Software and/or Hardware recovery": [
    "Increased cost of working or expenses for recovery / replacement of software on endpoints and servers (e.g. how many FTEs for how long)?",
    "Fixing of specific vulnerabilities / configuration leakages (e.g. of an outdated version of a web application)?",
    "Further recovery expenses for (third party) services?",
    "Expenses for temporaly use of hardware from third party?",
    "Replacement costs for damaged assets (e.g. Hardware such as servers/endpoint)?",
    "...",
  ],
  "Forensic expenses": [
    "Number of servers/endpoints, relevant for forensics analysis?",
    "External support needed or internal resources available?",
    "Which are the major affected processes and/ or services?",
    "…",
  ],
  "Regulatory & Legal defense costs": [
    "Costs for all legal actions required to report, sue, defend and all other related legal actions according to legislation?",
    "Costs for responding to governmental or regulatory inquiries related to the cyber-attack (e.g. for additional investigations)?",
    "What are the maximal regulatory fines and penalties, e.g. in case of data leakage according to local or international Data Protection Regulation (e.g. GDPR)?",
    "Further cost needed to fullfil organization's obligations to regulatory and/or financial authorities?",
    "...",
  ],
  "Communication and management": [
    "Expenses for increased PR services?",
    "Cost of expanding or outsourcing call center services to answer inquiries?",
    "Expenses for crisis management?",
    "...",
  ],
  "Other financial impact": [
    "Any other financial expenses the organization incurred or income the organization fails to earn as a direct result of the event that do not fit in any of the other categories?",
    "...",
  ],
} as const satisfies FinancialConfig;

import { FE_ControlEnvRating } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function updateControlEnvEffectiveness({
  controlEnvironmentEffectivenessEntries,
  oeId,
}: {
  controlEnvironmentEffectivenessEntries: FE_ControlEnvRating[];
  oeId: number;
}) {
  if (!Array.isArray(controlEnvironmentEffectivenessEntries)) {
    return [];
  }

  const response = await fetch(
    `${STRAPI_URL}/api/bff/control-env-effectiveness/${oeId}`,
    {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: controlEnvironmentEffectivenessEntries,
      }),
    }
  );

  if (response.status === 400) {
    throw await response.json();
  }
}

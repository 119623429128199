import { Grid } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  InformationCard,
  type InformationCardProps,
} from "@pimo/pimo-components";

import { DonutChartCard, type DonutChartCardProps } from "./donut-chart-card";

export type TopRisksAndScenarioRankingProps = {
  scenariosRankedByTopRisks: InformationCardProps;
  topRisksWithoutOverruling: DonutChartCardProps;
  topRisksWithOverruling: DonutChartCardProps;
};

export const TopRisksAndScenarioRanking: PimoReactComponent<
  TopRisksAndScenarioRankingProps
> = ({
  scenariosRankedByTopRisks,
  topRisksWithoutOverruling,
  topRisksWithOverruling,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid container item xs={6} spacing={1}>
        <Grid item xs={12}>
          <DonutChartCard {...topRisksWithoutOverruling} />
        </Grid>
        <Grid item xs={12}>
          <DonutChartCard {...topRisksWithOverruling} />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <InformationCard {...scenariosRankedByTopRisks} />
      </Grid>
    </Grid>
  );
};

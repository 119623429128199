import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import type { FE_Scenario, OERatingResponse } from "tracy-types";
import { getColorSchemeForRating } from "tracy-utils";

import { RATING_LABEL } from "../../../config/rating-label";
import { RatingChip } from "../../rating-chip";
import { ScenariosForm } from "../scenarios-form";
import { ScenariosFormComponent } from "../types";

type ComparisonTableRow = {
  title: string;
  currentRisk: string;
  residualRisk: string;
  colorSchemeForCurrent: { chipColor: string; textColor: string };
  colorSchemeForResidual: { chipColor: string; textColor: string };
  difference: number;
};

export const CompareCurrentVsResidualCyberRisk: ScenariosFormComponent = (
  props
) => {
  const { oeRatings, oeReportPage, scenario, step } = props;

  if (!oeRatings || !oeReportPage || !scenario || !step) {
    return <></>;
  }

  return (
    <ScenariosForm {...props}>
      <CompareCurrentVsResidualCyberRiskForm {...props} />
    </ScenariosForm>
  );
};

const CompareCurrentVsResidualCyberRiskForm: ScenariosFormComponent = ({
  oeRatings,
  scenario,
}) => {
  const tableRows: ComparisonTableRow[] = fillTable(oeRatings, scenario);
  const scenarioRatings = oeRatings?.ratings?.find(
    ({ id }) => id === scenario.id
  );
  const currentRatings = scenarioRatings?.current;
  const residualRatings = scenarioRatings?.residual;
  const currentTopRisk = currentRatings?.isTopRisk;
  const residualTopRisk = residualRatings?.isTopRisk;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            m: 4,
            mr: 6,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                    borderRadius: "10px 0 0 10px",
                  }}
                >
                  Overview
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                  }}
                >
                  Current Cyber Risk
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                  }}
                >
                  Residual Cyber Risk
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                    borderRadius: "0 10px 10px 0",
                  }}
                >
                  Difference
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableRows.map(
                ({
                  title,
                  currentRisk,
                  residualRisk,
                  difference,
                  colorSchemeForResidual,
                  colorSchemeForCurrent,
                }) => (
                  <TableRow key={title}>
                    <TableCell>
                      <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                        {title}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <RatingChip
                        label={currentRisk}
                        background={colorSchemeForCurrent.chipColor}
                        color={colorSchemeForCurrent.textColor}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <RatingChip
                        label={residualRisk}
                        background={colorSchemeForResidual.chipColor}
                        color={colorSchemeForResidual.textColor}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <RatingChip
                        label={String(difference)}
                        background="#ffffff"
                        border="#C4C4C4"
                        color="#000000"
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                    Top Risk
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ border: "none" }}>
                  <RatingChip
                    label={
                      currentTopRisk == null
                        ? "N/A"
                        : currentTopRisk
                          ? "YES"
                          : "NO"
                    }
                    background={currentTopRisk == null ? "#C4C4C4" : "#2196F3"}
                    border={currentTopRisk == null ? "#C4C4C4" : "#2196F3"}
                  />
                </TableCell>
                <TableCell align="center" sx={{ border: "none" }}>
                  <RatingChip
                    label={
                      residualTopRisk == null
                        ? "N/A"
                        : residualTopRisk
                          ? "YES"
                          : "NO"
                    }
                    background={residualTopRisk == null ? "#C4C4C4" : "#2196F3"}
                    border={residualTopRisk == null ? "#C4C4C4" : "#2196F3"}
                  />
                </TableCell>
                <TableCell sx={{ border: "none" }} />
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>

      {/* empty box to push content to the left not to touch the right screen edge */}
      <Box />
    </>
  );
};

function fillTable(
  oeRatings: OERatingResponse | undefined,
  scenario: FE_Scenario
): ComparisonTableRow[] {
  if (!oeRatings || !scenario) {
    return [];
  }

  const tableRows: ComparisonTableRow[] = [];

  const scenarioRatings = oeRatings?.ratings?.find(
    ({ id }) => id === scenario.id
  );
  const currentRatings = scenarioRatings?.current;
  const residualRatings = scenarioRatings?.residual;
  const deltaRatings = scenarioRatings?.delta;

  const probabilityRatingLabelCurrent = currentRatings?.probabilityRating
    ? `${RATING_LABEL.ProbabilityRating[currentRatings.probabilityRating]} (${
        currentRatings.probabilityRating
      })`
    : "N/A";

  const probabilityRatingLabelResidual = residualRatings?.probabilityRating
    ? `${RATING_LABEL.ProbabilityRating[residualRatings.probabilityRating]} (${
        residualRatings.probabilityRating
      })`
    : "N/A";

  tableRows.push({
    title: "Probability Rating",
    currentRisk: probabilityRatingLabelCurrent,
    residualRisk: probabilityRatingLabelResidual,
    difference: deltaRatings?.probabilityRating ?? 0,
    colorSchemeForResidual: getColorSchemeForRating(
      residualRatings?.probabilityRating
    ).colorScheme,
    colorSchemeForCurrent: getColorSchemeForRating(
      currentRatings?.probabilityRating
    ).colorScheme,
  });

  const financialImpactRatingLabelCurrent = currentRatings?.financialRating
    ? `${
        RATING_LABEL.FinancialImpact[currentRatings?.financialRating]
      } (${currentRatings?.financialRating})`
    : "N/A";

  const financialImpactRatingLabelResidual = residualRatings?.financialRating
    ? `${
        RATING_LABEL.FinancialImpact[residualRatings?.financialRating]
      } (${residualRatings?.financialRating})`
    : "N/A";

  tableRows.push({
    title: "Financial Impact Rating",
    currentRisk: financialImpactRatingLabelCurrent,
    residualRisk: financialImpactRatingLabelResidual,
    difference: deltaRatings?.financialRating ?? 0,
    colorSchemeForCurrent: getColorSchemeForRating(
      currentRatings?.financialRating
    ).colorScheme,
    colorSchemeForResidual: getColorSchemeForRating(
      residualRatings?.financialRating
    ).colorScheme,
  });

  const reputationalImpactRatingLabelCurrent =
    currentRatings?.reputationalRating
      ? `${
          RATING_LABEL.ReputationalImpact[currentRatings?.reputationalRating]
        } (${currentRatings?.reputationalRating})`
      : "N/A";

  const reputationalImpactRatingLabelResidual =
    residualRatings?.reputationalRating
      ? `${
          RATING_LABEL.ReputationalImpact[residualRatings?.reputationalRating]
        } (${residualRatings?.reputationalRating})`
      : "N/A";

  tableRows.push({
    title: "Reputational Impact Rating",
    currentRisk: reputationalImpactRatingLabelCurrent,
    residualRisk: reputationalImpactRatingLabelResidual,
    difference: deltaRatings?.reputationalRating ?? 0,
    colorSchemeForCurrent: getColorSchemeForRating(
      currentRatings?.reputationalRating
    ).colorScheme,
    colorSchemeForResidual: getColorSchemeForRating(
      residualRatings?.reputationalRating
    ).colorScheme,
  });

  tableRows.push({
    title: "Impact Severity",
    currentRisk: currentRatings?.impactSeverityRating
      ? `${
          RATING_LABEL.FinancialImpact[currentRatings?.impactSeverityRating]
        } (${currentRatings?.impactSeverityRating})`
      : "N/A",
    residualRisk: residualRatings?.impactSeverityRating
      ? `${
          RATING_LABEL.FinancialImpact[residualRatings?.impactSeverityRating]
        } (${residualRatings?.impactSeverityRating})`
      : "N/A",
    difference: deltaRatings?.impactSeverityRating ?? 0,
    colorSchemeForCurrent: getColorSchemeForRating(
      currentRatings?.impactSeverityRating
    ).colorScheme,
    colorSchemeForResidual: getColorSchemeForRating(
      residualRatings?.impactSeverityRating
    ).colorScheme,
  });

  tableRows.push({
    title: "Overall Rating",
    currentRisk: currentRatings?.overallRating
      ? `${
          RATING_LABEL.FinancialImpact[currentRatings?.overallRating]
        } (${currentRatings?.overallRating})`
      : "N/A",
    residualRisk: residualRatings?.overallRating
      ? `${
          RATING_LABEL.FinancialImpact[residualRatings?.overallRating]
        } (${residualRatings?.overallRating})`
      : "N/A",
    difference: deltaRatings?.overallRating ?? 0,
    colorSchemeForCurrent: getColorSchemeForRating(
      currentRatings?.overallRating
    ).colorScheme,
    colorSchemeForResidual: getColorSchemeForRating(
      residualRatings?.overallRating
    ).colorScheme,
  });

  return tableRows;
}

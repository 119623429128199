import {
  Alert,
  Box,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  type RatingField,
  REPUTATIONAL_SUBGROUPS,
  type ReputationalSubgroup,
  type ScenariosFormValues,
} from "tracy-types";
import { getColorSchemeForRating, getMaxRating } from "tracy-utils";

import { RATING_LABEL } from "../../../../config/rating-label";
import { REPUTATIONAL_CONFIG } from "../../../../config/reputational";
import { DISABLED_INPUT_STYLES } from "../../../../constants";
import { getErrorMessage } from "../../../../helpers/validation/get-error-message";
import { hasError } from "../../../../helpers/validation/has-error";
import type { ScenariosFormComponent } from "../../types";

export const ReputationalImpactOfCyberRiskForm: ScenariosFormComponent<{
  riskType: "current" | "residual";
}> = ({ disabled, formValues, riskType, validationResult }) => {
  const { watch, register, getValues, setValue } =
    useFormContext<ScenariosFormValues>();

  const values = watch(`reputationalImpact.${riskType}.values`);

  const [overallRating, setOverallRating] = useState<RatingField | undefined>(
    getMaxRating(
      Object.values(values ?? {})
        .map((v) => v.rating)
        .filter((v) => v != null)
    )
  );

  useEffect(() => {
    setValue(`reputationalImpact.${riskType}.rating`, overallRating);
  }, [overallRating, setValue, riskType]);

  const questions: ReputationalSubgroup[] = [
    ...REPUTATIONAL_SUBGROUPS,
  ] as ReputationalSubgroup[];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            m: 4,
            mr: 6,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  width="25%"
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                    borderRadius: "10px 0 0 10px",
                  }}
                >
                  Stakeholder
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                  }}
                >
                  Rating
                </TableCell>
                <TableCell
                  width="30%"
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                  }}
                >
                  Impact on Stakeholder
                </TableCell>
                <TableCell
                  width="30%"
                  sx={{
                    color: "#fff",
                    background: "#2196F3",
                    borderRadius: "0 10px 10px 0",
                  }}
                >
                  Comment
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {questions?.map((question, questionIndex) => {
                const borderBottom =
                  questionIndex === questions.length - 1
                    ? "none"
                    : "#DFEFF2 1px solid";

                return (
                  <Fragment key={question}>
                    {riskType === "residual" &&
                      getValues(
                        `reputationalImpact.current.values.${question}.rating`
                      ) <
                        getValues(
                          `reputationalImpact.residual.values.${question}.rating`
                        ) && (
                        <TableCell sx={{ border: "none" }} colSpan={4}>
                          <Alert sx={{ color: "gray" }} severity="warning">
                            The entered residual risk rating is worse than the
                            corresponding current risk rating. Please review
                            your assessment or provide an explanation in the
                            comment field
                          </Alert>
                        </TableCell>
                      )}
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom,
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                          {question}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <FormControl
                            key={`${questionIndex}rating`}
                            sx={{ flex: "1 1 100%" }}
                            error={hasError(
                              `reputationalImpact.${riskType}.values.${question}.rating`,
                              validationResult
                            )}
                          >
                            <InputLabel>Rating</InputLabel>
                            <Select
                              disabled={disabled}
                              label="Rating"
                              inputProps={register(
                                `reputationalImpact.${riskType}.values.${question}.rating`
                              )}
                              onChange={() => {
                                setOverallRating(
                                  getMaxRating(
                                    questions.map((q) => values[q].rating)
                                  )
                                );
                              }}
                              sx={
                                disabled
                                  ? { ...DISABLED_INPUT_STYLES, minWidth: 150 }
                                  : { minWidth: 150 }
                              }
                              value={values?.[question]?.rating ?? ""}
                              variant="outlined"
                            >
                              {Object.entries(
                                RATING_LABEL["ReputationalImpact"]
                              ).map(([key, value]) => (
                                <MenuItem value={+key} key={key}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                            {hasError(
                              `reputationalImpact.${riskType}.values.${question}.rating`,
                              validationResult
                            ) && (
                              <FormHelperText>
                                {getErrorMessage(
                                  `reputationalImpact.${riskType}.values.${question}.rating`,
                                  validationResult
                                )}
                              </FormHelperText>
                            )}
                          </FormControl>
                          {riskType === "residual" && (
                            <Typography sx={{ color: "#979797" }}>
                              Current Risk:{" "}
                              {formValues?.reputationalImpact?.current
                                ?.values?.[question]?.rating != null
                                ? RATING_LABEL["ReputationalImpact"][
                                    formValues?.reputationalImpact?.current
                                      ?.values?.[question]?.rating
                                  ]
                                : "N/A"}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom,
                        }}
                      >
                        <ul style={{ paddingLeft: "20px" }}>
                          {REPUTATIONAL_CONFIG?.[
                            getValues(
                              `reputationalImpact.${riskType}.values.${question}.rating`
                            )
                          ]?.[question]?.map((guidingQuestion) => (
                            <li key={guidingQuestion}>
                              <Typography>{guidingQuestion}</Typography>
                            </li>
                          ))}
                        </ul>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom,
                        }}
                      >
                        <TextField
                          disabled={disabled}
                          fullWidth
                          inputProps={register(
                            `reputationalImpact.${riskType}.values.${question}.comment`
                          )}
                          error={hasError(
                            `reputationalImpact.${riskType}.values.${question}.comment`,
                            validationResult
                          )}
                          helperText={getErrorMessage(
                            `reputationalImpact.${riskType}.values.${question}.comment`,
                            validationResult
                          )}
                          placeholder="Comment"
                          sx={
                            disabled
                              ? { ...DISABLED_INPUT_STYLES, height: "100%" }
                              : { height: "100%" }
                          }
                          multiline
                          rows="4"
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pl: 6,
          mt: 2,
          pb: 4,
        }}
      >
        <Typography sx={{ fontWeight: "bold" }} component="span">
          Reputational Rating
          <Chip
            sx={{
              background:
                getColorSchemeForRating(overallRating).colorScheme.chipColor,
              height: 28,
              ml: 2,
              pl: 1,
              pr: 1,
              color:
                getColorSchemeForRating(overallRating).colorScheme.textColor,
            }}
            label={
              overallRating != null
                ? `${overallRating} (${RATING_LABEL["ReputationalImpact"][overallRating]})`
                : "N/A"
            }
          />
        </Typography>

        {/* empty box to push content to the left not to touch the right screen edge */}
        <Box />
      </Box>
    </>
  );
};

import {
  DeleteOutlined,
  EditOutlined,
  InfoOutlined,
  MoreVert,
  SettingsBackupRestore,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Markdown } from "@pimo/pimo-components";
import { useState } from "react";
import { FE_OrganizationalEntity, FilterData, OECluster } from "tracy-types";

import { ManageOETitleCard } from "../manage-oe-title-card/manage-oe-title-card";

export type ManageOEEventPayload = FE_OrganizationalEntity & {
  reopenTopRiskAssessment?: boolean;
  hasAllDefaultScenarios?: boolean;
};

export type OEManagerProps = {
  oeClusters: OECluster[];
  oes: ManageOEEventPayload[];
  year?: number;
  filterData: FilterData;
  title: string;
  filterValues?: { oeProjects?: string[]; contacts?: string[] };
};

type OEManagerEventName =
  | "oe-created"
  | "oe-updated"
  | "oe-deleted"
  | "create-default-scenarios-for-single-oe-clicked"
  | "create-default-scenarios-for-all-oes-clicked"
  | "filter:apply"
  | "filter:clear";

const OE_ID_INFO_TEXT = `
Please use the OE ID you find under this [link](https://allianzms.sharepoint.com/sites/DE1890-connect-azse-group-technology/SitePages/OE%20Chief%20Information%20Security%20Officers.aspx).
`;

export const ManageOEsSettingsPage: PimoReactComponent<
  OEManagerProps,
  OEManagerEventName,
  ManageOEEventPayload | FilterData
> = ({ fireEvent, oeClusters, oes, year, filterData, title, filterValues }) => {
  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openInfoAnchor, setOpenInfoAnchor] = useState<HTMLButtonElement>();

  const [anchorElementForGeneralMenu, setAnchorElementForGeneralMenu] =
    useState<null | HTMLElement>(null);
  const generalMenuOpen = Boolean(anchorElementForGeneralMenu);

  const [anchorElementForOEMenu, setAnchorElementForOEMenu] =
    useState<null | HTMLElement>(null);
  const [oeForMenu, setOEForMenu] = useState<ManageOEEventPayload | null>(null);
  const oeMenuOpen = Boolean(anchorElementForOEMenu);

  const [formData, setFormData] = useState<ManageOEEventPayload>({
    name: "",
    cimid: "",
    reopenTopRiskAssessment: false,
    hideFromGroupDashboard: true,
    hasShortcutAssessment: false,
    oeClusters: [],
    oeStatuses: [],
    controlEnvRatings: [],
  });

  const handleOpenForm = () => {
    setFormData({
      name: "",
      cimid: "",
      hideFromGroupDashboard: true,
      reopenTopRiskAssessment: false,
      hasShortcutAssessment: false,
      oeClusters: [],
      oeStatuses: [],
      controlEnvRatings: [],
    });
    setOpenForm(true);
  };

  const handleButtonClick = () => {
    if (
      formData.id == null ||
      formData.oeStatuses.length === 0 ||
      formData.oeStatuses.every((status) => status.completionDate === null)
    ) {
      return;
    } else {
      setFormData({
        ...formData,
        reopenTopRiskAssessment: true,
      });
    }
  };

  const handleEdit = (oe: ManageOEEventPayload) => {
    if (!oe.id) {
      return;
    }
    setFormData(oe);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSubmit = () => {
    if (formData.id != null) {
      fireEvent?.("oe-updated", formData);
    } else {
      fireEvent?.("oe-created", formData);
    }
    handleCloseForm();
  };

  const handleDelete = (oe: ManageOEEventPayload) => {
    setOpenDelete(true);
    setFormData(oe);
  };

  const handleConfirmDelete = () => {
    if (formData.id !== null) {
      fireEvent?.("oe-deleted", formData);
    }
    setOpenDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        boxShadow: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          padding: "15px 0",
        }}
      >
        <ManageOETitleCard
          title={title}
          filterData={filterData}
          filterValues={filterValues}
          onButtonClick={handleOpenForm}
          fireEvent={fireEvent}
        />
      </Stack>

      {oes.length === 0 && (
        <Box sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <Typography
            sx={{
              color: "#979797",
              fontSize: "30px",
              fontWeight: 500,
              display: "block",
              margin: "auto",
              marginTop: "150px",
              marginBottom: "150px",
            }}
          >
            You have not yet created any OEs
          </Typography>
        </Box>
      )}

      {oes.length > 0 && (
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "700px",
            marginLeft: "24px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">
                  Actions
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={oeMenuOpen ? "long-menu" : undefined}
                    aria-expanded={oeMenuOpen ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(event) => {
                      setAnchorElementForGeneralMenu(event.currentTarget);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorElementForGeneralMenu}
                    open={generalMenuOpen}
                    onClose={() => setAnchorElementForGeneralMenu(null)}
                  >
                    <MenuItem
                      // selected={option === "Pyxis"}
                      onClick={() => {
                        setAnchorElementForGeneralMenu(null);
                        fireEvent?.(
                          "create-default-scenarios-for-all-oes-clicked"
                        );
                      }}
                    >
                      <SettingsBackupRestore sx={{ mr: 2 }} />
                      Set up default scenarios for {year} for{" "}
                      <b>&nbsp;all&nbsp;</b> OEs without default scenarios
                      (copies scenarios from OE named "default")
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {oes.map((oe: ManageOEEventPayload, index) => (
                <TableRow key={oe.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell sx={{ width: "30%" }}>
                    <strong>{oe.name}</strong>
                  </TableCell>
                  <TableCell align="right">
                    {oe.name === "default" && (
                      <Chip
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          mr: 1,
                          background: "rgba(0, 200, 83, 0.2)",
                          fontWeight: 500,
                        }}
                        label={<>💡 OE that holds default scenarios</>}
                      />
                    )}
                    {oe.name !== "default" &&
                      (oe.hasAllDefaultScenarios ? (
                        <Chip
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            mr: 1,
                            background: "rgba(0, 200, 83, 0.2)",
                            fontWeight: 500,
                          }}
                          label={
                            <>✔️ has all default scenarios set up for {year}</>
                          }
                        />
                      ) : (
                        <Chip
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            mr: 1,
                            background: "rgba(200, 0, 83, 0.2)",
                            fontWeight: 500,
                          }}
                          label={
                            <>
                              🚫 does not have all default scenarios set up for{" "}
                              {year}
                            </>
                          }
                        />
                      ))}

                    <Chip
                      label={
                        oe.hideFromGroupDashboard
                          ? "Not Included in the dashboard"
                          : "Included in the dashboard"
                      }
                      sx={{
                        backgroundColor: oe.hideFromGroupDashboard
                          ? "#E0E0E0"
                          : "rgba(0, 200, 83, 0.2)",
                        marginRight: 2,
                        fontWeight: 500,
                      }}
                    />
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleEdit(oe)}
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(oe)}
                    >
                      <DeleteOutlined />
                    </IconButton>

                    <IconButton
                      disabled={oe.name === "default"}
                      aria-label="more"
                      id="long-button"
                      aria-controls={oeMenuOpen ? "long-menu" : undefined}
                      aria-expanded={oeMenuOpen ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(event) => {
                        setAnchorElementForOEMenu(event.currentTarget);
                        setOEForMenu(oe);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}

      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorElementForOEMenu}
        open={oeMenuOpen}
        onClose={() => setAnchorElementForOEMenu(null)}
      >
        <MenuItem
          // selected={option === "Pyxis"}
          onClick={() => {
            setAnchorElementForOEMenu(null);
            if (!oeForMenu) {
              return;
            }
            fireEvent?.(
              "create-default-scenarios-for-single-oe-clicked",
              oeForMenu
            );
          }}
        >
          <SettingsBackupRestore sx={{ mr: 2 }} />
          Set up default scenarios for {year} (copies scenarios from OE named
          "default")
        </MenuItem>
      </Menu>

      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        fullWidth
        maxWidth="md"
        sx={{ margin: 8 }}
        PaperProps={{ sx: { padding: 2 } }}
      >
        <DialogTitle
          sx={{
            color: "#000000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {formData.id != null ? "Edit OE" : "New OE"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <TextField
              label="OE Name*"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
              <TextField
                label="OE ID*"
                value={formData.cimid}
                onChange={(e) =>
                  setFormData({ ...formData, cimid: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  onClick={({ target }) =>
                    setOpenInfoAnchor(target as HTMLButtonElement)
                  }
                >
                  <InfoOutlined />
                </IconButton>
                <Popover
                  open={!!openInfoAnchor}
                  anchorEl={openInfoAnchor}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={() => setOpenInfoAnchor(undefined)}
                >
                  <Box sx={{ py: 1, px: 2 }}>
                    <Markdown>{OE_ID_INFO_TEXT}</Markdown>
                  </Box>
                </Popover>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>OE Clusters</InputLabel>
              <Select
                input={<OutlinedInput label="OE Clusters" />}
                MenuProps={{ sx: { textOverflow: "ellipsis" } }}
                multiple
                value={formData.oeClusters}
                renderValue={() => {
                  return oeClusters
                    .filter(({ id }) =>
                      formData.oeClusters.some((cluster) => cluster.id === id)
                    )
                    .map(({ name }) => name)
                    .join(", ");
                }}
              >
                {oeClusters.map(({ id, name }) => {
                  return (
                    <MenuItem key={id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.oeClusters.some(
                              (cluster) => cluster.id === id
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFormData({
                                  ...formData,
                                  oeClusters: [
                                    ...formData.oeClusters,
                                    { id } as OECluster,
                                  ],
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  oeClusters: formData.oeClusters.filter(
                                    (cluster) => cluster.id !== id
                                  ),
                                });
                              }
                            }}
                          />
                        }
                        checked={formData.oeClusters.some(
                          (cluster) => cluster.id === id
                        )}
                        label={name}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box sx={{ width: "100%" }} />
          </Box>

          <Box sx={{ mt: 4 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Switch
                checked={!formData.hideFromGroupDashboard}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hideFromGroupDashboard: !e.target.checked,
                  })
                }
              />
              <Typography>OE part of Dashboard</Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Switch
                checked={formData.hasShortcutAssessment}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hasShortcutAssessment: e.target.checked,
                  })
                }
              />
              <Typography>Short Assessment</Typography>
            </Stack>
          </Box>
          {formData.id && formData.oeStatuses && (
            <Stack direction="row" spacing={2} mt={2} alignItems="center">
              <Button
                variant="contained"
                onClick={handleButtonClick}
                disabled={
                  formData.oeStatuses.length === 0 ||
                  formData.oeStatuses.every(
                    (status) => status.completionDate === null
                  ) ||
                  formData.reopenTopRiskAssessment
                }
              >
                Reopen Top Risk Assessment (current year)
              </Button>
            </Stack>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", paddingRight: 2 }}>
          <Button onClick={handleCloseForm}>CLOSE</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            disabled={!formData.name || !formData.cimid}
            sx={{ ml: 2 }}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this OE?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

import { EntityValidationResult } from "tracy-types";

export function getErrorMessage(
  path: string,
  validationResult: EntityValidationResult | undefined
): string | undefined {
  return validationResult?.error?.details?.errors?.find(
    (error) => error.path === path
  )?.message;
}

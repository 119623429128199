import { SVGProps } from "react";

export const Arrow = ({
  direction,
  length,
  ...props
}: {
  direction:
    | "top-left-bottom-right"
    | "top-right-bottom-left"
    | "top-bottom"
    | "left-right";
  length: number;
} & SVGProps<SVGSVGElement>) => {
  const markerSize = 13;

  let x1 = 0;
  let y1 = 0;
  let x2 = 0;
  let y2 = length - markerSize;

  switch (direction) {
    case "top-left-bottom-right": {
      x2 = length - markerSize;
      break;
    }
    case "top-right-bottom-left": {
      x1 = length;
      x2 = markerSize;
      break;
    }
    case "top-bottom": {
      x1 = length / 2;
      x2 = length / 2;
      y2 = length - markerSize - 3;
      break;
    }
    case "left-right": {
      x1 = 0;
      x2 = length - markerSize - 3;
      y1 = length / 2;
      y2 = y1;
    }
  }

  return (
    <svg height={`${length}px`} width={`${length}px`} {...props}>
      <defs>
        <marker
          id="markerArrow"
          markerWidth={markerSize}
          markerHeight={markerSize}
          refX="2"
          refY="6"
          orient="auto"
        >
          <path d="M2,2 L2,11 L10,6 L2,2" style={{ fill: "#000000" }} />
        </marker>
      </defs>

      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        style={{
          stroke: "#000",
          strokeWidth: 2,
          markerEnd: "url(#markerArrow)",
        }}
      />
    </svg>
  );
};

import { ScenariosForm } from "../scenarios-form";
import type { ScenariosFormComponent } from "../types";
import { ProbabilityRatingOfCyberRiskForm } from "./reusable-step-components/1-and-7-probability-rating";

export const ProbabilityRatingOfResidualCyberRisk: ScenariosFormComponent = (
  props
) => {
  const { oeRatings, oeReportPage, scenario, step } = props;

  if (!oeRatings || !oeReportPage || !scenario || !step) {
    return <></>;
  }

  return (
    <ScenariosForm {...props}>
      <ProbabilityRatingOfCyberRiskForm {...props} riskType="residual" />
    </ScenariosForm>
  );
};

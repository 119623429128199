import { Box, Divider, Typography, useTheme } from "@mui/material";
import { ContentBoxProps } from "@pimo/pimo-components";
import React from "react";

export const CyberRiskAssessmentTableContainer = React.forwardRef(
  ({ children, title }: ContentBoxProps, ref) => {
    return (
      <ContentBox ref={ref} title={title}>
        <Box sx={{ overflowY: "auto", width: "100%" }}>{children}</Box>
      </ContentBox>
    );
  }
);

const ContentBox = React.forwardRef(
  ({ children, icon, title }: ContentBoxProps, ref) => {
    const theme = useTheme();
    return (
      <Box
        ref={ref}
        sx={{
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(1.5),
          paddingBottom: theme.spacing(1),
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            backgroundColor: "white",
            borderTopLeftRadius: theme.spacing(1.5),
            borderTopRightRadius: theme.spacing(1.5),
            color: "black",
            fontWeight: 500,
            gap: 2,
            p: 1,
            px: 2,
          }}
        >
          {icon ? (
            <Box
              sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: "12px",
                height: "36px",
                width: "36px",
              }}
            >
              {icon}
            </Box>
          ) : null}
          <Typography
            sx={{
              fontWeight: 500,
              py: 1,
              fontSize: "20px",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        {children}
      </Box>
    );
  }
);

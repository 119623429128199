import { STRAPI_URL } from "../../env";

export async function fetchCopyDefaultScenariosIntoYearForAllOEs(
  year: number
): Promise<void> {
  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff-copy-default-scenarios-into-year-for-all-oes/${year}`,
      {
        credentials: "include",
        method: "POST",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
  } catch {
    return;
  }
}

import { Box, BoxProps, Typography } from "@mui/material";
import { RatingField } from "tracy-types";

import { RatingChip } from "./rating-chip";

export const IMPACT_BOX_WIDTH = 280;
export const IMPACT_BOX_HEIGHT = 138;

export const ImpactBox: React.FC<{
  name: string;
  impact?: RatingField;
  impactName?: string;
  background: string;
  color: string;
  sx?: BoxProps;
}> = ({ name, impact, impactName, sx, background, color }) => {
  return (
    <Box
      sx={{
        ...sx,
        border: "#C4C4C4 1px solid",
        borderRadius: "5px",
        width: `${IMPACT_BOX_WIDTH}px`,
        height: `${IMPACT_BOX_HEIGHT}px`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <Typography
        component="span"
        sx={{ fontWeight: "bold", display: "inline" }}
      >
        {name}
      </Typography>
      <RatingChip
        label={
          impact || impactName
            ? [impact, impactName && `(${impactName})`]
                .filter(Boolean)
                .join(" ")
            : "N/A"
        }
        background={background}
        color={color}
      />
    </Box>
  );
};

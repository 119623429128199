import { Box, Typography } from "@mui/material";
import { type PimoReactComponent } from "@pimo/pimo-app-builder";

import type { ScenarioStatus } from "../../constants";
import { ScenariosStatusIndicator } from "./scenarios-status-indicator";

export type ScenariosHeaderProps = {
  percentage: number | string;
  title: string;
  status: ScenarioStatus;
};

export const ScenariosHeader: PimoReactComponent<ScenariosHeaderProps> = ({
  percentage,
  title,
  status,
}) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h5">{title}</Typography>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          gap: 2,
        }}
      >
        <Typography>Overall Completion: {percentage ?? "n/a"} %</Typography>
        <ScenariosStatusIndicator status={status} />
      </Box>
    </Box>
  );
};

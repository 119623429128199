import type { Comment } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchOEStatusComments({
  id,
  year,
}: {
  id: number;
  year: number;
}): Promise<Comment[]> {
  if (!id || !year) {
    return [];
  }

  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff/comments/${id}/${year}`,
      { credentials: "include" }
    );

    return (await response.json()) as Comment[];
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function createOEStatusComment({
  data,
  id,
  year,
}: {
  data: Partial<Comment>;
  id: number;
  year: number;
}): Promise<void> {
  if (!id || !year) {
    return;
  }

  try {
    await fetch(`${STRAPI_URL}/api/bff/comments/${id}/${year}`, {
      body: JSON.stringify({ data }),
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error(error);
  }
}

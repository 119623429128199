import { Box } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";
import { useEffect, useRef } from "react";
import { FilterData } from "tracy-types";
import { fireFilterEventWithTheProperPayloadBasedOnTrigger } from "tracy-utils";

import {
  DashboardTitleCardSearchBarSlot,
  DashboardTitleCardSearchBarSlotProps,
} from "./search-and-filter/dashboard-title-card-search-bar-slot";

export type ProjectOverviewCardProps = {
  title: string;
  filterData: FilterData;
  filterValues?: { clusters?: string[] };
};

export const DashboardTitleCard: PimoReactComponent<
  ProjectOverviewCardProps,
  "filter:clear" | "filter:apply",
  FilterData
> = ({ fireEvent, title, filterData, filterValues, ...props }) => {
  // used to debounce search queries
  const inputTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  // clean up timeout on destroy
  useEffect(() => {
    const timeout = inputTimeout.current;
    return () => clearTimeout(timeout);
  }, []);

  return (
    <TitleCard
      title={title}
      endSlot={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <DashboardTitleCardSearchBarSlot
            {...(props as DashboardTitleCardSearchBarSlotProps)}
            handleFilterChange={(event, trigger) => {
              fireFilterEventWithTheProperPayloadBasedOnTrigger(
                trigger,
                filterData,
                event,
                fireEvent
              );
            }}
            clearAllFilters={() => fireEvent?.("filter:clear")}
            filterData={filterData}
            filterValues={filterValues}
          />
        </Box>
      }
    />
  );
};

import { GlobalSettings } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchGlobalSettings(): Promise<
  GlobalSettings | undefined
> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff/global-setting`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as GlobalSettings;
  } catch {
    return;
  }
}
export async function updateGlobalSetting(
  payload: GlobalSettings
): Promise<Response | undefined> {
  try {
    return await fetch(`${STRAPI_URL}/api/bff/global-setting`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        lockGroupDashboardForOEs: payload.lockGroupDashboardForOEs,
      }),
    });
  } catch {
    return;
  }
}

import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  type DialogProps,
  DialogTitle,
  DialogTitleProps,
  Grid,
  type GridProps,
} from "@mui/material";
import { Layout, type PimoReactLayout } from "@pimo/pimo-app-builder";
import { useEffect, useMemo } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";

import type { ScenarioFlowStep } from "../components/scenarios/types";

type StepsDialogTitleProps = {
  type: "title";
} & DialogTitleProps;

type StepsDialogContentProps = {
  step?: string;
  type: "content";
} & GridProps;

type StepsDialogActionsProps = {
  type: "actions";
} & DialogActionsProps;

type StepsDialogLoadingProps = {
  type: "loading";
};

type StepsDialogLayoutProps =
  | StepsDialogLoadingProps
  | StepsDialogContentProps
  | StepsDialogTitleProps
  | StepsDialogActionsProps;

export class StepsDialogLayout implements Layout<StepsDialogLayoutProps> {
  constructor(
    private settings: {
      dialog: DialogProps;
      grid: GridProps;
      pattern: string;
      steps: ScenarioFlowStep[];
    }
  ) {}

  getLayoutComponent(): PimoReactLayout<StepsDialogLayoutProps> {
    const { dialog, grid, pattern, steps } = this.settings;

    return (props) => {
      const match = useMatch(pattern);
      const navigate = useNavigate();
      const params = useParams<"*">();
      const [
        actionsComponents,
        contentComponents,
        titleComponents,
        loadingComponents,
      ] = useMemo(
        () =>
          props.components.reduce(
            (components, component) => {
              switch (true) {
                case component.layoutProps?.type === "actions":
                  components[0].push([
                    component?.render(),
                    component?.layoutProps,
                  ]);
                  break;
                case component.layoutProps?.type === "content" &&
                  (!component.layoutProps?.step ||
                    component.layoutProps?.step === params["*"]):
                  components[1].push([
                    component?.render(),
                    component?.layoutProps,
                  ]);
                  break;
                case component.layoutProps?.type === "title":
                  components[2].push([
                    component?.render(),
                    component?.layoutProps,
                  ]);
                  break;
                case component.layoutProps?.type === "loading":
                  components[3].push([
                    component?.render(),
                    component?.layoutProps,
                  ]);
                  break;
              }

              return components;
            },
            [
              [] as [React.FC, StepsDialogActionsProps][],
              [] as [React.FC, StepsDialogContentProps][],
              [] as [React.FC, StepsDialogTitleProps][],
              [] as [React.FC, StepsDialogLoadingProps][],
            ]
          ),
        [params, props.components]
      );

      useEffect(() => {
        /** if there no `step` found in params, navigate to the first in the list  */
        if (!params["*"] && steps.length) {
          navigate([match?.pathnameBase, steps[0].slug].join(""));
        }
        /**
         * if there's a `step` found in params,
         * but that steps doesn't exist in the definitions
         * call the dialog's `onClose` callback
         */
        if (
          params["*"] &&
          !steps.find((step) => step.viewname === params["*"])
        ) {
          dialog.onClose?.({}, "backdropClick");
        }
      }, [match, navigate, params]);

      return (
        <Dialog
          {...dialog}
          fullScreen
          sx={{ m: "1%" }}
          PaperProps={{ sx: { borderRadius: 5 } }}
        >
          {loadingComponents.length && (
            <>
              {loadingComponents.map(([Component, layoutProps], index) => {
                return (
                  <Component
                    {...(layoutProps ?? {})}
                    key={`loading_component_${index}`}
                  />
                );
              })}
            </>
          )}
          {titleComponents.length && (
            <DialogTitle sx={{ borderBottom: "1px solid lightgrey" }}>
              {titleComponents.map(([Component, layoutProps], index) => {
                return (
                  <Component
                    {...(layoutProps ?? {})}
                    key={`title_component_${index}`}
                  />
                );
              })}
            </DialogTitle>
          )}
          <DialogContent sx={{ p: 0 }}>
            <Grid {...grid} container sx={{ height: "100%", width: "100%" }}>
              {contentComponents.map(([Component, layoutProps], index) => {
                return (
                  <Grid
                    {...(layoutProps ?? {})}
                    key={`grid_item_${index}`}
                    item
                  >
                    <Component />
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
          {actionsComponents.length && (
            <DialogActions sx={{ p: 0, borderTop: "1px solid lightgrey" }}>
              {actionsComponents.map(([Component, layoutProps], index) => {
                return (
                  <Component
                    {...(layoutProps ?? {})}
                    key={`actions_component_${index}`}
                  />
                );
              })}
            </DialogActions>
          )}
        </Dialog>
      );
    };
  }
}

import { OECluster } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchOEClusters(): Promise<OECluster[]> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff/oe-clusters`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as OECluster[];
  } catch (error) {
    console.error(error);
    return [];
  }
}

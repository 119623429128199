import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import type { FC } from "react";

export const ScenariosErrorsDialog: FC<{
  onContinue: () => void;
  onResolve: () => void;
  showValidationErrorsDialog: boolean;
}> = ({ onContinue, onResolve, showValidationErrorsDialog }) => {
  return (
    <Dialog open={!!showValidationErrorsDialog}>
      <DialogTitle>Errors in Step</DialogTitle>
      <Divider />
      <DialogContent>
        There are errors (e.g. missing fields) on the current step.
        <br />
        <br />
        How do you want to proceed?
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue}>Continue with errors</Button>
        <Button onClick={onResolve} variant="contained">
          Resolve errors
        </Button>
      </DialogActions>
    </Dialog>
  );
};

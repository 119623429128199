import type { ReputationalConfig } from "tracy-types";

export const REPUTATIONAL_CONFIG = {
  0: {
    "AZ Investors": ["Not Affected"],
    "AZ Staff": ["Not Affected"],
    "Business partners": ["Not Affected"],
    Customers: ["Not Affected"],
    NGOs: ["Not Affected"],
    Regulators: ["Not Affected"],
  },
  1: {
    "AZ Investors": [
      "Low level local or special media awareness (incl. Limited web)",
      "No change in analysts' recommendations",
    ],
    Customers: [
      "Low level local or specific media awareness (incl. limited web)",
      "No important customer/significant number of customers at risk",
    ],
    NGOs: ["Little negative attention by minor/regional NGOs"],
    "Business partners": [
      "Low level local or special (incl. limited web) media awareness",
      "No impact on attractiveness of AZ for business partners",
    ],
    Regulators: ["Minor non-public criticism by regulator or industry body"],
    "AZ Staff": ["No impact on trust/motivation of employees"],
  },
  2: {
    "AZ Investors": [
      "National or special media awareness",
      "No change in analysts' recommendations",
    ],
    Customers: [
      "Regional or special (incl. Broader web) media awareness/impact  on minor customer groups",
      "Marginal impact on product quality",
      "Customers become aware of problem, but only small number of existing/new customers at risk",
    ],
    NGOs: ["Campaign or heightened attention by minor/regional NGOs"],
    "Business partners": [
      "Marginal impact on attractiveness of AZ for business partners",
    ],
    Regulators: ["Strong non-public criticism by regulator or industry body"],
    "AZ Staff": [
      "Moderate negative impact on trust/motivation of certain groups of employees",
    ],
  },
  3: {
    "AZ Investors": [
      "Long-term national/short-term international media awareness",
      "Critical articles in national financial press",
      "No change in analysts' recommendations",
    ],
    Customers: [
      "Long-term national/short-term international media awareness",
      "Topic-related impact on sensitive customer groups",
      "Some impact on product quality",
      "Risk of significant lapses/loss of targeted new customers, as significant impact on customers",
    ],
    NGOs: [
      "Some negative attention by international and highly influential NGOs",
    ],
    "Business partners": [
      "Long-term national/short-term international media awareness",
      "Topic related impact on sensitive business partners",
      "Some impact on attractiveness of AZ for business partners",
    ],
    Regulators: [
      "Public criticism by regulator or industry body",
      "Low scale regulatory action",
    ],
    "AZ Staff": [
      "Strong topic-related impact on trust/motivation of some sensitive staff",
    ],
  },
  4: {
    "AZ Investors": [
      "Financial results are adversely affected by reputational event",
      "Critical articles in international financial press",
      "High short-term national/international media awareness (cover stories)",
      "A few analysts downgrade their recommendations",
      "AZ is removed from portfolios/investment universe by some specialized ESG-investors",
    ],
    Customers: [
      'Challenge on AZ brand/"Trust"',
      "High short-term national/international media awareness (cover stories)",
      "High impact on product quality",
      "Risk of large number of lapses/huge loss of targeted new customers",
    ],
    NGOs: [
      "Campaign or heightened attention by a single international and highly influential NGOs",
    ],
    "Business partners": [
      "Significant loss of attractiveness of AZ for major business partners",
    ],
    Regulators: ["Strong public criticism by regulator or industry body"],
    "AZ Staff": [
      "Serious challenge to trust and motivation of majority of mid-management and staff",
    ],
  },
  5: {
    "AZ Investors": [
      "Repeated, very critical articles in international  financial press",
      "High long-term national/international media awareness (cover stories)",
      "Many analysts reduce target prices and downgrade their recommendations",
      "AZ is removed from portfolios/investment universe by some important institutional investors",
    ],
    Customers: [
      "High long-term national/international media awareness (cover stories)",
      "Very high impact on product quality",
      'Huge loss of "Trust" in AZ products across all important customer groups',
      "Risk of very large number of lapses/very huge loss of targeted new customers",
    ],
    NGOs: [
      "Campagin or heightened attention by a coalition of international and highly influential NGOs",
    ],
    "Business partners": [
      "Significant loss of attractiveness of AZ for a significant number of business partners",
    ],
    Regulators: ["High-scale regulatory action", "Government action"],
    "AZ Staff": ["Huge loss in confidence by mid-management and staff"],
  },
} as const satisfies ReputationalConfig;

import type { ScenarioFields } from "tracy-types";

export const FIELD_CONFIG = {
  motivesObjectives: {
    displayName: "Motives/Objectives",
    description:
      "The underlying intentions of the attackers, such as financial gain, sabotage, or ideological drivers.",
    isWriteable: true,
  },
  assetsAtRisk: {
    displayName: "Assets at Risk",
    description:
      "The specific targets or assets that attackers are aiming for, which could range from (strictly) confidential data and IT systems to monetary funds.",
    isWriteable: true,
  },
  initialAction: {
    displayName: "Action: Initial Attack",
    description:
      "The primary method attackers use to gain access to the network such as phishing or exploiting zero-day vulnerabilities, Threat-Event/Vulnerability",
    isWriteable: true,
  },
  responseDuration: {
    displayName: "Response Duration",
    description:
      "The anticipated period from the moment an attack is detected until it is resolved, categorized as short-term (days), medium-term (weeks) or long-term (months).",
    isWriteable: true,
  },
  vulnerability: {
    displayName: "Vulnerability",
    description:
      "The broader weaknesses or control deficiencies being targeted and exploited such as susceptible individuals, misconfigured or unpatched systems.",
    isWriteable: true,
  },
  actionFurtherActionAttackTtp: {
    displayName: "Action: Further Action / Attack TTP",
    description:
      "The further tactics, techniques and procedures attackers utilize to achieve their objectives.\n",
    isWriteable: true,
  },
  businessImpact: {
    displayName: "Impact",
    description:
      "The impact of the attack on Allianz such as business interruption, financial fraud or losses, data leakage.\n",
    isWriteable: true,
  },
  affectedAttributes: {
    displayName: "Affected Attributes",
    description:
      "The specific properties like confidentiality, availability or integrity, that are affected by the attack.\n",
    isWriteable: true,
  },
  actor: {
    displayName: "Actor",
    description:
      "The individual or group behind the attack. Examples include: \n- Nation States and Organized Criminals: Early access to vulnerability information  and access to custom-developed tools.\n- Hackers: Access to publicly available tools and known exploits.\n- Insiders: No special skills, but have access to confidential data.",
    isWriteable: true,
  },
  storyAndName: {
    displayName: "Scenario Name and Story",
    description:
      "The overall description of how the scenario takes place step by step",
    isWriteable: false,
  },
  story: {
    displayName: "Scenario Story",
    description:
      "The overall description of how the scenario takes place step by step",
    isWriteable: false,
  },
  name: {
    displayName: "Scenario Name",
    description: "The name of the scenario",
    isWriteable: false,
  },
  shortDescription: {
    displayName: "Scenario Short Description",
    description: "A single sentence summarizing the scenario",
    isWriteable: false,
  },
} as const satisfies ScenarioFields;

import { Box, Tab, Tabs, useTheme } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import type { ScenarioProgress } from "tracy-types";

import {
  getStatus,
  ScenariosStatusIndicator,
} from "./scenarios-status-indicator";
import type { ScenarioFlowStep } from "./types";

export type ScenariosNavigationProps = {
  currentScenarioProgress?: ScenarioProgress;
  currentStep?: ScenarioFlowStep;
  pattern: string;
  steps: ScenarioFlowStep[];
};

export type ScenariosNavigationEvent = "change";
export type ScenariosNavigationEventPayload = { step: ScenarioFlowStep };

export const ScenariosNavigation: PimoReactComponent<
  ScenariosNavigationProps,
  ScenariosNavigationEvent,
  ScenariosNavigationEventPayload
> = ({ currentStep, currentScenarioProgress, fireEvent, steps }) => {
  const theme = useTheme();
  const index = steps.findIndex((step) => step.slug === currentStep?.slug) ?? 0;
  const handleChange = (_: React.SyntheticEvent, tab: number) => {
    fireEvent?.("change", { step: steps?.[tab] });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.light,
        display: "flex",
        flex: 1,
        height: "100%",
        width: "100%",
      }}
    >
      <Tabs
        onChange={handleChange}
        scrollButtons="auto"
        value={index}
        variant="scrollable"
      >
        {steps.map((step) => {
          return (
            <Tab
              icon={
                <ScenariosStatusIndicator
                  status={getStatus({
                    step: step.number,
                    progress: currentScenarioProgress,
                  })}
                />
              }
              iconPosition="end"
              key={step.slug}
              label={step.name}
              sx={{ gap: 2, textTransform: "none" }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

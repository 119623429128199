import { RiskType } from "tracy-types";
import { getColorSchemeForRating, isTopRisk } from "tracy-utils";

import { TracyAppState } from "../../app";
import { RATING_LABEL } from "../../config/rating-label";

export function generateRiskTableEntries(
  state: TracyAppState,
  riskType: RiskType
) {
  return state?.currentOERatings?.ratings?.map((rating) => {
    const risk = rating[riskType];
    const financialRating = risk?.financialRating;
    const financialRatingColorScheme =
      getColorSchemeForRating(financialRating).colorScheme;

    const reputationalRating = risk?.reputationalRating;
    const reputationalRatingColorScheme =
      getColorSchemeForRating(reputationalRating).colorScheme;

    const impactSeverity = risk?.impactSeverityRating;
    const impactSeverityColorScheme =
      getColorSchemeForRating(impactSeverity).colorScheme;

    const probabilityRating = risk?.probabilityRating;
    const probabilityRatingColorScheme =
      getColorSchemeForRating(probabilityRating).colorScheme;

    const overallRating = risk?.overallRating;
    const overallRatingColorScheme =
      getColorSchemeForRating(overallRating).colorScheme;

    const topRisk = isTopRisk({
      probabilityRating,
      impactSeverity,
    });

    return {
      columnProps: [
        { header: "", body: rating.name },
        {
          body: financialRating
            ? `${financialRating} (${RATING_LABEL.FinancialImpact[financialRating]})`
            : "N/A",
          chipProps: {
            sx: {
              backgroundColor: financialRatingColorScheme.chipColor,
              color: financialRatingColorScheme.textColor,
              borderRadius: "100px",
              px: 1,
              minWidth: 130,
            },
          },
        },
        {
          body: reputationalRating
            ? `${reputationalRating} (${RATING_LABEL.ReputationalImpact[reputationalRating]})`
            : "N/A",
          chipProps: {
            sx: {
              backgroundColor: reputationalRatingColorScheme.chipColor,
              color: reputationalRatingColorScheme.textColor,
              borderRadius: "100px",
              px: 1,
              minWidth: 130,
            },
          },
        },
        {
          body: impactSeverity
            ? `${impactSeverity} (${RATING_LABEL.ReputationalImpact[impactSeverity]})`
            : "N/A",
          chipProps: {
            sx: {
              backgroundColor: impactSeverityColorScheme.chipColor,
              color: impactSeverityColorScheme.textColor,
              borderRadius: "100px",
              px: 1,
              minWidth: 130,
            },
          },
        },
        {
          body: probabilityRating
            ? `${probabilityRating} (${RATING_LABEL.ProbabilityRating[probabilityRating]})`
            : "N/A",
          chipProps: {
            sx: {
              backgroundColor: probabilityRatingColorScheme.chipColor,
              color: probabilityRatingColorScheme.textColor,
              borderRadius: "100px",
              px: 1,
              minWidth: 130,
            },
          },
        },
        {
          body: overallRating
            ? `${overallRating} (${RATING_LABEL.FinancialImpact[overallRating]})`
            : "N/A",
          chipProps: {
            sx: {
              backgroundColor: overallRatingColorScheme.chipColor,
              color: overallRatingColorScheme.textColor,
              borderRadius: "100px",
              px: 1,
              minWidth: 130,
            },
          },
        },
        {
          body: topRisk == null ? "N/A" : topRisk ? "Yes" : "No",
          chipProps: {
            sx: {
              backgroundColor: topRisk == null ? "#C4C4C4" : "#2196F3",
              color: "#fff",
              borderRadius: "100px",
              px: 1,
              minWidth: 130,
            },
          },
        },
        ...(riskType === "residual"
          ? [
              {
                body: `${rating["delta"].overallRating ?? "N/A"}`,
                chipProps: {
                  sx: {
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "100px",
                    px: 1,
                    minWidth: 130,
                  },
                },
              },
            ]
          : []),
      ],
      rowProps: {},
    };
  });
}

import { Box, Typography } from "@mui/material";

export const ResultsDisclaimer = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mx: 4, my: 8 }}>
      <Typography
        color="#979797"
        fontWeight="bold"
        textAlign="center"
        variant="h4"
      >
        Results will be shown once the whole assessment is done
      </Typography>
    </Box>
  );
};

import { Box, Button, MenuItem, Select } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  DefaultOverlay,
  DefaultOverlayEventNames,
  DefaultOverlayEventPayload,
  DefaultOverlayProps,
  HeaderProps,
} from "@pimo/pimo-components";
import { Profile } from "tracy-types";

export type TracyOverlayState = {
  isSidebarOpen: boolean;
  userProfile?: Profile;
  year: number;
} & DefaultOverlayProps;

type TracyOverlayEventNames =
  | DefaultOverlayEventNames
  | "year-selected"
  | "submit-report";

export const TracyOverlay: PimoReactComponent<
  TracyOverlayState,
  TracyOverlayEventNames,
  { year: number } | DefaultOverlayEventPayload
> = ({ year, ...props }) => {
  const headerProps: HeaderProps = {
    ...props.header,

    children: (
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          onClick={() => (location.href = "mailto:isrm@allianz.com")}
          sx={{
            background: "#E5A537",
            color: "white",
            px: 2,
            textTransform: "none",
            ":hover": { background: "#E5A537", opacity: 0.75 },
          }}
        >
          Contact Us
        </Button>
        <Select
          value={year}
          onChange={(e) =>
            props.fireEvent?.("year-selected", {
              year: parseInt(e.target.value.toString()),
            })
          }
          sx={{ height: 38 }}
        >
          <MenuItem value={2024}>2024</MenuItem>
          <MenuItem value={2025}>2025</MenuItem>
        </Select>
      </Box>
    ),
  };
  return <DefaultOverlay {...props} header={headerProps} />;
};
